import React from "react"
import loaderIcon from "@assets/whale-signals/loader.svg";
import loopIcon from "@assets/whale-signals/emoji/loop.svg";
import cryIcon from "@assets/whale-signals/emoji/cry.svg";
import { useTranslation } from "react-i18next";

/* eslint-disable react/prop-types */
const Errors = ({status, whaleSignalsLength}) => {
  const { t } = useTranslation();

  return (
    <>
      {status === 'loading' &&
        <div className="loader-container">
          <div>
            <img src={loaderIcon} alt="Loading..." width="48"/>
          </div>
          <div>{t('common.loading')}</div>
        </div>
      }

      {status === 'ok' && !whaleSignalsLength &&
        <div className="empty-data-contrainer">
          <div>
            <img src={loopIcon} alt={'No anomalies'} width='48'/>
          </div>
          <div>
            {t('common.no-anomalies')}
          </div>
        </div>
      }

      {status === 'error' &&
        <div className="error-contrainer">
          <div>
            <img src={cryIcon} alt={'something went wrong'} width='48'/>
          </div>
          <div>
            {t('common.oops-smth-wrong')}
          </div>
          <div>
            <a href={'/'} className="btn btn-semi-10 btn-sm">
              {t('common.update-page')}
            </a>
          </div>
        </div>
      }
    </>
  );
};

export default Errors;
