import React, { useEffect } from 'react';
import { useAnimate } from 'framer-motion';
import { randomNumber } from '@helpers/numbers';
import { useTranslation } from 'react-i18next';

export interface TabCardItem {
  value: string | number;
  label?: string;
}

interface TabsCardProps {
  list: TabCardItem[];
  active?: string | number;
  onClick?: (value: any) => void;
  className?: string;
  buttonsClassName?: string;
  tabClassName?: string;
  children?: React.ReactNode;
  noContent?: boolean;
  noAnimation?: boolean;
  disabled?: boolean;
}

export default function TabsCard(props: TabsCardProps) {
  const {
    list,
    active,
    onClick,
    className = '',
    tabClassName = 'py-2',
    buttonsClassName = 'card flex-row p-1 justify-content-between bg-semi-transparent-10 mb-3',
    noContent = false,
    noAnimation = false,
    children,
    disabled = false,
  } = props;

  const { t } = useTranslation();
  const number = randomNumber();
  const [scope, animate] = useAnimate();
  const activeIndex = list.findIndex(({ value }) => value === active);
  const width = 100 / list.length;

  useEffect(() => {
    animate(`#tab-indicator-${number}`, {
      left: activeIndex + 1 ? `calc(${width * activeIndex}% + 4px)` : '-250px',
      zIndex: 1,
    });
    if (noContent) return;
    list.forEach(({ value }) => {
      const isActive = value === active;
      if (
        document.querySelector(
          `.tab-element.tab-${value.toString().replace(' ', '_')}`,
        )
      ) {
        if (!noAnimation) {
          animate(
            `.tab-element.tab-${value.toString().replace(' ', '_')}`,
            { opacity: isActive ? 1 : 0, display: isActive ? 'block' : 'none' },
            { duration: 0.3, delay: isActive ? 0.2 : 0 },
          );
        } else {
          document
            .querySelector(
              `.tab-element.tab-${value.toString().replace(' ', '_')}`,
            )
            ?.setAttribute(
              'style',
              `display: ${isActive ? 'block' : 'none'}; opacity: ${isActive ? 1 : 0}`,
            );
        }
      }
    });
  }, [list, activeIndex, animate, noContent]);

  const handleClick = (value: string | number) => {
    if (disabled) return;
    onClick && onClick(value);
  };

  return (
    <div className={className} ref={scope}>
      <div className={buttonsClassName}>
        <div
          className="bg-semi-transparent rounded-2 position-absolute"
          style={{
            width: `calc(${width}% - 8px)`,
            height: 'calc(100% - 8px)',
            top: '4px',
          }}
          id={`tab-indicator-${number}`}
        />
        {list.map(({ value, label }, index) => (
          <div
            className={`tab-button tab-${value.toString().replace(' ', '_').toLowerCase()} tx-14 rounded-2 tx-center cur-pointer wd-100p ${value !== active ? 'tx-muted' : ''} ${tabClassName} ${disabled ? 'op-8' : ''}`}
            onClick={() => handleClick(value)}
            key={`tab-card-${value}-${index}`}
            style={{ zIndex: 2 }}
          >
            {t(label || value.toString())}
          </div>
        ))}
      </div>
      <div className="tab-content position-relative">{children}</div>
    </div>
  );
}
