import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { numberRegex } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import IconWithLoading from '@components/common/IconWithLoading';
import useNotification from '@hooks/useNotification';
import BigNumber from 'bignumber.js';
import NumericInput from '@components/common/NumericInput';
import PageLoader from '@components/PageLoader';
import { useTranslation } from 'react-i18next';
import TabsCard from '@components/common/TabsCard';
import cog from '@assets/icons/cog-white.svg';
import plus from '@assets/icons/plus-outlined.svg';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const predefinedBots = ['SafeAnalyzerbot', 'ttfbotbot', 'PirbViewBot'];

type BotType = 'SafeAnalyzerbot' | 'ttfbotbot' | 'PirbViewBot' | 'custom';

const Customization = observer(() => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const { settingsStore } = useStores();
  const {
    amounts: _amounts,
    percents: _percents,
    triggers: _triggers,
    initialized,
    isSaving,
    chartService: _chartService,
    analyticsBot,
  } = settingsStore;
  const navigate = useNavigate();
  const [amounts, setAmounts] = useState<string[]>([]);
  const [percents, setPercents] = useState<string[]>([]);
  const [triggers, setTriggers] = useState<string[]>([]);
  const notify = useNotification();
  const [chartService, setChartService] = useState<'dexscreener' | 'dextools'>(
    _chartService,
  );
  const [botService, setBotService] = useState<BotType>(
    predefinedBots.includes(analyticsBot)
      ? (analyticsBot as BotType)
      : 'custom',
  );
  const [botInput, setBotInput] = useState<string>(analyticsBot);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (initialized && _amounts.length && !amounts.length) {
      setAmounts(_amounts.map((a) => a.toString()));
      setTriggers(_triggers.map((a) => a.toString()));
      setPercents(_percents.map((a) => a.toString()));
    }
  }, [initialized, _amounts, amounts]);

  useEffect(() => {
    if (botService !== 'custom') {
      setBotInput(botService);
    }
  }, [botService]);

  useEffect(() => {
    if (!predefinedBots.includes(analyticsBot)) {
      setBotService('custom')
    }
    setBotInput(analyticsBot);
  }, [analyticsBot])

  const isInvalid = useCallback(
    (index: number) => {
      if (amounts[index] === '') {
        return false;
      }
      const value = new BigNumber(amounts[index]).toNumber();
      return isNaN(value) || !numberRegex.test(value.toString()) || value <= 0;
    },
    [amounts],
  );

  const handleSaving = () => {
    const checks = [
      { check: isInvalid(0), label: t('profile.first-amount') },
      { check: isInvalid(1), label: t('profile.second-amount') },
      { check: isInvalid(2), label: t('profile.third-amount') },
      { check: isInvalid(3), label: t('profile.fourth-amount') },
    ];

    if (checks.some(({ check }) => check)) {
      w(notify, AmplitudeEvent.CUSTOMIZATION_SETTINGS_ERROR, {
        error: checks.find((c) => c.check)?.label + ' ' + t('is-invalid'),
      })(checks.find((c) => c.check)?.label + ' ' + t('is-invalid'), {
        type: 'danger',
      });
      return;
    }

    w(() => null, AmplitudeEvent.CUSTOMIZATION_SETTINGS_SAVED, {
      amounts,
      percents,
      triggers,
      chartService,
      botInput,
    })();

    settingsStore.setAmounts(amounts);
    settingsStore.setPercents(percents);
    settingsStore.setTriggers(triggers);
    settingsStore.setChartService(chartService);
    settingsStore.setAnalyticsBot(botInput);
    if (!botInput) {
      setBotInput('ttfbotbot');
    }
    setTimeout(() => {
      settingsStore.handleUpdate();
    }, 50);
  };

  const handleChange = (
    key: string,
    v: number | string | null,
    index: number,
  ) => {
    const handler = {
      amounts: setAmounts,
      percents: setPercents,
      triggers: setTriggers,
    }[key];
    handler &&
      handler((prev) => {
        const newValues = [...prev];
        newValues[index] = v === null || v === undefined ? '' : v.toString();
        return newValues;
      });
  };

  const valuesConfig = [
    {
      emoji: '💰',
      key: 'amounts',
      value: amounts,
      storePrefix: 'amount-',
      prefix: '$',
    },
    {
      emoji: '💯',
      key: 'percents',
      value: percents,
      storePrefix: 'percent-',
      postfix: '%',
    },
    {
      emoji: '🎯',
      key: 'triggers',
      value: triggers,
      storePrefix: 'trigger-',
      postfix: '%',
    },
  ];

  if (!initialized || !amounts.length) {
    return <PageLoader text={t('strategy.loading-settings')} />;
  }

  return (
    <div className="pb-3" id="amount-settings">
      <div className="tx-28 tx-semibold my-3 d-flex align-items-center">
        <img
          src={cog}
          alt={t('profile.customization')}
          className="wd-25 me-2"
        />
        <span>{t('profile.customization')}</span>
      </div>

      <div className="tx-17 mb-2 mt-3">📈 {t('profile.chart-service')}</div>

      <TabsCard
        list={[
          { value: 'dexscreener', label: 'DexScreener' },
          { value: 'dextools', label: 'DexTools' },
        ]}
        active={chartService}
        onClick={setChartService}
      />

      <div className="tx-17 mb-2 mt-3">🤖 {t('profile.analytics-bot')}</div>

      <div className="d-flex">
        <TabsCard
          className='wd-100p'
          list={[
            { value: 'SafeAnalyzerbot', label: 'SafeAnalyzer' },
            { value: 'ttfbotbot', label: 'TTF Bot' },
            { value: 'PirbViewBot', label: 'PIRBViewBot' },
          ]}
          active={botService}
          onClick={setBotService}
        />

        <button onClick={() => setBotService('custom')} className='btn mx-ht-45 mb-3 py-0 px-2'>
          <img src={plus} alt="+" />
        </button>
      </div>

      <div className={`card ${botService === 'custom' ? 'd-flex' : 'd-none'}`}>
        <input
          type="text"
          className="form-control appearance-none"
          placeholder="ttfbotbot"
          value={`@${botInput}`}
          onChange={(e) => {
            setBotInput(e.target.value.replaceAll('@', ''));
          }}
          onBlur={() => window.scrollTo(0, 0)}
          disabled={isSaving}
        />
      </div>
      {valuesConfig.map(
        ({ emoji, key, value, storePrefix, postfix, prefix }) => {
          return (
            <React.Fragment key={key}>
              <div className="tx-17 mb-2 mt-3">
                {emoji} {t(`profile.${key}-settings`)}
              </div>
              <div className="card">
                <div className="d-flex justify-content-between align-items-center wd-100p gap-2 tx-center">
                  {value.map((a, index) => (
                    <NumericInput
                      className="form-control appearance-none tx-center"
                      key={`${storePrefix}${index}`}
                      value={a}
                      disabled={isSaving}
                      onChange={(v) => handleChange(key, v, index)}
                      noDecimal
                      {...{ postfix, prefix }}
                    />
                  ))}
                </div>
              </div>
            </React.Fragment>
          );
        },
      )}

      <div className="mt-4">
        <button
          className="btn wd-100p btn-primary-10"
          onClick={handleSaving}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} className="me-2" />
          {t('common.save')}
        </button>

        <button
          className="btn btn-transparent tx-white wd-100p mt-2"
          onClick={handleClose}
          disabled={isSaving}
        >
          {t('common.close')}
        </button>
      </div>
    </div>
  );
});

export default Customization;
