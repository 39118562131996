import React, { useRef, useState } from 'react';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import PrivateKeyModal from '@pages/Profile/WalletSettings/PrivateKeyModal';
import useResponseHandler from '@hooks/useResponseHandler';
import edit from '@assets/icons/edit.svg';
import LabelValueListItem from '@components/common/LabelValueListItem';
import CopyButton from '@components/common/CopyButton';
import copy from '@assets/icons/copy.svg';
import { walletTypeName } from '@helpers/chains';
import Preloader from '@components/common/Preloader';
import AvatarModal from '@pages/Profile/WalletSettings/AvatarModal';
import DeleteModal from './DeleteModal';
import { useTranslation } from 'react-i18next';
import { WalletType } from 'types/enums';

const WalletSettings = observer(() => {
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const { wallets, address, currentWallet } = accountStore;
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(currentWallet?.name || '');
  const handleResponse = useResponseHandler();
  const savingRef = useRef(false);

  const handleSave = () => {
    window.scrollTo(0, 0)

    if (savingRef.current) return;
    savingRef.current = true;

    const confirmed = window.confirm(
      `${t('wallet.rename-wallet')} ${currentWallet?.name} ${t('wallet.to')} ${name}?`,
    );
    if (confirmed) {
      accountStore
        .updateWalletName(name)
        .then((response) => {
          if (response && response.data && response.data.address) {
            accountStore.loadUser();
            handleResponse(t('wallet.wallet-name-updated'));
            setIsNameEditing(false);
          } else {
            handleResponse(response.response, true);
          }
          savingRef.current = false;
        })
        .catch((e) => {
          setName(currentWallet?.name || '');
          console.error(e);
          handleResponse(e, true);
          setIsNameEditing(false);
          savingRef.current = false;
        });
    } else {
      setName(currentWallet?.name || '');
      setIsNameEditing(false);
      savingRef.current = false;
    }
  };

  if (!currentWallet) {
    return <Preloader className="py-3" inline />;
  }

  return (
    <div className="pt-4">
      <div className="card d-flex flex-row justify-content-start align-items-center mb-4 wd-100p">
        <AvatarModal />
        <div className="d-flex flex-column align-items-start justify-content-center ms-3 pb-3">
          <div className="tx-13 tx-muted lh-1">
            {walletTypeName(currentWallet.type)}
          </div>
          {!isNameEditing && (
            <div
              className="d-flex align-items-center justify-content-start cur-pointer tx-17"
              onClick={() => setIsNameEditing(true)}
            >
              <div className="tx-17 me-2">{name}</div>
              <img src={edit} alt="edit" width={12} />
            </div>
          )}
          {isNameEditing && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSave();
              }}
            >
              <input
                type="text"
                className="form-control appearance-none px-0 py-0 tx-17 tx-normal bg-transparent border-0"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={handleSave}
                autoFocus
              />
            </form>
          )}
        </div>
      </div>

      <LabelValueListItem
        label={t('wallet.wallet-address')}
        className="py-2"
        isSingle
      >
        <CopyButton
          text={address}
          className="d-flex align-items-center justify-content-between tx-12"
          noIcon
        >
          <span>{address}</span>
          <img
            src={copy}
            alt="copy"
            width={16}
            height={16}
            className="ms-auto"
          />
        </CopyButton>
      </LabelValueListItem>

      <div className="tx-17 tx-semibold mt-3 mb-2">
        {t('wallet.export-wallet')}
      </div>

      <PrivateKeyModal />

      {!!(wallets.length - 1) && (
        <>
          <div className="tx-17 tx-semibold mt-3 mb-2">
            {t('wallet.edit-wallet')}
          </div>

          <DeleteModal />
        </>
      )}
    </div>
  );
});

export default WalletSettings;
