import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import info from '@assets/icons/info-red.svg';
import { PageRoutes } from '../../../constants';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import useNotification from '@hooks/useNotification';
import { AxiosError } from 'axios';
import useResponseHandler from '@hooks/useResponseHandler';

const ResetPinModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { authStore, accountStore } = useStores();
  const navigate = useNavigate();
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const handleDelete = async () => {
    setLoading(true);
    const response = await accountStore.updatePin({ pinCode: null });

    switch (response instanceof AxiosError) {
      case false:
        notify(t('profile.disable-pin-successful'), { type: 'success' });
        authStore.checkMe();
        onClose();
        navigate(PageRoutes.PROFILE);
        break;
      case true:
        handleResponse(response.response);
        break;
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onClose} onExited={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('profile.disable-pin')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center">
        <img src={info} alt="info" className="mb-3 mt-1 wd-45" />

        <div className="tx-28 tx-semibold tx-center mb-2">
          {t('profile.disable-pin')}?
        </div>

        <div className="tx-muted tx-17">
          {t('profile.disable-pin-description')}
        </div>

        <button
          className="btn wd-100p mt-3 bg-danger text-white"
          onClick={handleDelete}
          disabled={loading}
        >
          {t('profile.disable-pin')}
        </button>

        <button
          className="btn btn-transparent wd-100p mt-2 mb-3"
          onClick={onClose}
        >
          {t('common.cancel')}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPinModal;
