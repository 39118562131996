import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponseHandler from '@hooks/useResponseHandler';

interface RouteWrapperProps {
  children: React.ReactNode;
}

const RouteWrapper = observer(({ children }: RouteWrapperProps) => {
  const { accountStore } = useStores();
  const { wallets, checkDone, redirectTo, isAppInitialized, lockedToken } = accountStore;
  const [walletRouted, setWalletRouted] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleResponse = useResponseHandler();

  // useEffect(() => {
  //   if (isAppInitialized || profile.length) return;
  //   console.log('load user');
  //   accountStore.loadUser().then((response) => {
  //     console.log('loaded');
  //     if (!response?.data.length) {
  //       console.log('to login');
  //       navigate(PageRoutes.LOGIN, {replace: true});
  //     }
  //     accountStore.setAppInitialized(true);
  //   }).catch(() => {
  //     console.log('error to login');
  //     navigate(PageRoutes.LOGIN, {replace: true});
  //     accountStore.setAppInitialized(true);
  //   });
  // }, [isAppInitialized, profile]);
  //
  // useEffect(() => {
  //   if (profile.length || !isAppInitialized || !lockedToken) return;
  //   accountStore.checkMe().then((data) => {
  //     if (data) {
  //       if (data.isActivated) {
  //         navigate(PageRoutes.LOGIN, {replace: true});
  //       } else if (!data.isActivated) {
  //         navigate(PageRoutes.CREATE_WALLET, {replace: true});
  //       }
  //       accountStore.setAppInitialized(true);
  //     }
  //   }).catch((response) => {
  //     handleResponse(response);
  //   });
  // }, [isAppInitialized, lockedToken, profile]);
  //
  // useEffect(() => {
  //   console.log('redirect to', clone({redirectTo, pathname, isAppInitialized}));
  //   if (isAppInitialized && redirectTo && redirectTo !== PageRoutes.WALLET) {
  //     accountStore.setRedirectTo(null);
  //     // console.log('redirect to 2', redirectTo);
  //     navigate(redirectTo);
  //   } else if (isAppInitialized && !redirectTo && pathname === '/') {
  //     navigate(PageRoutes.WALLET);
  //   }
  //   // accountStore.setRedirectTo(null);
  // }, [redirectTo, isAppInitialized, pathname]);

  // useEffect(() => {
  //   console.log('redirect to', {state: clone({redirectTo, pathname, walletRouted})});
  //   if (isAppInitialized && redirectTo && pathname !== PageRoutes.WALLET && !walletRouted) {
  //     navigate(PageRoutes.WALLET);
  //     setTimeout(() => {
  //       setWalletRouted(true);
  //     }, 50);
  //   }
  // }, [redirectTo, isAppInitialized, pathname, walletRouted]);

  return (
    // <motion.main
    //   initial="initial"
    //   animate="enter"
    //   exit="exit"
    //   variants={transitionVariants}
    // >
    <>
      {children}
    </>
    // </motion.main>
  );
});

export default RouteWrapper;
