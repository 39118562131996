import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { getRandomInt } from '@helpers/numbers';
import close from "@assets/icons/close-black.svg";
import cn from 'classnames';

interface NotificationProps {
  type?: 'success' | 'danger' | 'warning';
  duration?: number;
  id?: string;
  closable?: boolean;
}

const useNotification = () => {
  const handleRemove = (id: string, text: string, type: string, closable?: boolean) => {
    toast.custom(
      <div
        className={`notification notification-out px-3 py-2 rounded-5 tx-13 tx-white alert-box-shadow bg-${type}`}
      >
        {text}
      </div>,
      { id },
    );

    setTimeout(() => {
      toast.remove(id);
    }, 300);
  };

  return useCallback(
    (
      text: string,
      {
        type = 'success',
        duration = 5000,
        id: _id,
        closable = false,
      }: NotificationProps = { type: 'success', duration: 5000 },
    ) => {
      const id = _id || new Date().getTime() + getRandomInt(100) + '';
      let timeout: NodeJS.Timeout;
      if (duration) {
        timeout = setTimeout(() => {
          handleRemove(id, text, type);
        }, duration);
      }

      toast.custom(
        <div
          className={cn([
            'd-flex',
            'notification',
            'px-3',
            'py-2',
            'rounded-5',
            'tx-13',
            'tx-white',
            'alert-box-shadow',
            `bg-${type}`,
            type === 'warning' && 'tx-black'
          ])}
          onClick={() => {
            if (timeout) {
              clearTimeout(timeout);
            }
            handleRemove(id, text, type, closable);
          }}
        >
          {text}
          {closable && <img src={close} alt="close" className="ms-1" />}
        </div>,
        { id, duration },
      );
    },
    [],
  );
};

export default useNotification;
