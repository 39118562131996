import {
  action,
  computed,
  makeAutoObservable,
  observable,
  reaction,
} from 'mobx';
import * as Sentry from '@sentry/react';
import api, { apiRaw, ApiServer, Paged, ResponseData } from '@helpers/api';
import { HotToken, WalletBalance } from '../types';
import { ChainId } from '../constants';
import { CreateOrderRequest } from '../types/order/create-order.request';
import { OrderResponse } from '../types/order/order.response';
import { OrdersSummaryResponse } from '../types/order/orders-summary.response';
import { TokenBalanceResponse } from '../types/order/token-balance.response';
import { OrderType } from '../types/order/order.enum';
import { CommandResultResponse } from '../types/command-result.response';
import { AccountStore } from '@stores/account-store';
import { CreateOrderResponse } from '../types/order/create-order.response';
import { WalletType } from '../types/enums';
import { hasOwnProperty } from '@helpers/object';
import { UpdateOrderRequest } from '../types/order/update-order.request';
import { chainToWallet } from '@helpers/chains';
import { AxiosError } from 'axios';
// import {Alchemy, Network, Utils} from "alchemy-sdk";
// import {alchemyToken} from "../constants";

const keys = ['_network'];

export class OrdersStore {
  private _accountStore: AccountStore;

  @observable
  private _initialized = false;

  @observable
  private _isSaving = false;

  @observable
  private _triggerUpdate = 0;

  @observable
  private _balance: WalletBalance | null = null;

  @observable
  private _summary: OrdersSummaryResponse[] = [];

  @observable
  private _isLoadingSummary = false;

  @observable
  private _hideDetails = false;

  constructor(accountStore: AccountStore) {
    this._accountStore = accountStore;
    makeAutoObservable(this);

    reaction(
      () => accountStore.currentWallet,
      () => this.loadBalance(),
    );
  }

  @computed
  profile() {
    return this._accountStore.currentWallet
      ? this._accountStore.currentWallet
      : null;
  }

  get isInit() {
    return (
      this.profile() !== null &&
      this._accountStore.isLoggedIn &&
      this.initialized
    );
  }

  get isEvm() {
    return this.profile()?.type === WalletType.EVM;
  }

  get isTon() {
    return this.profile()?.type === WalletType.TON;
  }

  get isTron() {
    return this.profile()?.type === WalletType.TRON;
  }

  get isSolana() {
    return this.profile()?.type === WalletType.SOL;
  }

  get currentOrderType() {
    return this.profile()?.type;
  }

  get network() {
    if (this.isTon) {
      return ChainId.TON;
    }
    if (this.isSolana) {
      return ChainId.SOLANA;
    }
    if (this.isTron) {
      return ChainId.TRON;
    }
    return this._accountStore.network;
  }

  @action
  async loadBalance(reset = true) {
    if (!this.isInit) return;

    if (reset) {
      this._balance = null;
    }

    return apiRaw<WalletBalance>({
      method: 'get',
      path: `/balance/native`,
      data: {
        walletId: this.profile()?.id,
        blockchain: this.network === ChainId.ALL ? ChainId.ETHER : this.network,
      },
    })
      .then((response) => {
        this._balance = response.data || null;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          Sentry.captureEvent(
            { message: 'Wallet ID Error' },
            { data: { response, source: 'orders-store' } },
          );
        } else {
          console.error(response);
        }
      });
  }

  @action
  setNetwork(value: ChainId) {
    if (!this._accountStore.isLoggedIn) return;

    if (this.network !== value) {
      const needType = chainToWallet(value);
      const { wallets } = this._accountStore;

      if (this.currentOrderType !== needType) {
        this._accountStore.setCurrentWallet(
          wallets.find(({ type }) => type === needType) ?? null,
        );
      }
      this._accountStore.setNetwork(value);
      this.handleUpdate();
    }
    return Promise.all([this.loadBalance(), this.loadSummary()]);
  }

  get balance() {
    return this._balance;
  }

  get summary() {
    return this._summary;
  }

  get isLoadingSummary() {
    return this._isLoadingSummary;
  }

  get isSaving() {
    return this._isSaving;
  }

  get triggerUpdate() {
    return this._triggerUpdate;
  }

  get initialized() {
    return this._initialized;
  }

  get hideDetails() {
    return this._hideDetails;
  }

  @action
  setHideDetails(value: boolean) {
    this._hideDetails = value;
    this.handleUpdate();
  }

  @action
  handleUpdate() {
    this._triggerUpdate++;
    this._isSaving = true;
  }

  @action
  setSaved() {
    this._isSaving = false;
  }

  @action
  async getTokenBalance(tokenAddress: string, blockchain?: ChainId) {
    if (!this.isInit) return;

    this._balance = null;

    return api<TokenBalanceResponse>({
      method: 'get',
      path: `/balance/token`,
      data: {
        walletId: this.profile()?.id,
        tokenAddress,
        blockchain:
          blockchain || this.network === ChainId.ALL
            ? ChainId.ETHER
            : this.network,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
      });
  }

  @action
  async loadOrders(pairId: string, filter?: string, type?: OrderType[]) {
    if (!this.isInit) return;

    const types = type ? type.map((v) => `type=${v}`).join('&') : undefined;

    return api<Paged<OrderResponse[]>>({
      method: 'get',
      path: `/order?${types ? '&' + types : ''}`,
      data: {
        blockchain: this.network,
        walletId: this.profile()?.id,
        pairId,
        filter,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getOrder(id: string) {
    return api<OrderResponse>({
      method: 'get',
      path: `/order/${id}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async loadSummary(seamless = false) {
    if (!this.isInit) return;

    if (!seamless) {
      this._isLoadingSummary = true;
    }

    return api<OrdersSummaryResponse[]>({
      method: 'get',
      path: `/order/summary`,
      data: {
        blockchain: this.network,
        walletId: this.profile()?.id,
      },
    })
      .then((response) => {
        this._summary = response;
        this._isLoadingSummary = false;
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          if (response instanceof AxiosError && response.response?.data) {
            console.error(response.response.data);
          } else {
            console.error(response);
          }
        }
        this._isLoadingSummary = false;
        return response.response;
      });
  }

  @action
  async loadHiddenSummary() {
    if (!this.isInit) return;

    return api<OrdersSummaryResponse[]>({
      method: 'get',
      path: `/order/summary/hidden`,
      data: {
        blockchain: this.network,
        walletId: this.profile()?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async createOrder(data: CreateOrderRequest) {
    return api<CreateOrderResponse>({
      method: 'post',
      path: `/order`,
      data,
    })
      .then((response) => {
        this._accountStore.loadPortfolio();
        this._accountStore.loadBalance();
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this._accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async updateOrder(data: UpdateOrderRequest) {
    return api<OrderResponse>({
      method: 'patch',
      path: `/order`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this._accountStore.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async cancelOrder(id: string) {
    return api<CommandResultResponse>({
      method: 'post',
      path: `/order/cancel`,
      data: {
        orderId: id,
      },
    })
      .then((response) => {
        return response.success;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async hideSummary(pairId: string, hidden: boolean) {
    return apiRaw<CommandResultResponse>({
      method: 'post',
      path: `/preferences/wallet/pair`,
      data: {
        walletId: this.profile()?.id,
        pairId,
        hidden,
      },
    })
      .then((response) => {
        return response.status === 201;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  get state() {
    return keys.map((key) => {
      return {
        key,
        // @ts-ignore
        value: key === '_network' ? this.network : this[key],
      };
    });
  }

  @action
  restoreSettings(keys: string[], values: string[]) {
    keys.forEach((key, index) => {
      try {
        const value = values[index] ? JSON.parse(values[index]) : null;
        if (
          hasOwnProperty(this, key) &&
          value &&
          // @ts-ignore
          JSON.stringify(this[key]) !== JSON.stringify(value)
        ) {
          // @ts-ignore
          this[key] = value;
        } else if (key === '_network' && value) {
          this.setNetwork(value);
        }
      } catch (e) {
        // console.log(e);
      }
    });
    this._initialized = true;
  }
}
