import {observer} from "mobx-react-lite";
import InfoTooltip from "@components/common/InfoTooltip";
import cross from "@assets/icons/cross.svg";
import Toggle from 'react-toggle';
import SnipeRow from "@pages/Profile/SnipeRow";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import {numberRegex} from "../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ISnipeSettings} from "../../types";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import IconWithLoading from "@components/common/IconWithLoading";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import useNotification from "@hooks/useNotification";
import {ApiError} from "@helpers/api";
import SettingsInput from "@components/common/SettingsInput";
import { useTranslation } from "react-i18next";

interface SnipeSettingsProps {
  isBot?: boolean;
}

const SnipeSettings = observer(({isBot}: SnipeSettingsProps) => {
  const { t } = useTranslation();
  const {accountStore} = useStores();
  const {snipeSettings} = accountStore;
  const navigate = useNavigate();
  const [settings, setSettings] = useState<ISnipeSettings>();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const WebApp = useWebApp();
  const notify = useNotification();

  const handleClose = () => {
    if (isBot && WebApp) {
      WebApp.close();
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (snipeSettings && !settings) {
      const {
        createdAt,
        updatedAt,
        id,
        userId,
        label,
        ...mainData
      } = snipeSettings;
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [snipeSettings, settings]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({...settings, stopLossEnabled: true});
    }
  }, [settings]);

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!settings) {
      return false;
    }
    // @ts-ignore
    const value = settings[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    return isNaN(value) || !numberRegex.test(value.toString()) || !!(error && error.error?.includes(key));
  }, [settings, error]);

  const handleOnChange = useCallback((key: string, value: string | number | null) => {
    if (error && error.error?.includes(key)) {
      setError(null);
    }
  }, [error]);

  if (!settings) {
    return (
      <div className="pb-3" id="snipe-settings">
        <div className="tx-28 tx-semibold my-3">
          {t('profile.trade-settings')}
        </div>

        <Preloader inline />
      </div>
    );
  }

  const handleSaving = () => {
    const checks = [
      {check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: t('common.stop-loss')},
      {check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: t('trading.take-profit')},
      {check: isInvalid('stopLossSellPercent', settings.stopLossEnabled), label: t('trading.stop-loss-amount')},
      {check: isInvalid('takeProfitSellPercent', settings.takeProfitEnabled), label: t('trading.take-profit-amount')},
      {check: isInvalid('sellSlippage', true), label: t('common.sell-slippage')},
      {check: isInvalid('buySlippage', true), label: t('trading.buy-slippage')},
      {check: isInvalid('sellGasDelta'), label: t('trading.sell-priority-fee')},
      {check: isInvalid('buyGasDelta'), label: t('trading.buy-priority-fee')},
      {check: isInvalid('approveGasDelta'), label: t('common.approve-priority-fee')},
    ];

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + t('common.invalid-or-empty'), {type: 'danger'});
      return;
    }

    const data = JSON.parse(JSON.stringify(settings));

    if (!settings.stopLossEnabled && !settings.stopLossPercent) {
      data.stopLossPercent = snipeSettings?.stopLossPercent || 0;
    }
    if (!settings.takeProfitEnabled && !settings.takeProfitPercent) {
      data.takeProfitPercent = snipeSettings?.takeProfitPercent || 0;
    }
    if (settings.stopLossPercent && settings.stopLossPercent > 0) {
      data.stopLossPercent = settings.stopLossPercent * -1;
    }

    setIsSaving(true);
    accountStore.saveSnipeSettings(data).then((response) => {
      setIsSaving(false);
      if (response.data?.label) {
        notify(t('profile.trade-settings-saved-successfully'));
      } else {
        notify(response.response.data.error, {type: 'danger'});
        setError(response.response.data);
      }
    }).catch((e) => {
      console.error(e);
      setIsSaving(false);
      notify(t('profile.error-saving-trade-settings'), {type: 'danger'});
    });
  };

  return (
    <div className="pb-3" id="snipe-settings">
      <div className="tx-28 tx-semibold my-3">
        {t('trading.take-profit-sell')}
      </div>

      <div>{t('common.stop-loss-sell')}</div>
      <div className="card mt-1">
        <SnipeRow>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="maxGasPrice"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip={t('trading.max-gas-price-description')}
          >
            {t('trading.max-gas-price')}
          </SettingsInput>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="approveGasDelta"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip={t('trading.extra-tip-priority-fee-description')}
          >
            {t('common.approve-priority-fee')}
          </SettingsInput>
        </SnipeRow>

        <SnipeRow className="mt-3">
          <SnipeColumn>
            <div>{t('trading.mev-guard')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.mevGuardEnabled}
              onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>{t('trading.fail-guard')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.failGuard}
              onChange={(e) => setSettings({...settings, failGuard: e.target.checked})}
            />
          </SnipeColumn>
        </SnipeRow>

        {/*{!isBot && <SnipeRow className="mt-3">*/}
        {/*  <SnipeColumn>*/}
        {/*    <div className="tx-gray-300">Anti Rug</div>*/}
        {/*    <Toggle*/}
        {/*      icons={false}*/}
        {/*      className="styled-toggle my-2"*/}
        {/*      disabled*/}
        {/*    />*/}
        {/*  </SnipeColumn>*/}
        {/*  <SnipeColumn>*/}
        {/*    <div>MEV Guard</div>*/}
        {/*    <Toggle*/}
        {/*      icons={false}*/}
        {/*      className="styled-toggle my-2"*/}
        {/*      checked={settings.mevGuardEnabled}*/}
        {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
        {/*    />*/}
        {/*  </SnipeColumn>*/}
        {/*</SnipeRow>}*/}

        <SnipeRow className="mt-3">
          <SnipeColumn className="tx-12 tx-semibold">
            <div>{t('trading.auto-approve')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.autoApprove}
              onChange={(e) => setSettings({...settings, autoApprove: e.target.checked})}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>{t('trading.trailing-stop-loss')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.trailingStopLossEnabled}
              onChange={(e) => setSettings({...settings, trailingStopLossEnabled: e.target.checked})}
            />
          </SnipeColumn>
        </SnipeRow>
      </div>


      <div className="mt-4">{t('trading.take-profit-stop-loss')}</div>
      <div className="card mt-1 mb-2">
        <SnipeRow>
          <SnipeColumn>
            <div>{t('trading.take-profit')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.takeProfitEnabled}
              onChange={(e) => setSettings({...settings, takeProfitEnabled: e.target.checked})}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>{t('common.stop-loss')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.stopLossEnabled}
              onChange={(e) => setSettings({...settings, stopLossEnabled: e.target.checked, trailingStopLossEnabled: false})}
            />
          </SnipeColumn>
        </SnipeRow>

        {settings.takeProfitEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('order.percentage-profit-sell-executed')}
              isRequired
            >
              {t('trading.take-profit')}
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('order.percentage-total-token-sold-profit-level-reached')}
            >
              {t('trading.take-profit-sell')} %
            </SettingsInput>
          </SnipeRow>
        )}

        {settings.stopLossEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('trading.percentage-loss-sell-executed')}
              isRequired
            >
              {t('common.stop-loss')}
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('trading.percentage-total-token-sold-loss-level-reached')}
            >
              {t('common.stop-loss-sell')} %
            </SettingsInput>
          </SnipeRow>
        )}
      </div>

      <div className="mt-4">{t('trading.buy-settings')}</div>
      <div className="card mt-1">
        <SnipeRow>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="buySlippage"
            isInvalidHandler={isInvalid}
            placeholder="1%"
            unit="%"
            onChange={handleOnChange}
            tooltip={t('common.buy-execution-percentage')}
            isRequired
          >
            {t('trading.slippage')}
          </SettingsInput>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="buyGasDelta"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip={t('trading.extra-tip-priority-fee-description')}
          >
            {t('trading.extra-tip-priority-fee-description')}
          </SettingsInput>
        </SnipeRow>
      </div>

      <div className="mt-4">{t('trading.sell-settings')}</div>
      <div className="card mt-1">
        <SnipeRow>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="sellSlippage"
            isInvalidHandler={isInvalid}
            placeholder="1%"
            unit="%"
            onChange={handleOnChange}
            tooltip={t('common.sale-execution-percentage')}
            isRequired
          >
            {t('trading.slippage')}
          </SettingsInput>
          <SettingsInput
            data={settings}
            setHandler={setSettings}
            dataKey="sellGasDelta"
            isInvalidHandler={isInvalid}
            placeholder="GWEI"
            onChange={handleOnChange}
            tooltip={t('trading.extra-tip-priority-fee-description')}
          >
            {t('trading.priority-fee')}
          </SettingsInput>
        </SnipeRow>
      </div>

      <div className="mt-4">
        <button
          className="btn wd-100p btn-primary-10"
          onClick={handleSaving}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} />
          {t('common.save-settings')}
        </button>

        <button
          className="btn btn-transparent tx-white wd-100p mt-2"
          onClick={handleClose}
          disabled={isSaving}
        >
          {t('common.close')}
        </button>
      </div>
    </div>
  );
});

export default SnipeSettings;
