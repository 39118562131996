import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import BigNumber from 'bignumber.js';
import './i18n';
import { SDKProvider } from '@telegram-apps/sdk-react';
import './mock-telegram';
import AppRouter from './AppRouter';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

BigNumber.config({ EXPONENTIAL_AT: 100, DECIMAL_PLACES: 36 });

root.render(
  <SDKProvider acceptCustomStyles debug>
    {/*<BrowserRouter>*/}
    <AppRouter>
      {/*<TelegramProvider>*/}
      <App />

      <Toaster
        toastOptions={{
          style: {
            border: 'none',
          },
          success: {
            style: {
              background: '#1C1C1C',
              color: '#32D74B',
            },
          },
          error: {
            style: {
              background: '#1C1C1C',
              color: '#FF453A',
            },
          },
        }}
      />
      {/*</TelegramProvider>*/}
    {/*</BrowserRouter>*/}
    </AppRouter>
  </SDKProvider>,
);
