import { action, computed, makeAutoObservable, observable, when } from 'mobx';
import { hasOwnProperty } from '@helpers/object';
import BigNumber from 'bignumber.js';
import api from '@helpers/api';
import { ChainId } from '../constants';
import { dexScreenerLink, dexToolsLink } from '@helpers/chains';
import { IOrderSettings } from 'types';
import { AuthStore } from './auth-store';

const keys = [
  '_notifyEnabled',
  '_amounts',
  '_triggers',
  '_percents',
  '_avatarOptions',
  '_chartService',
  '_analyticsBot',
  '_researchApproved',
  '_orderSettings',
  '_stopLossSettings',
  '_takeProfitSettings',
];

const defaultAmounts = ['10', '50', '100', '1000'];
const defaultPercents = ['25', '50', '75', '90'];
const defaultTriggers = ['25', '50', '200', '300'];

export const defaultOrderSettings: IOrderSettings = {
  autoApproveEnabled: true,
  antiFailEnabled: false,
  antiMevEnabled: true,
  extraGasPricePercent: null,
  slippage: null,
};

interface AvatarOptions {
  [key: string]: string;
}

export class SettingsStore {
  @observable
  private _initialized = false;

  @observable
  private _isSaving = false;

  @observable
  private _triggerUpdate = 0;

  @observable
  private _notifyEnabled = false;

  @observable
  private _researchApproved = false;

  @observable
  private _chartService: 'dexscreener' | 'dextools' = 'dexscreener';

  @observable
  private _amounts: string[] = defaultAmounts.slice();

  @observable
  private _percents: string[] = defaultPercents.slice();

  @observable
  private _triggers: string[] = defaultTriggers.slice();

  @observable
  private _analyticsBot: string = 'ttfbotbot';

  @observable
  private _orderSettings: { [key: string]: IOrderSettings } = {};

  @observable
  private _stopLossSettings: { [key: string]: IOrderSettings } = {};

  @observable
  private _takeProfitSettings: { [key: string]: IOrderSettings } = {};

  @observable
  private _avatarOptions: AvatarOptions = {};

  constructor(authStore: AuthStore) {
    makeAutoObservable(this);

    when(
      () => authStore.isLogged,
      () => this.restoreSettings(),
    );
  }

  get initialized() {
    return this._initialized;
  }

  get triggerUpdate() {
    return this._triggerUpdate;
  }

  get notifyEnabled() {
    return this._notifyEnabled;
  }

  get amounts() {
    return this._amounts.map((amount) => new BigNumber(amount).toNumber());
  }

  get percents() {
    return this._percents.map((amount) => new BigNumber(amount).toNumber());
  }

  get triggers() {
    return this._triggers.map((amount) => new BigNumber(amount).toNumber());
  }

  get chartService() {
    return this._chartService;
  }

  get analyticsBot() {
    return this._analyticsBot;
  }

  get orderSettings() {
    return Object.keys(this._orderSettings).length ? this._orderSettings : null;
  }

  get stopLossSettings() {
    return Object.keys(this._stopLossSettings).length
      ? this._stopLossSettings
      : null;
  }

  get takeProfitSettings() {
    return Object.keys(this._takeProfitSettings).length
      ? this._takeProfitSettings
      : null;
  }

  get isSaving() {
    return this._isSaving;
  }

  get researchApproved() {
    return this._researchApproved;
  }

  get defaultAmounts() {
    return defaultAmounts;
  }

  @action
  getChartLink(chain: ChainId | string, pairAddress: string, wallet?: string) {
    if (this._chartService === 'dextools') {
      return dexToolsLink(chain, pairAddress);
    }
    return dexScreenerLink(chain, pairAddress, wallet);
  }

  @action
  setChartService(value: 'dexscreener' | 'dextools') {
    this._chartService = value;
  }

  @action
  setAnalyticsBot(value: string = 'ttfbotbot') {
    this._analyticsBot = value;
  }

  @action
  setOrderSettings(value: { [key: string]: IOrderSettings }) {
    this._orderSettings = value;
    this.saveSettings();
  }

  @action
  setStopLossSettings(value: { [key: string]: IOrderSettings }) {
    this._stopLossSettings = value;
    this.saveSettings();
  }

  @action
  setTakeProfitSettings(value: { [key: string]: IOrderSettings }) {
    this._takeProfitSettings = value;
    this.saveSettings();
  }

  @computed
  avatarOption(wallet: string) {
    return hasOwnProperty(this._avatarOptions, wallet)
      ? this._avatarOptions[wallet]
      : '';
  }

  @action
  restoreAvatarOptions(options: AvatarOptions) {
    this._avatarOptions = options;
    this.saveSettings();
  }

  @action
  setAvatarOption(wallet: string, value: string) {
    this._avatarOptions[wallet] = value;
    this.handleUpdate();
  }

  @action
  handleUpdate() {
    this._triggerUpdate++;
    this._isSaving = true;
  }

  @action
  setNotifyEnabled(value: boolean) {
    this._notifyEnabled = value;
    this.handleUpdate();
  }

  @action
  setResearchApproved(value: boolean) {
    this._researchApproved = value;
    this.handleUpdate();
  }

  @action
  setAmounts(value: string[]) {
    this._amounts = defaultAmounts.slice().map((amount, index) => {
      return value[index] || amount;
    });
  }

  @action
  setPercents(value: string[]) {
    this._percents = defaultPercents.slice().map((amount, index) => {
      return value[index] || amount;
    });
  }

  @action
  setTriggers(value: string[]) {
    this._triggers = defaultTriggers.slice().map((amount, index) => {
      return value[index] || amount;
    });
  }

  @action
  setSaved() {
    this._isSaving = false;
  }

  get state() {
    const obj = {};
    keys.forEach((key) => {
      // @ts-ignore
      obj[key] = this[key];
    });
    return obj;
  }

  @action
  async saveSettings() {
    this._isSaving = true;
    try {
      await api({
        method: 'post',
        path: '/user/settings',
        data: {
          settings: this.state,
        },
      });
      this.setSaved();
      return true;
    } catch (e) {
      console.error(e);
      this.setSaved();
      return false;
    }
  }

  @action
  restoreSettings() {
    api<any>({
      method: 'get',
      path: '/user/settings',
    })
      .then((response) => {
        if (typeof response !== 'object' || !response) {
          console.error(response);
          return;
        }
        Object.keys(response).forEach((key) => {
          // @ts-ignore
          if (hasOwnProperty(this, key) && this[key] !== response[key]) {
            // @ts-ignore
            this[key] = response[key];
          }
        });
        this._initialized = true;
      })
      .catch((e) => {
        console.error(e);
        this._initialized = true;
      });
  }

  @action
  restoreSettingsDeprecated(keys: string[], values: string[]) {
    keys.forEach((key, index) => {
      try {
        const value = values[index] ? JSON.parse(values[index]) : null;
        if (
          hasOwnProperty(this, key) &&
          value &&
          // @ts-ignore
          JSON.stringify(this[key]) !== JSON.stringify(value)
        ) {
          if (key === '_amounts' && values[index] === '["0.5","1","1.5"]') {
            this.setAmounts(defaultAmounts.slice());
            return;
          }
          // @ts-ignore
          this[key] = value;
        }
      } catch (e) {
        // console.log(e);
      }
    });
    this._initialized = true;
  }
}
