import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import useInterval from '@hooks/useInterval';
import { useTranslation } from 'react-i18next';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { StyleVariant } from 'types/common.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ShowPopupButton extends Record<string, unknown> {
  id: string;
  type?: StyleVariant;
  text?: string;
  icon?: IconProp;
}

interface ShowPopupParams extends Record<string, unknown> {
  title?: string;
  message: JSX.Element | string;
  buttons?: ShowPopupButton[];
}

export interface ModalAlertProps extends ShowPopupParams {
  show?: boolean;
  proceed?: (id: string) => void;
  enableEscape?: boolean;
  closeTimeout?: number;
  closeButton?: boolean;
}

export default function ModalAlert(props: ModalAlertProps) {
  const { t } = useTranslation();

  const {
    title = '',
    message,
    buttons,
    show = true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    proceed = () => {},
    enableEscape = true,
    closeTimeout = 0,
    closeButton = false,
  } = props;

  const [timeLeft, setTimeLeft] = useState<number>(closeTimeout);

  const defaultButtons: ShowPopupButton[] = [
    {
      text: t('common.confirm'),
      type: 'success',
      id: 'confirm',
    },
    {
      text: t('common.cancel'),
      id: 'cancel',
    },
  ];

  useInterval(
    () => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    },
    timeLeft > 0 ? 1000 : null,
  );

  const header = title ? (
    <Modal.Header closeButton={closeButton}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  ) : null;

  return (
    <div className="static-modal">
      <Modal
        show={show}
        onHide={() => proceed('cancel')}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
        className="pb-3"
      >
        {header}
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center">
          {typeof message === 'string' ? (
            <>
              <div className="tx-28 tx-semibold tx-center mb-2">{title}</div>

              <div className="tx-muted tx-17">{message}</div>
            </>
          ) : (
            message
          )}

          {(buttons || defaultButtons).map(({ id, text, type, icon }) => {
            return (
              <div className="wd-100p mt-2" key={id}>
                <button
                  className={'btn wd-100p btn-' + type}
                  onClick={() => proceed(id)}
                  disabled={timeLeft !== 0}
                >
                  {timeLeft !== 0 && (
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="me-2"
                      spin
                    />
                  )}
                  {timeLeft === 0 && icon && (
                    <FontAwesomeIcon icon={icon} className="me-2" />
                  )}
                  {text}
                </button>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    </div>
  );
}
