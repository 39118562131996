import React from 'react';
import HistoryLogo from '@pages/Wallet/components/HistoryLogo';
import FormattedNumber from '@components/common/FormattedNumber';
import { getEllipsisTxt } from '@helpers/formatters';
import { ApiWalletTransactionsData, OperationType, Status } from '../../../types/transactions.types';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import useTransactionDetails from '@hooks/useTransactionDetails';
import { useTranslation } from 'react-i18next';
import { chainToken } from '@helpers/chains';
import { ChainId } from '../../../constants';

interface HistoryItemProps {
  item: ApiWalletTransactionsData;
  onClick: () => void;
  hide?: boolean;
}

const HistoryItem = observer(({ item, onClick, hide }: HistoryItemProps) => {
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const { assets } = accountStore;
  const {
    address,
    chain,
    status,
    operation,
    logoIn,
    logoOut,
    symbolIn,
    symbolOut,
    amountIn,
    amountOut,
    amountInUsd,
    amountOutUsd,
    approveLogo,
    approveSymbol,
    tokenIn,
    tokenOut,
    sentTo,
    isBuy,
    transactionHash,
  } = useTransactionDetails(item, assets);

  const inNative = symbolIn === chainToken(chain);
  const outNative = symbolOut === chainToken(chain);
  const mainLogo = approveLogo || logoIn;

  const handleClick = () => {
    if (chain === ChainId.TRON) {
      window.open(`https://tronscan.org/#/transaction/${transactionHash}`, '_blank');
      return;
    }
    onClick();
  };

  return (
    <div className={`pb-3 mb-3 cur-pointer with-hover ${hide ? 'd-none' : ''}`}>
      <div className="d-flex justify-content-between align-items-center wd-100p" onClick={handleClick}>
        <HistoryLogo
          type={operation}
          symbolIn={approveSymbol || symbolIn || t('common.na')}
          symbolOut={operation === OperationType.Trade ? symbolOut : undefined}
          chain={chain}
          addressIn={!mainLogo && !inNative && isBuy ? sentTo : undefined}
          addressOut={!logoOut && !outNative && isBuy === false ? sentTo : undefined}
          mainLogo={mainLogo}
          secondLogo={logoOut}
        />
        <div className="ms-3 wd-100p">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="text-capitalize">
              {item.isTaxPayout
                ? t('operation.alpha-tax')
                : t(`operation.${operation.toLowerCase()}`)}
            </div>
            {status === Status.Pending && (
              <div className="tx-yellow">
                {t('common.pending')} ⌛️
              </div>
            )}
            {status === Status.Failed && (
              <div className="tx-danger">
                {t('common.failed')} 🚫
              </div>
            )}
            {chain !== ChainId.TRON && operation !== OperationType.Approve && status === Status.Confirmed && (
              <>
                {(amountIn || amountOut) ? (
                  <FormattedNumber
                    value={(amountIn || amountOut) * (operation !== OperationType.Send ? 1 : -1)}
                    postfix={`${symbolIn || symbolOut}`}
                    className={`tx-right ${operation !== OperationType.Send ? 'tx-success' : 'tx-white'}`}
                    shorten={9}
                    subZeros
                    withSign
                  />
                ) : (<span>{t('common.na')}</span>)}
              </>
            )}
            {chain === ChainId.TRON && (
              <>
                {operation !== OperationType.Trade && operation !== OperationType.Approve && status === Status.Confirmed && (
                  <>
                    {(amountIn || amountOut) ? (
                      <FormattedNumber
                        value={(amountIn || amountOut) * (operation !== OperationType.Send ? 1 : -1)}
                        postfix={`${symbolIn || symbolOut}`}
                        className={`tx-right ${operation !== OperationType.Send ? 'tx-success' : 'tx-white'}`}
                        shorten={9}
                        subZeros
                        withSign
                      />
                    ) : (<span>{t('common.na')}</span>)}
                  </>
                )}
                {operation === OperationType.Trade && status === Status.Confirmed && (
                  <>
                    {amountIn ? (
                      <FormattedNumber
                        value={amountIn}
                        postfix={symbolIn}
                        className="tx-right tx-success"
                        shorten={9}
                        subZeros
                        withSign
                      />
                    ) : (<span>{t('common.na')}</span>)}
                  </>
                )}
              </>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
            {operation !== OperationType.Trade && (
              <div>{getEllipsisTxt((address || ''), 4, '0x')}</div>
            )}
            {operation === OperationType.Trade && !!amountOut && (
              <FormattedNumber
                suffix="-"
                value={amountOut || 0}
                postfix={`${symbolOut}`}
                shorten={9}
              />
            )}
            {operation !== OperationType.Approve && !!(amountInUsd || amountOutUsd) && (
              <FormattedNumber
                value={amountInUsd || amountOutUsd || 0}
                suffix="$"
                shorten={9}
              />
            )}
          </div>
        </div>
      </div>
      {/*{status === Status.Pending && operation !== OperationType.Receive && (*/}
      {/*  <div className="mt-3">*/}
      {/*    <button className="btn btn-dark bg-semi-transparent-10 border-semi-transparent-10 tx-13 px-3">*/}
      {/*      {t('common.speed-up')}*/}
      {/*    </button>*/}
      {/*    <button className="btn btn-outline-danger border-semi-transparent ms-3 tx-13 px-3">*/}
      {/*      {t('common.cancel')}*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
});

export default HistoryItem;
