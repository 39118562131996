import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import top50tokens from "@assets/images/features/top50tokens.png";
import autoSnipe from "@assets/images/features/auto-snipe.png";
import whaleSignals from "@assets/images/features/whale-signals.png";
import blockXRay from "@assets/images/features/block-x-ray.png";
import tokenSnipe from "@assets/images/features/sniping-scope.png";
// import limitOrders from "@assets/images/features/limit-orders.png";
import {Carousel} from "react-bootstrap";
import Badge from "@components/common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import { useTranslation } from "react-i18next";

const ComingSoon = observer(() => {
  const { t } = useTranslation();
  const { settingsStore } = useStores();
  const { notifyEnabled } = settingsStore;
  const navigate = useNavigate();
  const location = useLocation();

  // const initialIndex = location.pathname === PageRoutes.RESEARCH ? 1 : 0;

  const slides = [
    {
      image: autoSnipe,
      title: t('common.oracle'),
      text: t('oracle.transform-token-search'),
    },
    // {
    //   image: top50tokens,
    //   title: 'Top Tokens',
    //   text: 'We spotlight top DEX tokens that are not just leading by market capitalization or trading volume but are distinguished through a comprehensive analysis of their potential and performance metrics',
    // },
    // {
    //   image: whaleSignals,
    //   title: 'Whale Signals',
    //   text: 'Receive instant notifications about tokens as soon as the market giants pay attention to it, and follow their dynamics to ride the wave of opportunities',
    // },
    // {
    //   image: blockXRay,
    //   title: 'Block X-Ray',
    //   text: 'Trade and discover everything in one fell swoop, because our platform combines the search for new generation tokens with convenient trading tools to maximize profits',
    // },
    // {
    //   image: tokenSnipe,
    //   title: 'Sniping Scope',
    //   text: 'Analyze the activity of traders on contracts of new Ethereum tokens before their launch',
    // },
    // {
    //   image: limitOrders,
    //   title: 'Limit Orders',
    //   text: 'You will be able to set the values for buying or selling a token in just a couple of clicks, which will make managing your asset portfolio more convenient and efficient than ever before!',
    // },
  ];

  // const handleSubmit = () => {
  //   accountStore
  //     .register(pin)
  //     .then(() => {
  //       accountStore.loadUser().then(() => {
  //         navigate(PageRoutes.WALLET_CREATED, {replace: true});
  //       });
  //     })
  //     .catch((e) => {
  //       setPin('');
  //       setConfirmPin('');
  //       setIsInvalid(true);
  //       handleResponse(e.response);
  //       setError('');
  //     });
  // }

  return (
    <div className="tx-center full-page d-flex flex-column">
      <Carousel className="ht-100p" controls={false} defaultActiveIndex={0} interval={null} indicators={false}>
        {slides.map(({image, title, text}, index) => (
          <Carousel.Item className="ht-100p" key={`coming-soon-feature-${title}-${index}`}>
            <div className="d-flex flex-column align-items-center justify-content-center tx-center ht-100p">
              <div className="row">
                <img src={image} alt="Registration" className="wd-100p px-0" />
              </div>
              <h2 className="tx-28 d-flex align-items-center justify-content-center wd-100p mt-3">
                <span className="me-2">{title}</span> <Badge label={t('oracle.soon')} />
              </h2>
              <p className="tx-16 my-3 tx-muted">
                {text}
              </p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="mt-auto pb-5">
        <button
          className={`btn wd-100p ${notifyEnabled ? 'btn-outline-success tx-success' : 'btn-primary-10'}`}
          onClick={() => settingsStore.setNotifyEnabled(!notifyEnabled)}
        >
          {notifyEnabled ? (<><FontAwesomeIcon icon={faCheck} className="me-2" />{t('oracle.will-be-notified')}</>) : t('oracle.notify-me')}
        </button>

        <button
          className="btn btn-transparent tx-white wd-100p mt-3 mb-5"
          onClick={() => navigate(-1)}
        >
          {t('common.close')}
        </button>
      </div>
    </div>
  )
});

export default ComingSoon;
