import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useParams } from 'react-router-dom';
import useResponseHandler from '@hooks/useResponseHandler';
import PageLoader from '@components/PageLoader';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import NetworkSelectionPill from '@components/NetworkSelectionPill';
import WalletSelection from '@components/WalletSelection';
import UserAvatar from '@components/common/UserAvatar';
import { getEllipsisTxt } from '@helpers/formatters';
import FormattedNumber from '@components/common/FormattedNumber';
import chevronDown from '@assets/icons/chevron-down-gray.svg';
import OrderValue from '@pages/LimitOrders/components/OrderValue';
import Toggle from 'react-toggle';
import { chainToken } from '@helpers/chains';
import { ChainId } from '../../constants';
import { IOrderSettings, QuickModeRaw } from '../../types';
import { defaultOrderSettings } from '@stores/settings-store';
import BigNumber from 'bignumber.js';
import IconWithLoading from '@components/common/IconWithLoading';
import Preloader from '@components/common/Preloader';
import { Blockchain } from '../../types/enums';
import { OrderTrigger } from '../../types/order/order.enum';
import useNotification from '@hooks/useNotification';

const QuickTrade = observer(() => {
  const { t } = useTranslation();
  const handleResponse = useResponseHandler();
  const params = useParams();
  const notify = useNotification();
  useAmplitude([AmplitudeEvent.QUICK_TRADE_PAGE_VIEWED, { params }]);

  const { accountStore, ordersStore, tokenStore, tokenTrade } = useStores();
  const {
    currency,
    tpSlOrders,
  } = tokenTrade;

  const { currentWallet, network, quickTradeSettings, quickTradeSettingsLoaded } = accountStore;
  const { balance } = ordersStore;

  const [amount, setAmount] = useState<string | number | null>(0);
  const [usdAmount, setUsdAmount] = useState<string | number | null>(null);
  const [noConfirmation, setNoConfirmation] = useState<boolean>(false);
  const [isRestored, setIsRestored] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isInit = useMemo(() => {
    return !!tokenTrade.currency;
  }, [tokenTrade.currency]);

  const [settings, setSettings] = useState<IOrderSettings>(defaultOrderSettings);

  const enabledSettings = useMemo(() => {
    return quickTradeSettings.filter((s) => s.enabled);
  }, [quickTradeSettings]);

  const networkSettings = useMemo(() => {
    return quickTradeSettings.length
      ? quickTradeSettings.find((s) => s.blockchain === (network as unknown as Blockchain))
      : null;
  }, [quickTradeSettings, network]);

  const settingsData: QuickModeRaw = useMemo(() => {
    return {
      orderSettings: settings,
      valueIn: new BigNumber(amount || 0).toString(),
      walletId: currentWallet?.id || '0',
      needConfirm: !noConfirmation,
      orders: {
        takeProfits: tpSlOrders?.takeProfits || [],
        stopLosses: tpSlOrders?.stopLosses || [],
        trigger: OrderTrigger.PRICE_IN_USD,
      },
      blockchain: network as unknown as Blockchain,
    };
  }, [settings, amount, currentWallet, noConfirmation, tpSlOrders]);

  const handleEnable = () => {
    const bn = new BigNumber(amount || 0);
    if (bn.isGreaterThan(0)) {
      setIsSaving(true);
      accountStore.updateQuickTradeSettings({...settingsData, enabled: true}).then(() => {
        setIsSaving(false);
        notify(t('quick-trade.settings-enabled'), { type: 'success' });
      }).catch((e) => {
        setIsSaving(false);
        handleResponse(e, true);
      });
    }
  };

  const handleDisable = () => {
    if (!quickTradeSettingsLoaded || !networkSettings) return;

    setIsSaving(true);
    accountStore.updateQuickTradeSettings({ ...networkSettings, enabled: false }).then(() => {
      setIsSaving(false);
      notify(t('quick-trade.settings-disabled'), { type: 'success' });
    }).catch((e) => {
      setIsSaving(false);
      handleResponse(e, true);
    });
  };

  useEffect(() => {
    if (!quickTradeSettingsLoaded || !currency) return;

    if (networkSettings && currency) {
      setTimeout(() => {
        const usd = new BigNumber(networkSettings.valueIn).multipliedBy(currency || 0);
        setSettings(JSON.parse(JSON.stringify(networkSettings.orderSettings)));
        setAmount(networkSettings.valueIn);
        setUsdAmount(usd.isLessThan(0.01) ? usd.toString() : usd.toFixed(2));
        setNoConfirmation(!networkSettings.needConfirm);
        if (networkSettings.walletId !== currentWallet?.id) {
          accountStore.setCurrentWalletById(networkSettings.walletId);
        }
        tokenTrade.switchTakeProfits(!!networkSettings.orders.takeProfits?.length);
        if (networkSettings.orders.takeProfits?.length) {
          tokenTrade.setTakeProfits(networkSettings.orders.takeProfits);
        }
        tokenTrade.switchStopLosses(!!networkSettings.orders.stopLosses?.length);
        if (networkSettings.orders.stopLosses?.length) {
          tokenTrade.setStopLosses(networkSettings.orders.stopLosses);
        }
        setIsRestored(true);
      }, 50);
    } else {
      setSettings(defaultOrderSettings);
      setAmount(0);
      setUsdAmount(0);
      setNoConfirmation(false);
      tokenTrade.switchTakeProfits(false);
      tokenTrade.switchStopLosses(false);
      setIsRestored(true);
    }
  }, [networkSettings, quickTradeSettingsLoaded, currency, currentWallet]);

  useEffect(() => {
    tokenTrade.setRouteParams({chain: network, address: '', id: ''});
    return () => {
      tokenStore.reset();
      tokenTrade.reset();
    };
  }, [network]);

  if (!isInit || !quickTradeSettingsLoaded) {
    return <PageLoader />;
  }

  return (
    <div className="pt-4 pb-5 d-flex flex-column">
      <h1 className="my-3">
        {t('quick-trade.title')}
      </h1>

      {!isRestored && <Preloader />}

      <NetworkSelectionPill highlight={enabledSettings.map((s) => s.blockchain as unknown as ChainId)} displayAll />

      <WalletSelection
        className="btn btn-transparent py-2 d-flex align-items-center gap-2 card flex-row mt-3"
        setOnChange
      >
        <div className="d-flex align-items-center">
          <div className="wd-40 ht-40 mn-wd-40 mx-wd-40">
            <UserAvatar string={currentWallet?.address || ''} />
          </div>
          <div className="ms-2 me-auto d-flex flex-column justify-content-center tx-left tx-normal">
            <div className="tx-17 lh-3">
              {currentWallet?.name ||
                getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
            </div>
            <div className="tx-12 tx-muted">
              {getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
            </div>
          </div>
        </div>

        <div className="ms-auto me-0 d-flex flex-column tx-right">
          <div className="tx-14 tx-semibold">
            <FormattedNumber
              value={balance?.balanceInUsd}
              suffix="$"
              className="ms-1"
              noSpace
              floor
              subZeros
            />
          </div>
          <FormattedNumber
            className="tx-muted tx-12"
            value={balance?.balance}
            decimals={4}
            subZeros
            floor
            postfix={balance?.token}
          />
        </div>

        <img src={chevronDown} alt="chevron-down" />
      </WalletSelection>

      <OrderValue
        title={t('trading.buy-settings')}
        balance={null}
        amount={amount}
        amountChange={(v) => {
          setAmount(v);
        }}
        amountUsd={usdAmount}
        amountUsdChange={setUsdAmount}
        symbol={chainToken(network || ChainId.ETHER)}
        noPredefined
        noBalance
        settings={settings}
        setSettings={setSettings}
      />

      <div className="card mt-3 mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="tx-14 tx-semibold">
            {t('quick-trade.buy-confirmation-title')}
          </div>
          <Toggle
            icons={false}
            className="styled-toggle"
            checked={noConfirmation}
            onChange={(e) => setNoConfirmation(e.target.checked)}
          />
        </div>
        <div className="tx-12 tx-muted">
          {t('quick-trade.buy-confirmation-description')}
        </div>
        <div className="tx-12 tx-warning">
          {t('quick-trade.buy-confirmation-alert')}
        </div>
      </div>

      <button
        className={`btn btn-primary ${networkSettings?.enabled ? 'mb-3' : ''}`}
        onClick={handleEnable}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} className="me-2" />
        {t(!networkSettings?.enabled ? 'common.enable' : 'common.save')}
      </button>

      {networkSettings?.enabled && (
        <button
          className="btn btn-secondary"
          onClick={handleDisable}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} className="me-2" />
          {t('common.disable')}
        </button>
      )}
    </div>
  );
});

export default QuickTrade;
