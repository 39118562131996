import React from 'react';

interface TokenNameProps {
  text: string;
  size?: number | string;
  fontSize?: number;
  className?: string;
  bg?: string;
}

const TokenName = (props: TokenNameProps) => {
  const {
    text,
    size = 45,
    fontSize = 12,
    className = '',
    bg = 'bg-gray-800',
  } = props;

  return (
    <div
      className={`${bg} rounded-circle wd-${size} ht-${size} tx-${fontSize} px-1 d-flex lh-1 justify-content-center align-items-center tx-center pos-relative overflow-hidden ${className}`}>
      <div className="text-truncate">{text}</div>
    </div>
  );
};

export default TokenName;
