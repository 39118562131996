import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ChainId, ethRegex } from '../../constants';
import useDebounce from '@hooks/useDebounce';
import { useStores } from '@hooks/useStores';
import BadgeIcon from '@components/common/BadgeIcon';
import Preloader from '@components/common/Preloader';
import { HotToken } from '../../types';
import useResponseHandler from '@hooks/useResponseHandler';
import ResearchItem from '@pages/Research/components/ResearchItem';
import NetworkSelection from '@components/NetworkSelection';
import { chainLogo, chainToShort } from '@helpers/chains';
import arrow from '@assets/icons/chevron-down.svg';
import bell from '@assets/icons/bell.svg';
import { hasOwnProperty } from '@helpers/object';
import { useTranslation } from 'react-i18next';
import { InviteLink } from '../../types/research/invite-links.response';
import ResearchApproveModal from '@pages/Research/components/ResearchApproveModal';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import ResearchItemTron from '@pages/Research/components/ResearchItemTron';

const Research = observer(() => {
  const { t } = useTranslation();
  const [w] = useAmplitude([AmplitudeEvent.RESEARCH_SCREEN_VIEWED]);
  const { accountStore, ordersStore, settingsStore } = useStores();
  const { network: ordersNetwork, isInit } = ordersStore;
  const { researchApproved } = settingsStore;
  const { refId } = accountStore;
  const navigate = useNavigate();
  const [network, setNetwork] = useState<ChainId>(ordersNetwork);
  const [search, setSearch] = useState<string>('');
  const debounceSearch = useDebounce(search, 500);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [tokens, setTokens] = useState<HotToken[]>([]);
  // const [positions, setPositions] = useState<PositionModel[]>([]);
  const [isTokensLoaded, setIsTokensLoaded] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const [links, setLinks] = useState<InviteLink[]>([]);

  const isAddressValid = useMemo(() => {
    return ethRegex.test(search);
  }, [search]);

  useEffect(() => {
    accountStore
      .getResearchInviteLinks()
      .then((response) => {
        if (Array.isArray(response)) {
          setLinks(response);
        } else {
          handleResponse(response);
        }
      })
      .catch((e) => {
        handleResponse(e.response);
      });
  }, []);

  useEffect(() => {
    if (!isInit) return;
    setIsTokensLoaded(false);
    setTokens([]);
    ordersStore.setNetwork(network);
    accountStore
      .getHotTokens(network)
      .then((response) => {
        setIsTokensLoaded(true);
        if (Array.isArray(response)) {
          setTokens(response.slice(0, 20));
        } else {
          handleResponse(response);
        }
      })
      .catch((e) => {
        handleResponse(e.response);
        setIsTokensLoaded(true);
      });
  }, [network, isInit]);

  useEffect(() => {
    if (search) {
      setIsSearching(true);
    }
    setIsInvalid(false);
  }, [search]);

  useEffect(() => {
    if (debounceSearch && isAddressValid) {
      accountStore
        .getTokenDetails(debounceSearch)
        .then((response) => {
          setIsSearching(false);
          if (response && hasOwnProperty(response, 'pairAddress')) {
            navigate('/token-snipe/' + response.pairAddress);
          } else {
            setIsInvalid(true);
          }
        })
        .catch(() => {
          setIsInvalid(true);
          setIsSearching(false);
        });
    }
  }, [debounceSearch, isAddressValid]);


  useEffect(() => {
    if (search && search === debounceSearch && !isAddressValid) {
      setIsSearching(false);
      setIsInvalid(true);
    }
  }, [search, debounceSearch, isAddressValid]);

  const currentChainLink = useMemo(() => {
    return links.find((link) => link.blockchain.toString() === network);
  }, [links, network]);

  return (
    <div className="pt-3 pb-nav overflow-hidden">
      <div className="tx-28 tx-semibold d-flex align-items-start mb-2">
        <span>{t('common.research')}</span>
        <span className="tx-13 ms-1">({t('common.24h')})</span>

        {currentChainLink && (
          <a
            href={currentChainLink.link}
            target="_blank"
            rel="noreferrer"
            className="ms-auto me-3 lh-1"
          >
            <img src={bell} alt="bell" className="ht-20 mt-1" />
          </a>
        )}
        <div
          className={`card flex-row align-items-start p-2 me-0 ${currentChainLink ? '' : 'ms-auto'}`}
        >
          <NetworkSelection
            network={network}
            onChange={(network) =>
              w(setNetwork, AmplitudeEvent.NETWORK_SELECTED, { network })(
                network,
              )
            }
            disabledNetworks={[
              ChainId.ARBITRUM,
              ChainId.POLYGON,
              ChainId.AVALANCHE,
            ]}
          >
            <div className="d-flex align-items-center tx-12 wd-100p">
              <img src={chainLogo(network)} alt="chain" width={16} />
              <span className="ms-1 tx-uppercase">{chainToShort(network)}</span>
              <img src={arrow} alt="Choose network" width={16} />
            </div>
          </NetworkSelection>
        </div>
      </div>

      {!isTokensLoaded && (
        <div className="tx-center pt-5">
          <Preloader
            inline
            iconSize={64}
            className="d-flex flex-column align-items-center"
            textClass="d-block tx-semibold tx-28 mt-4"
            iconClass="d-block"
            text={t('research.loading-hot-tokens')}
          />
        </div>
      )}

      {/*<div className={`input-group mt-2 mb-3 rounded-2 ${isInvalid ? 'is-invalid' : ''}`}>*/}
      {/*  <div className="input-group-text py-0 ht-35 pe-1 ps-2 border-0">*/}
      {/*    <img src={searchIcon} alt="search" width={16} height={16} />*/}
      {/*  </div>*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className={`form-control ht-35 ps-0 py-1 border-0 outline-none ${isInvalid ? 'tx-danger is-invalid' : 'tx-gray-400'}`}*/}
      {/*    value={search}*/}
      {/*    onChange={(e) => setSearch(e.target.value)}*/}
      {/*    placeholder="Token address"*/}
      {/*  />*/}
      {/*  {!!search && (*/}
      {/*    <div className="input-group-text py-0 ht-35 pe-2 ps-1 border-0">*/}
      {/*      <img src={cross} alt="Clear" width={16} height={16} onClick={() => setSearch('')} />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      {search && isSearching && (
        <div className="tx-center pt-5">
          <Preloader
            inline
            iconSize={64}
            className="d-flex flex-column align-items-center"
            textClass="d-block tx-semibold tx-28 mt-4"
            iconClass="d-block"
            text={t('order.searching-token')}
          />
        </div>
      )}

      {search && !isSearching && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">
            🔎
          </BadgeIcon>
          <h1 className="tx-28 mt-4">{t('order.empty-message')}</h1>
          <div className="tx-muted my-3">{t('common.nothing-found')}</div>
        </div>
      )}

      {!search && (
        <div>
          {tokens.map((item, index) => network !== ChainId.TRON ? (
            <ResearchItem
              key={`research-token-${network}-${item.pairAddress}`}
              data={{ ...item, refId }}
              isLast={tokens.length - 1 === index}
              index={index}
              chain={network}
              chartLink={settingsStore.getChartLink(network, item.pairAddress)}
              analyticsBot={settingsStore.analyticsBot}
            />
          ) : (
            <ResearchItemTron
              key={`research-token-${network}-${item.pairAddress}`}
              data={{ ...item, refId }}
              isLast={tokens.length - 1 === index}
              index={index}
              chain={network}
              chartLink={settingsStore.getChartLink(network, item.pairAddress)}
              analyticsBot={settingsStore.analyticsBot}
            />
          ))}
        </div>
      )}

      {researchApproved === false && <ResearchApproveModal />}
    </div>
  );
});

export default Research;
