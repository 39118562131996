import {Locale, formatDistanceToNow} from "date-fns";
import { ru, enUS } from 'date-fns/locale';

export const getDateFnsLocale = (code: string) =>
  ({ 'en-EN': enUS, 'ru-RU': ru })[code] ?? enUS;

export default function timeAgo(date: number | string | Date, addSuffix = false, locale: Locale): string {
  return formatDistanceToNow(new Date(date), {addSuffix, locale}).replace('about ', '').replace('less than a ', '');
}

export function timeDiff(timestamp1: number, timestamp2: number) {
  // Convert timestamps from seconds to milliseconds
  const diffInMs = Math.abs(timestamp2 * 1000 - timestamp1 * 1000);

  // Calculate the difference in days, hours, minutes, and seconds
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

  // Format the output string
  if (days === 0 && hours === 0 && minutes === 0) {
    return `${seconds}s`;
  } else if (days === 0 && hours === 0) {
    return `${minutes}m ${seconds}s`;
  } else if (days === 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else {
    return `${days}d ${hours}h ${minutes}m`;
  }
}
