import {observer} from "mobx-react-lite";
import {PositionModel, PositionTransactionStatus, PositionTransactionType} from "../../types";
import React, {useEffect, useMemo, useState} from "react";
import {useStores} from "@hooks/useStores";
import {useNavigate, useParams} from "react-router-dom";
import Preloader from "@components/common/Preloader";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import SnipeDetailsItem from "@pages/TokenSnipe/SnipeDetailsItem";
import {PageRoutes, zeroHash} from "../../constants";
import useInterval from "@hooks/useInterval";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import useNotification from "@hooks/useNotification";
import BigNumber from "bignumber.js";
import useResponseHandler from "@hooks/useResponseHandler";
import { hasOwnProperty } from '@helpers/object';

interface PositionProcessingProps {
  isBot?: boolean;
}

const PositionProcessing = observer(({isBot}: PositionProcessingProps) => {
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState<PositionModel>();
  const [isRetryLoading, setIsRetryLoading] = useState(false);
  const WebApp = useWebApp();
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const handleClose = () => {
    if (isBot && WebApp) {
      WebApp.close();
    } else {
      navigate(PageRoutes.TOKEN_SNIPE, { replace: true });
    }
  };

  const isAutoApprove = useMemo(() => {
    return data?.settings.autoApprove || false;
  }, [data]);

  const transactions = useMemo(() => {
    return data?.transactions || [];
  }, [data]);

  const isSell = useMemo(() => {
    return transactions.some((tx) => tx.type === PositionTransactionType.SELL) || false;
  }, [transactions]);

  const buyTransaction = useMemo(() => {
    return transactions.find((tx) => tx.type === PositionTransactionType.BUY);
  }, [transactions]);

  const isBuyConfirmed = useMemo(() => {
    return transactions.some((tx) => tx.type === PositionTransactionType.BUY && tx.status === PositionTransactionStatus.CONFIRMED) || false;
  }, [transactions]);

  const sellTransaction = useMemo(() => {
    return transactions.find((tx) => tx.type === PositionTransactionType.SELL);
  }, [transactions]);

  const isSellConfirmed = useMemo(() => {
    return transactions.some((tx) => tx.type === PositionTransactionType.SELL && tx.status === PositionTransactionStatus.CONFIRMED) || false;
  }, [transactions]);

  const isApproveConfirmed = useMemo(() => {
    return transactions.some((tx) => tx.type === PositionTransactionType.APPROVE && tx.status === PositionTransactionStatus.CONFIRMED) || false;
  }, [transactions]);

  const approveTransaction = useMemo(() => {
    return transactions.find((tx) => tx.type === PositionTransactionType.APPROVE);
  }, [transactions]);

  const isApproveRequired = useMemo(() => {
    return isSell && !isAutoApprove && approveTransaction;
  }, [isSell, isAutoApprove, approveTransaction]);

  const isEverythingConfirmed = useMemo(() => {
    return (isSell ? isSellConfirmed : isBuyConfirmed) && (!isApproveRequired || isApproveConfirmed);
  }, [isSell, isSellConfirmed, isBuyConfirmed, isApproveRequired, isApproveConfirmed]);

  const failedTransaction = useMemo(() => {
    return transactions.find((tx) => tx.status === PositionTransactionStatus.FAILED || tx.status === PositionTransactionStatus.ERROR);
  }, [transactions]);

  const handleRetry = () => {
    if (!data) {
      return;
    }
    const {
      createdAt,
      updatedAt,
      id,
      userId,
      label,
      ...mainData
    } = data.settings;

    const sendData = JSON.parse(JSON.stringify(mainData));

    setIsRetryLoading(true);
    if (isSell) {
      accountStore.sellPosition(data.id, sellTransaction?.meta.sellAmountPercent || 0).then((succeed) => {
        if (succeed) {
          navigate(`/position-processing/${data.id}`);
        }
        setIsRetryLoading(false);
      }).catch((e) => {
        handleResponse(e);
        console.error(e);
        setIsRetryLoading(false);
      });
      return;
    }
    accountStore.openPosition({pairAddress: data.pair, ethAmount: new BigNumber(data.buyAmount).toString(), settings: sendData}).then((response) => {
      setIsRetryLoading(false);
      if (response && response.id) {
        navigate('/position-processing' + (isBot ? '-bot/' : '/') + response.id, {replace: true});
      } else {
        handleResponse(response.response);
      }
    }).catch((e) => {
      console.error(e);
      setIsRetryLoading(false);
      notify('Error. Try again', {type: 'danger'});
    })
  };

  useInterval(() => {
    if (hasOwnProperty(params, 'id') && params.id) {
      accountStore.getPosition(params.id)
        .then((response) => {
          if (response) {
            setData(response);
          }
        });
    }
  }, hasOwnProperty(params, 'id') && params.id && !isEverythingConfirmed && !failedTransaction ? 1000 : null);

  useEffect(() => {
    if (data) return;
    if (hasOwnProperty(params, 'id') && params.id) {
      accountStore.getPosition(params.id || '')
        .then((response) => {
          if (response) {
            setData(response);
          }
        });
    }
  }, [data, params]);

  if (!data || isRetryLoading) {
    return (
      <div className="tx-center pt-5">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text="Loading position info..." />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column align-items-center py-5">
      <HistoryLogo symbolIn={data.token.symbol} size={45} />
      <div className="tx-semibold tx-28 my-2">{isSell ? 'Close' : 'Open'} position {data.token.name}</div>

      <div className="card wd-100p py-0 mt-2">
        {!isSell && (<>
          <SnipeDetailsItem
            label={`Buy ${data.token.name} for ${data.buyAmount} ETH`}
            isActive={true}
            status={buyTransaction?.hash ? PositionTransactionStatus.CONFIRMED : PositionTransactionStatus.PENDING}
          />
          <SnipeDetailsItem
            label={`Buy confirmation`}
            isActive={!!buyTransaction?.hash}
            status={buyTransaction?.status || PositionTransactionStatus.PENDING}
            hash={data.transactions.find((tx) => tx.type === PositionTransactionType.BUY)?.hash || zeroHash}
            noBorder={!isApproveRequired}
          />
        </>)}
        {isApproveRequired && (<>
          <SnipeDetailsItem
            label={`Approve ${data.token.name} spending`}
            isActive={isBuyConfirmed}
            status={approveTransaction?.status || PositionTransactionStatus.PENDING}
          />
          <SnipeDetailsItem
            label={`Approve confirmation`}
            isActive={isApproveConfirmed}
            status={approveTransaction?.status || PositionTransactionStatus.PENDING}
            hash={data.transactions.find((tx) => tx.type === PositionTransactionType.APPROVE)?.hash || zeroHash}
            noBorder={!isSell}
          />
        </>)}
        {isSell && (<>
          <SnipeDetailsItem
            label={`Sell ${sellTransaction?.meta.sellAmountPercent || 0}% of ${data.token.name}`}
            isActive={approveTransaction && isApproveRequired ? isApproveConfirmed : true}
            status={sellTransaction?.status || PositionTransactionStatus.PENDING}
          />
          <SnipeDetailsItem
            label={`Sell confirmation`}
            isActive={isSellConfirmed || sellTransaction?.status === PositionTransactionStatus.ERROR || sellTransaction?.status === PositionTransactionStatus.FAILED}
            status={sellTransaction?.status || PositionTransactionStatus.PENDING}
            hash={data.transactions.find((tx) => tx.type === PositionTransactionType.SELL)?.hash || zeroHash}
            noBorder
          />
        </>)}
      </div>

      <button
        type="button"
        className="btn btn-primary-10 wd-100p mt-4 mb-3"
        disabled={failedTransaction ? false : !isEverythingConfirmed}
        onClick={handleClose}
      >
        Okay
      </button>

      {failedTransaction && (<>
        <button
          type="button"
          className="btn btn-transparent wd-100p mb-1"
          disabled={failedTransaction ? false : !isEverythingConfirmed}
          onClick={() => navigate('/token-snipe' + (isBot ? '-bot/' : '/') + data.pair, { replace: true, state: {settings: data?.settings} })}
        >
          Edit and retry
        </button>
        <button
          type="button"
          className="btn btn-transparent wd-100p mb-3"
          disabled={failedTransaction ? false : !isEverythingConfirmed}
          onClick={handleRetry}
        >
          Retry
        </button>
      </>)}

      {(isSell ? sellTransaction?.status === PositionTransactionStatus.CONFIRMED : buyTransaction?.status === PositionTransactionStatus.CONFIRMED) && (
        <div className="tx-success">
          Position successfully {isSell ? 'closed!' : 'opened!'}
        </div>
      )}

      {failedTransaction && (
        <div className="tx-danger wd-100p word-break">
          The transaction cannot succeed due to error: {failedTransaction.errorDescription}
        </div>
      )}
    </div>
  );
});

export default PositionProcessing;
