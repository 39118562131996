export interface ApiReferralLine {
  userId: string;
  line: number;
  turnover: string;
  turnover30d: string;
  profit: string;
  totalProfit: string;
  profit30d: string;
  refCount: number;
}

export interface ApiReferral {
  refId: string;
  totalProfit: number;
  unclaimedProfit: number;
  userRank: number;
  personalTurnover: number;
  referralTurnover: number;
  totalTurnover: number;
  personal30dTurnover: number;
  referral30dTurnover: number;
  total30dTurnover: number;
  refLines: ApiReferralLine[];
}

export enum ReferralLevelType {
  BRONZE = 0,
  SILVER = 1,
  GOLD = 2,
  PLATINUM = 3,
  DIAMOND = 4,
  MASTER = 5,
}

export interface ReferralLevel {
  type: ReferralLevelType;
  turnover: number;
  refPercent1Line: number;
  refPercent2Line: number;
  label: string;
}

export const RANK_RULES = [
  {
    rank: 0,
    label: 'Bronze',
    min30dUsdVolume: 0,
    refRewardPercents: [10, 5],
  },
  {
    rank: 1,
    label: 'Silver',
    min30dUsdVolume: 100000,
    refRewardPercents: [12, 8],
  },
  {
    rank: 2,
    label: 'Gold',
    min30dUsdVolume: 500000,
    refRewardPercents: [15, 10],
  },
  {
    rank: 3,
    label: 'Platinum',
    min30dUsdVolume: 1000000,
    refRewardPercents: [18, 12],
  },
  {
    rank: 4,
    label: 'Diamond',
    min30dUsdVolume: 2500000,
    refRewardPercents: [21, 14],
  },
  {
    rank: 5,
    label: 'Master',
    min30dUsdVolume: 5000000,
    refRewardPercents: [24, 16],
  },
];

export const referralLevels: ReferralLevel[] = [
  {
    type: ReferralLevelType.BRONZE,
    turnover: 0,
    refPercent1Line: 10,
    refPercent2Line: 5,
    label: 'Bronze',
  },
  {
    type: ReferralLevelType.SILVER,
    turnover: 40,
    refPercent1Line: 12,
    refPercent2Line: 8,
    label: 'Silver',
  },
  {
    type: ReferralLevelType.GOLD,
    turnover: 200,
    refPercent1Line: 15,
    refPercent2Line: 10,
    label: 'Gold',
  },
  {
    type: ReferralLevelType.PLATINUM,
    turnover: 400,
    refPercent1Line: 18,
    refPercent2Line: 12,
    label: 'Platinum',
  },
  {
    type: ReferralLevelType.DIAMOND,
    turnover: 1000,
    refPercent1Line: 21,
    refPercent2Line: 14,
    label: 'Diamond',
  },
  {
    type: ReferralLevelType.MASTER,
    turnover: 2000,
    refPercent1Line: 24,
    refPercent2Line: 16,
    label: 'Master',
  },
];
