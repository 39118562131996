import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {BlockchainGas, ISnipeSettings, TokenDetails} from "../../types";
import {useStores} from "@hooks/useStores";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Preloader from "@components/common/Preloader";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import FormattedNumber from "@components/common/FormattedNumber";
import CopyButton from "@components/common/CopyButton";
import {getEllipsisTxt} from "@helpers/formatters";
import {numberRegex} from "../../constants";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import SnipeRow from "@pages/Profile/SnipeRow";
import cross from "@assets/icons/cross.svg";
import chevron from "@assets/icons/chevron.svg";
import IconWithLoading from "@components/common/IconWithLoading";
import confirmAlert from "@components/ConfirmAlert";
import BigNumber from "bignumber.js";
import useNotification from "@hooks/useNotification";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import SettingsInput from "@components/common/SettingsInput";
import useResponseHandler from "@hooks/useResponseHandler";
import {ApiError} from "@helpers/api";
import LabelValueCard from "@components/common/LabelValueCard";
import gasIcon from "@assets/icons/gas-white.svg";
import NumericInput from "@components/common/NumericInput";
import { useTranslation } from "react-i18next";
import { hasOwnProperty } from '@helpers/object';

interface SnipeDetailsProps {
  data?: TokenDetails;
  isBot?: boolean;
}

const SnipeDetails = observer(({data, isBot = false}: SnipeDetailsProps) => {
  const { t } = useTranslation();
  const { accountStore, settingsStore } = useStores();
  const {amounts} = settingsStore;
  const { snipeSettings, balance } = accountStore;
  const navigate = useNavigate();
  const params = useParams();
  const [details, setDetails] = useState<TokenDetails>();
  const [settings, setSettings] = useState<ISnipeSettings>();
  const [gas, setGas] = useState<BlockchainGas>();
  const [error, setError] = useState<ApiError | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const { state } = useLocation();
  const WebApp = useWebApp();
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const handleClose = () => {
    if (isBot && WebApp) {
      WebApp.close();
    } else {
      navigate(-1);
    }
  };

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!settings) {
      return false;
    }
    // @ts-ignore
    const value = settings[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    if (error) {
      console.error(key, !!(error && error.error?.includes(key)));
    }
    return isNaN(value) || !numberRegex.test(value.toString()) || !!(error && error.error?.includes(key));
  }, [settings, error]);

  const handleOnChange = useCallback((key: string, value: string | number | null) => {
    if (error && error.error?.includes(key)) {
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({...settings, stopLossEnabled: true});
    }
  }, [settings]);

  useEffect(() => {
    if (snipeSettings && !settings && (!state || !state.settings)) {
      const {
        createdAt,
        updatedAt,
        id,
        userId,
        label,
        ...mainData
      } = snipeSettings;
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [snipeSettings, settings, state]);

  useEffect(() => {
    if (!settings && state?.settings) {
      const {
        createdAt,
        updatedAt,
        id,
        userId,
        label,
        ...mainData
      } = state.settings;
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [state, settings]);

  useEffect(() => {
    if (data && !details) {
      setDetails(data);
    }
    setIsMounted(true);
  }, [data, details]);

  useEffect(() => {
    if (isMounted && !data && !details && hasOwnProperty(params, 'address')) {
      accountStore.getTokenDetails(params.address || '')
        .then((response) => {
          if (response) {
            setDetails(response);
          }
        });
    }
  }, [isMounted, data, details, params]);

  useEffect(() => {
    if (!gas) {
      accountStore.getBlockchainGas().then((response) => {
        if (response) {
          setGas(response);
        }
      });
    }
  }, [gas]);

  if (!details || !settings) {
    return (
      <div className="tx-center pt-4">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text={t('position.loading-token-info')} />
      </div>
    )
  }

  const guards = [
    {
      label: t('trading.mev-blocker'),
      value: settings.mevGuardEnabled,
    },
    {
      label: t('trading.fail-guard'),
      value: settings.failGuard,
    },
    {
      label: t('trading.anti-rug'),
      value: false,
    },
    {
      label: t('trading.trailing-stop-loss'),
      value: settings.trailingStopLossEnabled,
    },
    {
      label: t('trading.auto-approve'),
      value: settings.autoApprove,
    },
  ];

  const confirmItems = [
    {
      label: t('trading.take-profit'),
      value: settings.takeProfitPercent,
      unit: '%',
      altValue: new BigNumber(amount).multipliedBy(settings.takeProfitPercent || 0).dividedBy(100).toNumber(),
      altUnit: 'ETH',
      enabled: settings.takeProfitEnabled,
    },
    {
      label: t('common.stop-loss'),
      value: settings.stopLossPercent,
      unit: '%',
      altValue: new BigNumber(amount).multipliedBy(settings.stopLossPercent || 0).dividedBy(100).toNumber(),
      altUnit: 'ETH',
      enabled: settings.stopLossEnabled,
    },
    {
      label: t('position.tp-sl-amount'),
      text: `${settings.takeProfitEnabled ? settings.takeProfitSellPercent : 0}/${settings.stopLossEnabled ? settings.stopLossSellPercent : 0}`,
      enabled: settings.takeProfitEnabled || settings.stopLossEnabled,
    },
    {
      label: t('common.sell-slippage'),
      value: settings.sellSlippage,
      unit: '%',
    },
    {
      label: t('trading.sell-priority-fee'),
      value: settings.sellGasDelta || 'N/A',
      unit: 'GWEI',
      enabled: !!settings.sellGasDelta,
    },
    {
      label: t('position.initial-amount'),
      value: amount,
      unit: 'ETH',
    },
    {
      label: t('position.guard'),
      text: guards.filter((guard) => guard.value).map((guard) => guard.label).join(', ') || 'None',
    },
  ];

  const handleSnipe = () => {
    const checks = [
      {check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: t('common.stop-loss')},
      {check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: t('trading.take-profit')},
      {check: isInvalid('sellSlippage', true), label: t('common.sell-slippage')},
      {check: isInvalid('buySlippage', true), label: t('trading.buy-slippage')},
      {check: isInvalid('sellGasDelta'), label: t('trading.sell-priority-fee')},
      {check: isInvalid('buyGasDelta'), label: t('trading.buy-priority-fee')},
      {check: isInvalid('approveGasDelta'), label: t('common.approve-priority-fee')},
    ];

    if (!amount) {
      notify(t('position.enter-amount'), {type: 'danger'});
      return;
    }

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + t('common.invalid-or-empty'), {type: 'danger'});
      return;
    }

    const data = JSON.parse(JSON.stringify(settings));

    if (!settings.stopLossEnabled && !settings.stopLossPercent) {
      data.stopLossPercent = snipeSettings?.stopLossPercent || 0;
    }
    if (!settings.takeProfitEnabled && !settings.takeProfitPercent) {
      data.takeProfitPercent = snipeSettings?.takeProfitPercent || 0;
    }
    if (settings.stopLossPercent && settings.stopLossPercent > 0) {
      data.stopLossPercent = settings.stopLossPercent * -1;
    }

    setIsSaving(true);
    accountStore.openPosition({pairAddress: details.pairAddress, ethAmount: amount, settings: data}).then((response) => {
      setIsSaving(false);
      if (response && response.id) {
        navigate('/position-processing' + (isBot ? '-bot/' : '/') + response.id, {replace: true});
      } else {
        handleResponse(response);
        setError(response.data);
      }
    }).catch((e) => {
      handleResponse(e);
      console.error(e);
      setIsSaving(false);
      notify('Error. Try again', {type: 'danger'});
    })
  };

  const handleConfirm = () => {
    const checks = [
      {check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: t('common.stop-loss')},
      {check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: t('trading.take-profit')},
      {check: isInvalid('sellSlippage', true), label: t('common.sell-slippage')},
      {check: isInvalid('buySlippage', true), label: t('trading.buy-slippage')},
      {check: isInvalid('sellGasDelta'), label: t('trading.sell-priority-fee')},
      {check: isInvalid('buyGasDelta'), label: t('trading.buy-priority-fee')},
      {check: isInvalid('approveGasDelta'), label: t('common.approve-priority-fee')},
    ];

    if (!amount) {
      notify(t('position.enter-amount'), {type: 'danger'});
      return;
    }

    if (new BigNumber(balance?.balance || 0).isLessThan(amount)) {
      notify(t('position.not-enough-eth'), {type: 'danger'});
      return;
    }

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + t('common.invalid-or-empty'), {type: 'danger'});
      return;
    }

    // confirmAlert({
    //   title: 'Open Position Confirmation',
    //   okLabel: t('common.open'),
    //   closeButton: true,
    //   confirmation: (
    //     <div>
    //       <div className="d-flex flex-column align-items-center justify-content-center tx-center py-2 wd-100p">
    //         <HistoryLogo symbolIn={details.token.symbol} size={45} />
    //         <div className="tx-semibold tx-28 mt-2 mb-1">{t('common.trade')} {details.token.name}</div>
    //         <div className="tx-muted tx-17">{t('position.open-position-question')}</div>
    //       </div>

    //       <div className="card bg-semi-transparent-10">
    //         <div className="wd-100p pb-2 mb-1 border-bottom border-semi-transparent">
    //           <div className="tx-17 tx-semibold">{details.token.name}</div>
    //           <div className="d-flex align-items-center tx-13 wd-100p">
    //             <CopyButton text={details.token.address} className="btn btn-link p-0 tx-13 tx-left text-decoration-none" iconClass="tx-white ms-1">
    //               <span className="tx-muted">{t('common.token')}: </span>
    //               {getEllipsisTxt(details.token.address,3, '0x')}
    //             </CopyButton>
    //             <CopyButton text={details.pairAddress} className="btn btn-link p-0 tx-13 tx-left text-decoration-none ms-2" iconClass="tx-white ms-1">
    //               <span className="tx-muted">{t('common.pair')}: </span>
    //               {getEllipsisTxt(details.pairAddress,3, '0x')}
    //             </CopyButton>
    //           </div>
    //         </div>

    //         {confirmItems.filter((v) => v.enabled !== false).map(({label, text, value, unit, altValue, altUnit}, index) => (
    //           <div className={`d-flex align-items-center justify-content-between tx-13 ${index && 'mt-1'}`} key={`snipe-main-details-${label}`}>
    //             <div className="tx-muted">{label}</div>
    //             {!!text && <div className="tx-right">{text}</div>}
    //             {!!value && (
    //               <div>
    //                 <FormattedNumber value={value} postfix={unit} withSign={value < 0} subZeros />
    //                 {!!altValue && (
    //                   <FormattedNumber
    //                     value={altValue}
    //                     suffix=" ("
    //                     postfix={`${altUnit})`}
    //                     withSign={altValue < 0}
    //                     subZeros
    //                   />
    //                 )}
    //               </div>
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   )
    // }).then((response) => {
    //   if (response) {
    //     handleSnipe();
    //   }
    // });
  }

  return (
    <div className="pt-3 pb-5">

      <div className="d-flex justify-content-end align-items-center tx-13 my-3">
        <img src={gasIcon} alt="gas" width={16} height={16} className="me-2"/>
        <div className="me-1">{t('common.gas')}:</div>
        <FormattedNumber
          value={parseInt(gas?.gasPrice || "0")}
          postfix={gas?.unit.toUpperCase()}
        />
      </div>

      <div className="card mb-4">
        <div className="d-flex flex-row justify-content-between align-items-center wd-100p pb-3">
          <HistoryLogo symbolIn={details.token.symbol} size={45}/>

          <div className="wd-100p ms-2">
            <div className="tx-17 tx-semibold">{details.token.name}</div>

            <div className="d-flex justify-content-between align-items-center tx-13">
              <CopyButton text={details.token.address} className="btn btn-link p-0 tx-13 tx-left text-decoration-none"
                          iconClass="tx-white ms-1">
                <span className="tx-muted">{t('common.token')}: </span>
                {getEllipsisTxt(details.token.address, 3, '0x')}
              </CopyButton>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center wd-100p gap-3">
          <LabelValueCard label={t('common.liquidity')} value={details.liquidity} unit="$"/>
          <LabelValueCard label={t('common.volume')} value={details.volume} unit="$"/>
          <LabelValueCard label={t('common.mcap')} value={details.mcap} unit="$"/>
        </div>
      </div>

      <div className="card mb-4">
        <div className="d-flex align-items-center justify-content-end tx-13 mb-1">
          <div className="ms-0 me-auto">
            {t('common.amount')}
          </div>
          <div className="tx-muted">{t('common.balance')}</div>
          <FormattedNumber
            value={balance?.balance || 0}
            decimals={4}
            subZeros
            floor
            postfix="ETH"
            className="tx-white ms-1 cur-pointer"
          />
          <FormattedNumber
            value={balance?.balanceInUsd || 0}
            className="tx-white ms-1 cur-pointer"
            suffix="($"
            postfix=")"
            noSpace
            floor
            subZeros
          />
        </div>
        <div className="input-group">
          <NumericInput
            value={amount}
            onChange={(v) => setAmount(new BigNumber(v || '').toString())}
            isInvalid={new BigNumber(balance?.balance || 0).isLessThan(amount)}
            placeholder="0.1 ETH"
            className="form-control wd-100p appearance-none"
          />
          {amount !== '' && (
            <div className="input-group-text">
              <img src={cross} alt="Clear" onClick={() => setAmount('')}/>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2 tx-13">
          {amounts.map((a) => (
            <button
              className="btn btn-semi-10 p-2 justify-content-center align-items-center wd-100p"
              onClick={() => setAmount(a.toString())}
              disabled={new BigNumber(balance?.balance || 0).isLessThan(a)}
              key={a}
            >
              {a} ETH
            </button>
          ))}
        </div>
      </div>

      <div>{t('trading.take-profit-stop-loss')}</div>
      <div className="card mt-1 mb-4">
        <SnipeRow>
          <SnipeColumn>
            <div>{t('trading.take-profit')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.takeProfitEnabled}
              onChange={(e) => setSettings({...settings, takeProfitEnabled: e.target.checked})}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>{t('common.stop-loss')}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.stopLossEnabled}
              onChange={(e) => setSettings({
                ...settings,
                stopLossEnabled: e.target.checked,
                trailingStopLossEnabled: false
              })}
            />
          </SnipeColumn>
        </SnipeRow>

        {settings.takeProfitEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('order.percentage-profit-sell-executed')}
              isRequired
            >
              {t('order.percentage-profit-sell-executed')}
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('order.percentage-total-token-sold-profit-level-reached')}
            >
              {t('trading.take-profit-sell')} %
            </SettingsInput>
          </SnipeRow>
        )}

        {settings.stopLossEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('trading.percentage-loss-sell-executed')}
              isRequired
            >
              {t('common.stop-loss')}
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('trading.percentage-total-token-sold-loss-level-reached')}
            >
              {t('common.stop-loss-sell')} %
            </SettingsInput>
          </SnipeRow>
        )}
      </div>

      <div className={`mb-4 ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
        <div>{t('trading.general-settings')}</div>
        <div className="card mt-1">
          <SnipeRow>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="maxGasPrice"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip={t('trading.max-gas-price-description')}
            >
              {t('trading.max-gas-price')}
            </SettingsInput>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="approveGasDelta"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip={t('trading.extra-tip-priority-fee-description')}
            >
              {t('common.approve-priority-fee')}
            </SettingsInput>
          </SnipeRow>

          <SnipeRow className="mt-3">
            <SnipeColumn>
              <div>{t('trading.mev-guard')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.mevGuardEnabled}
                onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('trading.fail-guard')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.failGuard}
                onChange={(e) => setSettings({...settings, failGuard: e.target.checked})}
              />
            </SnipeColumn>
          </SnipeRow>

          {/*<SnipeRow className="mt-3">*/}
          {/*  <SnipeColumn>*/}
          {/*    <div className="tx-gray-300">Anti Rug</div>*/}
          {/*    <Toggle*/}
          {/*      icons={false}*/}
          {/*      className="styled-toggle my-2"*/}
          {/*      disabled*/}
          {/*    />*/}
          {/*  </SnipeColumn>*/}
          {/*  <SnipeColumn>*/}
          {/*    <div>MEV Guard</div>*/}
          {/*    <Toggle*/}
          {/*      icons={false}*/}
          {/*      className="styled-toggle my-2"*/}
          {/*      checked={settings.mevGuardEnabled}*/}
          {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
          {/*    />*/}
          {/*  </SnipeColumn>*/}
          {/*</SnipeRow>*/}

          <SnipeRow className="mt-3">
            <SnipeColumn className="tx-12 tx-semibold">
              <div>{t('trading.auto-approve')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.autoApprove}
                onChange={(e) => setSettings({...settings, autoApprove: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('trading.trailing-stop-loss')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.trailingStopLossEnabled}
                onChange={(e) => setSettings({...settings, trailingStopLossEnabled: e.target.checked})}
              />
            </SnipeColumn>
          </SnipeRow>
        </div>

        <div className="mt-4">{t('trading.buy-settings')}</div>
        <div className="card mt-1">
          <SnipeRow>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="buySlippage"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('common.buy-execution-percentage')}
              isRequired
            >
              {t('trading.slippage')}
            </SettingsInput>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="buyGasDelta"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip={t('trading.extra-tip-priority-fee-description')}
            >
              {t('trading.priority-fee')}
            </SettingsInput>
          </SnipeRow>
        </div>

        <div className="mt-4">{t('Sell settings')}</div>
        <div className="card mt-1">
          <SnipeRow>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="sellSlippage"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip={t('common.sale-execution-percentage')}
              isRequired
            >
              {t('common.sale-execution-percentage')}
            </SettingsInput>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="sellGasDelta"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip={t('trading.extra-tip-priority-fee-description')}
            >
              {t('trading.priority-fee')}
            </SettingsInput>
          </SnipeRow>
        </div>
      </div>

      <div className="pb-4 mb-3 tx-13 tx-center border-bottom border-semi-transparent cur-pointer with-hover"
           onClick={() => setIsDetailsOpen((prev) => !prev)}>
        <span>{t('trading.more-settings')}</span>
        <img src={chevron} alt="toggle details" width={16} height={16}
             className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`}/>
      </div>

      <button
        className="btn wd-100p btn-primary-10"
        onClick={handleConfirm}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving}/>
        {t('common.trade')}
      </button>

      <button
        className="btn btn-transparent tx-white wd-100p mt-2"
        onClick={handleClose}
        disabled={isSaving}
      >
        {t('common.close')}
      </button>
    </div>
  );
});

export default SnipeDetails;
