import React, { useEffect, useState } from 'react';
import { getEllipsisTxt } from '@helpers/formatters';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import confetti from 'canvas-confetti';
import { WalletType } from '../../types/enums';
import UserAvatar from '@components/common/UserAvatar';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const WalletCreated = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountStore } = useStores();
  const { address, wallets } = accountStore;
  const [isInit, setIsInit] = useState<boolean>(false);
  const { state } = useLocation();
  const [w] = useAmplitude([AmplitudeEvent.WALLET_CREATED]);

  useEffect(() => {
    if (!isInit) {
      confetti();
      setIsInit(true);
    }
  }, [isInit]);

  return (
    <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
      <div className="rounded-30 bg-gray-800 px-2 py-1 border-1 border-solid border-semi-transparent d-flex align-items-center mb-3">
        <div className="wd-35 ht-35 rounded-circle overflow-hidden">
          <UserAvatar
            string={
              state?.address ||
              wallets.find((w) => w.type === WalletType.EVM)?.address ||
              address
            }
            size={35}
          />
        </div>
        <div className="tx-semibold ms-2">
          {getEllipsisTxt(
            state?.address ||
              wallets.find((w) => w.type === WalletType.EVM)?.address ||
              address,
            4,
          )}
        </div>
      </div>

      <h1 className="tx-28 mb-0">{t('registration.wallet-created')} 🎉</h1>
      <div className="tx-muted mt-3">
        {t('registration.wallet-created-description')}
      </div>
      <div className="mb-4">{t('registration.wallet-created-sub')}</div>

      <button
        className="btn btn-primary-10 wd-200"
        onClick={w(
          () => navigate(PageRoutes.LOGIN, { state }),
          AmplitudeEvent.SEED_PHRASE_COPY_CONTINUED,
        )}
      >
        {t('common.continue')}
      </button>
    </div>
  );
});

export default WalletCreated;
