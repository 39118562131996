import {AutoTradeSource, IAutoTradeFilters} from "../../../types";
import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import SnipeRow from "@pages/Profile/SnipeRow";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import {numberRegex, PageRoutes} from "../../../constants";
import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import usePopup from "@hooks/usePopup";
import {mockFilters} from "../../../mocks";
import SettingsInput from "@components/common/SettingsInput";
import {useLocation, useNavigate} from "react-router-dom";
import chevron from "@assets/icons/chevron.svg";
import {
  flatToStrategy,
  strategyFiltersKeys, strategyFiltersViewKeys,
  StrategyMainData,
  strategyToFlat,
  StrategyType
} from "@pages/AutoTrade/constants";
import useNotification from "@hooks/useNotification";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { hasOwnProperty } from '@helpers/object';

const StrategyFilters = observer(() => {
  const { accountStore } = useStores();
  const [filters, setFilters] = useState<IAutoTradeFilters | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [closedSettings, setClosedSettings] = useState<string[]>(['Volume', 'Buy & Sell tax', 'Buys', 'Sells', 'Price', 'Holders', 'Snipers']);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const notify = useNotification();
  const showPopup = usePopup();
  const navigate = useNavigate();
  const {state} = useLocation();
  const { t } = useTranslation();

  const defaultClosed = [
    t('common.volume'),
    t('strategy.buy-and-sell-tax'),
    t('strategy.buys'),
    t('strategy.sells'),
    t('common.price'),
    t('common.holders'),
    t('common.snipers')
  ];

  useEffect(() => {
    if (!isInitialized && filters) {
      setIsInitialized(true);
      if (state?.strategy) {
        const labels: string[] = [];
        strategyFiltersKeys.forEach(({label, rows, keys}) => {
          let hasValue = false;
          rows.forEach((items) => {
            items.forEach((item) => {
              const {key, type} = item;
              const value = (filters as any)[key];
              if (type === 'toggle' && value) {
                hasValue = true;
                return;
              } else if (type === 'input' && value !== null && !keys) {
                hasValue = true;
                return;
              } else if (type === 'input' && keys) {
                keys.forEach((variable) => {
                  const value = (filters as any)[key.replace('%key', variable)];
                  if (value !== null) {
                    hasValue = true;
                    return;
                  }
                });
              }
            });
          });
          if (!hasValue) {
            labels.push(label);
          }
        });
        setClosedSettings(labels.length ? labels : defaultClosed);
      } else {
        setClosedSettings(defaultClosed);
      }
    }
  }, [isInitialized, state, filters]);

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!filters) {
      return false;
    }
    // @ts-ignore
    const value = filters[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    return isNaN(value) || !numberRegex.test(value.toString());
  }, [filters]);

  const handleClear = () => {
    setFilters(JSON.parse(JSON.stringify(state?.strategy?.filters ? strategyToFlat(state?.strategy?.filters) : mockFilters)));
  }

  useEffect(() => {
    if (!filters) {
      handleClear();
    }
  }, [filters, accountStore.snipeSettings]);

  const checkMinMax = (key: string, groupLabel: string, itemLabel: string | undefined) => {
    let value = (filters as any)[key];
    const maxKey = key.replace('min', 'max').replace(t('common.min'), t('common.max'));
    let maxValue = (filters as any)[maxKey];
    if (value && maxValue) {
      value = new BigNumber(value).toNumber();
      maxValue = new BigNumber(maxValue).toNumber();
      let minLabel = itemLabel === t('common.min') || !itemLabel ? groupLabel + ` ${t('common.min')}` : itemLabel;
      let maxLabel = itemLabel === t('common.min') || !itemLabel ? groupLabel + ` ${t('common.max')}` : itemLabel.replace(t('common.min'), t('common.max'));
      if (!minLabel.toLowerCase().includes('min')) {
        minLabel += ` ${t('common.min')}`;
      }
      if (!maxLabel.toLowerCase().includes('max')) {
        maxLabel += ` ${t('common.max')}`;
      }
      if (value && maxValue && value > maxValue) {
        notify(`${t('strategy.limits-warn'), { minLabel, maxLabel }}`, {type: 'danger'});
        return true;
      }
    }
    return false;
  }

  const handleSubmit = () => {
    if (!filters || !state) return;

    let passed = true;

    strategyFiltersKeys.forEach(({label: groupLabel, keys, rows}) => {
      rows.forEach((items) => {
        items.forEach(({label: itemLabel, key, type}) => {
          if (type === 'input' && key.toLowerCase().includes('min')) {
            if (!keys) {
              if (checkMinMax(key, groupLabel, itemLabel)) {
                passed = false;
                return;
              }
            } else {
              keys.forEach((variableKey) => {
                if (checkMinMax(key.replace('%key', variableKey), groupLabel, itemLabel + ' ' + variableKey)) {
                  passed = false;
                  return;
                }
              });
            }
          }
        });
      });
    });

    if (!passed) {
      return;
    }

    const {
      createdAt,
      updatedAt,
      id,
      userId,
      label,
      ...rest
    } = state.settings;

    const strategy: StrategyMainData = {
      name: state.name,
      active: true,
      positionSettings: {
        ...rest,
        ethAmount: state.amount,
      },
      filters: flatToStrategy(filters),
    };
    setIsSaving(true);

    if (state?.strategy) {
      accountStore.updateStrategy(state.strategy.id, {name: state.name, filters: flatToStrategy(filters), active: state.strategy.active, positionSettings: {...rest, ethAmount: state.amount}}).then((response) => {
        setIsSaving(false);
        if (hasOwnProperty(response, 'id')) {
          notify(t('strategy.strategy-updated'));
          navigate(PageRoutes.AUTO_TRADE);
        } else {
          notify(response.data.error, {type: 'danger'});
        }
      }).catch((e) => {
        console.error(e);
        setIsSaving(false);
        notify(t('strategy.strategy-error'), {type: 'danger'});
      });
      return;
    }

    accountStore.createStrategy({...strategy, type: StrategyType.BLOCKCHAIN}).then((response) => {
      setIsSaving(false);
      if (hasOwnProperty(response, 'positionSettings')) {
        notify(t('strategy.strategy-created'));
        navigate(PageRoutes.AUTO_TRADE);
      } else {
        notify(response.data.error, {type: 'danger'});
      }
    }).catch((e) => {
      console.error(e);
      setIsSaving(false);
      notify('Error creating strategy', {type: 'danger'});
    });
  };

  const handleToggleSettings = (label: string) => {
    if (closedSettings.indexOf(label) !== -1) {
      setClosedSettings(closedSettings.filter((item) => item !== label));
    } else {
      setClosedSettings([...closedSettings, label]);
    }
  }

  useEffect(() => {
    if (!state || !state.settings || !state.amount || !state.name) {
      navigate(-1);
    }
  }, [state]);

  if (!filters) {
    return (
      <div className="tx-center pt-5">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text={t('strategy.loading-settings')} />
      </div>
    )
  }

  return (
    <div className="pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="tx-28 tx-semibold">
          Filter settings
        </div>
        <button
          onClick={() => {
            showPopup({
              title: t('strategy.clear-settings-t'),
              message: t('strategy.clear-settings-m'),
              buttons: [
                {
                  text: t('common.clear'),
                  type: 'danger',
                  id: 'clear',
                },
                {
                  text: t('common.cancel'),
                  id: 'close',
                },
              ],
            }).then((result) => {
              if (result === 'clear') {
                handleClear();
              }
            });
          }}
          className="btn btn-link text-decoration-none tx-semibold tx-primary tx-13 tx-right px-0 py-0"
        >
          {t('strategy.clear-settings-l')}
        </button>
      </div>

      <div>
        {strategyFiltersKeys.map(({label, keys, rows}, groupIndex) => {
          const isClosed = closedSettings.indexOf(label) !== -1;
          return (
            <div className="card mb-4" key={`settings-list-group-${label}-${groupIndex}`}>
              <div
                className={`d-flex justify-content-between align-items-center cur-pointer ${isClosed ? '' : 'mb-3'}`}
                onClick={() => handleToggleSettings(label)}
              >
                <div className="tx-15 tx-semibold">{label}</div>
                <img
                  src={chevron}
                  alt="toggle details"
                  width={16}
                  height={16}
                  className={`will-rotate ${isClosed ? '' : 'rotate-180'}`}
                />
              </div>

              <div className={isClosed ? 'd-none' : 'd-block'}>
                {!keys && rows.map((items, rowIndex) => (
                  <SnipeRow key={`settings-list-row-${groupIndex}-${rowIndex}`} className={rowIndex > 0 ? 'mt-2' : ''}>
                    {items.map(({label, key, type, placeholder, unit}, itemIndex) => {
                      if (type === 'input') {
                        return (
                          <SettingsInput
                            data={filters}
                            setHandler={setFilters}
                            dataKey={key}
                            isInvalidHandler={isInvalid}
                            placeholder={placeholder}
                            unit={unit}
                            disabled={isSaving}
                            key={`settings-list-item-${groupIndex}-${rowIndex}-${itemIndex}-${key}`}
                          >
                            {label ? <div>{label}</div> : <div>&nbsp;</div>}
                          </SettingsInput>
                        );
                      }
                      return (
                        <SnipeColumn key={`settings-list-item-${groupIndex}-${rowIndex}-${itemIndex}-${key}`}>
                          {label ? <div>{label}</div> : <div>&nbsp;</div>}
                          <Toggle
                            icons={false}
                            className="styled-toggle my-2"
                            checked={(filters as any)[key]}
                            disabled={isSaving}
                            onChange={(e) => setFilters({...filters, [key]: e.target.checked})}
                          />
                        </SnipeColumn>
                      );
                    })}
                  </SnipeRow>
                ))}
                {keys && keys.map((inputKey, inputIndex) => (
                  <React.Fragment key={`settings-list-variable-key-${groupIndex}-${inputIndex}`}>
                    {rows.map((items, rowIndex) => (
                      <SnipeRow key={`settings-list-row-${groupIndex}-${rowIndex}`}
                                className={inputIndex > 0 ? 'mt-2' : ''}>
                        {items.map(({label, key, placeholder, unit}, itemIndex) => {
                          return (
                            <SettingsInput
                              data={filters}
                              setHandler={setFilters}
                              dataKey={key.replace('%key', inputKey)}
                              isInvalidHandler={isInvalid}
                              placeholder={placeholder}
                              unit={unit}
                              key={`settings-list-item-${groupIndex}-${rowIndex}-${itemIndex}-${key}`}
                            >
                              {label ? <div>{label} {inputKey}</div> : <div>&nbsp;</div>}
                            </SettingsInput>
                          );
                        })}
                      </SnipeRow>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      <button
        className="btn btn-primary-10 wd-100p mt-3"
        onClick={handleSubmit}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} />
        {state?.strategy ? t('common.start') : t('common.update')} {t('common.strategy')}
      </button>

      <button
        className="btn btn-transparent wd-100p mt-2 mb-3"
        onClick={() => {
          showPopup({
            title: t('strategy.close-settings-t'),
            message: t('strategy.close-settings-m'),
            buttons: [
              {
                text: t('common.cancel'),
                type: 'danger',
                id: 'cancel-strategy',
              },
              {
                text: t('common.close'),
                id: 'cancel',
              },
            ],
          }).then((result) => {
            if (result === 'cancel-strategy') {
              navigate(PageRoutes.AUTO_TRADE, {state: {source: AutoTradeSource.ETH}});
            }
          });
        }}
        disabled={isSaving}
      >
        {t('common.cancel')}
      </button>
    </div>
  );
});

export default StrategyFilters;
