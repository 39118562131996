import React, { useEffect } from 'react';
import { logEvent } from '@amplitude/analytics-browser';
import { EventOptions } from '@amplitude/analytics-types';
import AmplitudeEvents from 'types/amplitude.types';

type AmplitudeEvent = {
  [K in keyof typeof AmplitudeEvents]: (typeof AmplitudeEvents)[K] extends string
    ? (typeof AmplitudeEvents)[K]
    : never;
}[keyof typeof AmplitudeEvents];

type AmplitudeEventTuple = [
  AmplitudeEvent,
  Record<string, any>?,
  EventOptions?,
];
type AmplitudeEnterLeaveEvent = [
  enterEvent?: AmplitudeEventTuple,
  leaveEvent?: AmplitudeEventTuple,
];

export function useAmplitude(
  ...event: AmplitudeEnterLeaveEvent
): [
  (
    fn: (...args: [any]) => any,
    eventInput: string | false,
    eventProperties?: Record<string, any>,
    eventOptions?: EventOptions,
  ) => any,
  ({
    children,
    className,
    eventInput,
    eventProperties,
    eventOptions,
  }: {
    children: React.ReactNode;
    className?: string;
    eventInput: string;
    eventProperties?: Record<string, any>;
    eventOptions?: EventOptions;
  }) => JSX.Element,
] {
  const [enterEvent, leaveEvent] = event;

  const handlerWrapper =
    (
      fn: (...args: [any]) => any,
      eventInput: string | false,
      eventProperties?: Record<string, any>,
      eventOptions?: EventOptions,
    ) =>
    (...args: [...any]) => {
      eventInput && logEvent(eventInput, eventProperties, eventOptions);
      //@ts-ignore
      return fn(...args);
    };

  const AmplitudeWrapper = ({
    children,
    className,
    eventInput,
    eventProperties,
    eventOptions,
  }: {
    children: React.ReactNode;
    className?: string;
    eventInput: string;
    eventProperties?: Record<string, any>;
    eventOptions?: EventOptions;
  }) => {
    const handler = () => logEvent(eventInput, eventProperties, eventOptions);

    return (
      <div className={className} onClick={handler}>
        {children}
      </div>
    );
  };

  if (enterEvent) {
    useEffect(() => {
      logEvent(...(enterEvent as AmplitudeEventTuple));

      if (leaveEvent) {
        return () => {
          logEvent(...leaveEvent);
        };
      }
    }, []);
  }
  return [handlerWrapper, AmplitudeWrapper];
}

export const AmplitudeEvent = AmplitudeEvents;
