import React, {useEffect, useState} from "react";
import Preloader from "@components/common/Preloader";
import {useNavigate} from "react-router-dom";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import {observer} from "mobx-react-lite";
import key from "@assets/icons/key-teal.svg";
import CopyButton from "@components/common/CopyButton";
import {PageRoutes} from "../../constants";
import { useTranslation } from "react-i18next";
import BadgeIconNew from '@components/common/BadgeIconNew';

const ExportPrivateKey = observer(({isCreate = false}: {isCreate?: boolean}) => {
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const [privateKey, setPrivateKey] = useState<string>('');
  const navigate = useNavigate();
  const handleResponse = useResponseHandler();

  useEffect(() => {
    if (!privateKey) {
      accountStore
        .loadPrivateKey()
        .then((response) => {
          if (response && response.privateKey) {
            setPrivateKey(response.privateKey);
          } else {
            handleResponse(response.response, true);
            navigate(-1);
          }
        })
        .catch((e) => {
          setPrivateKey('');
          handleResponse(e.response);
          navigate(-1);
        });
    }
  }, [privateKey]);

  if (!privateKey && isCreate) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2"
                   iconClass="d-block" text=""/>
        <h1 className="tx-28 my-3">
          {t('wallet.creating-wallet')}
        </h1>
        <div className="tx-muted px-5">{t('wallet.creating-process')}</div>
      </div>
    )
  }

  if (!privateKey) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" />
      </div>
    )
  }

  return (
    <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center pt-5">
      <BadgeIconNew className="mb-3">
        <img src={key} alt="key" width={20} />
      </BadgeIconNew>

      <h1 className="tx-28 mb-0">{t('wallet.your-private-key')}</h1>
      <div className="tx-muted my-3">
        {t('wallet.private-key-description')}
      </div>

      <div className="card tx-16 tx-left mb-3">
        <div className="tx-white tx-semibold">{t('wallet.private-key')}</div>

        <div className="tx-muted word-break my-3">
          {privateKey}
        </div>

        <CopyButton text={privateKey} className="btn btn-semi-10 wd-100p" noIcon>
          {t('common.copy')}
        </CopyButton>
      </div>


      {!isCreate && (
        <button
          className="btn btn-primary-10 wd-100p mt-auto"
          onClick={() => navigate(-1)}
        >
          {t('common.close')}
        </button>
      )}
      {isCreate && (
        <button
          className="btn btn-primary-10 wd-100p mt-auto"
          onClick={() => navigate(PageRoutes.NEW_WALLET_NAME)}
        >
          {t('common.continue')}
        </button>
      )}
    </div>
  );
});

export default ExportPrivateKey;
