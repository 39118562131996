import React from "react";
import activeIcon from "@assets/whale-signals/active.svg";
import inactiveIcon from "@assets/whale-signals/inactive.svg";
import { useTranslation } from "react-i18next";

/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
const SignalSecurity = ({signal}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-block d-sm-block mt-3">
        <div className="tx-17 tx-semibold mb-1">{t('signal.security')}</div>

        <div className="card bg-semi-transparent-10 px-2 py-1 tx-12">
          <div className="row">
            <div className="col-6">
              <img
                src={signal.security.buyTax <= 10 && signal.security.sellTax <= 10 ? activeIcon : inactiveIcon}
                width={8} alt="" className="me-1"/>
              {t('common.tax')}: <span className="plus">  B: {signal.security.buyTax}% </span>
              <span className="minus"> S: {signal.security.sellTax}% </span>
            </div>
            <div className="col-6">
              <img src={signal.security.lockedLpPercent > 90 ? activeIcon : inactiveIcon}
                   width={8} alt="" className="me-1"/>
              <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                    data-bs-title={`${signal.security.lockedLpPercent}%`}>
                                                  {t('signal.liquidity-lock')}
                                              </span>
            </div>
            <div className="col-6">
              <img src={signal.security.isHoneypot ? inactiveIcon : activeIcon}
                   width={8} alt="" className="me-1"/>
              {t('signal.honeypot')}
            </div>
            <div className="col-6">
              <img src={signal.security.contractVerified ? activeIcon : inactiveIcon}
                   width={8} alt="" className="me-1"/>
              {t('signal.contract-verified')}
            </div>
            <div className="col-6"><img src={signal.security.contractRenounced ? activeIcon : inactiveIcon}
                     width={8} alt="" className="me-1"/>
              {t('signal.contract-renounced')}
            </div>
            <div className="col-6">
              <img
                src={signal.security.creatorBalancePercent < 5 ? activeIcon : inactiveIcon}
                width={8} alt="" className="me-1"/>
              {t('signal.owner-balance')} &#60; 5%
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalSecurity;

