import React from "react";
import Preloader from "@components/common/Preloader";

interface PageLoaderProps {
  text?: string;
}

const PageLoader = ({ text }: PageLoaderProps) => {
  return (
    <div className="py-3">
      <Preloader
        className="d-flex flex-column align-items-center justify-content-center ht-100p tx-center"
        iconSize={65}
        textClass="mt-3"
        text={text}
        inline
      />
    </div>
  );
}

export default PageLoader;
