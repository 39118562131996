import React from "react";
import { useTranslation } from "react-i18next";

/* eslint-disable react/prop-types */
const SignalInitialLiquidity = ({signal}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-block liquidity mt-2">
        <div className="row gx-2">
          <div className="col-6">
            <div className="card bg-semi-transparent-10 px-2 py-1">
              <div className="tx-12 tx-muted">
                {t('signal.initial-liq')}
              </div>
              {signal.liquidity.initialAmount} {signal.liquidity.quoteSymbol}
            </div>
          </div>
          <div className="col-6">
            <div className="card bg-semi-transparent-10 px-2 py-1">
              <div className="tx-12 tx-muted">
                {t('signal.current-liq')}
              </div>
              {signal.liquidity.currentAmount} {signal.liquidity.quoteSymbol}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalInitialLiquidity;

