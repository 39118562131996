import React, {useCallback, useMemo, useState} from "react";
import {OrderType} from "../../../types";
import {Modal} from "react-bootstrap";
import filter from "@assets/icons/filter.svg";
import SnipeRow from "@pages/Profile/SnipeRow";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import { useTranslation } from "react-i18next";

interface OrdersFilterModalProps {
  filters: OrderType[];
  updateFilters: (value: OrderType[]) => void;
}

const OrdersFilterModal = ({ filters, updateFilters }: OrdersFilterModalProps) => {
  const { t } = useTranslation();
  
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setShow(false);
  };

  const isAllChecked = useMemo(() => {
    return filters.length === Object.keys(OrderType).length;
  }, [filters]);

  const handleChange = useCallback((key: OrderType, value: boolean) => {
    if (value) {
      updateFilters([...filters, key]);
    } else {
      updateFilters(filters.filter((item) => item !== key));
    }
  }, [updateFilters, filters]);

  const handleAllChange = () => {
    if (isAllChecked) {
      updateFilters([]);
    } else {
      updateFilters(Object.values(OrderType));
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('common.filters')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SnipeRow className="mb-3">
            <SnipeColumn>
              <div>{t('common.all')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={isAllChecked}
                onChange={handleAllChange}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('common.stop-loss')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={filters.indexOf(OrderType.STOP_LOSS) !== -1}
                onChange={(e) => handleChange(OrderType.STOP_LOSS, e.target.checked)}
              />
            </SnipeColumn>
          </SnipeRow>

          <SnipeRow className="mb-3">
            <SnipeColumn>
              <div>{t('order.market-buy')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={filters.indexOf(OrderType.MARKET_BUY) !== -1}
                onChange={(e) => handleChange(OrderType.MARKET_BUY, e.target.checked)}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('order.market-sell')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={filters.indexOf(OrderType.MARKET_SELL) !== -1}
                onChange={(e) => handleChange(OrderType.MARKET_SELL, e.target.checked)}
              />
            </SnipeColumn>
          </SnipeRow>

          <SnipeRow className="mb-3">
            <SnipeColumn>
              <div>{t('order.limit-buy')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={filters.indexOf(OrderType.LIMIT_BUY) !== -1}
                onChange={(e) => handleChange(OrderType.LIMIT_BUY, e.target.checked)}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('order.limit-sell')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={filters.indexOf(OrderType.LIMIT_SELL) !== -1}
                onChange={(e) => handleChange(OrderType.LIMIT_SELL, e.target.checked)}
              />
            </SnipeColumn>
          </SnipeRow>

          <div className="tx-muted">{t('order.displaying-transactions')}</div>
        </Modal.Body>
      </Modal>

      <div
        className="d-flex justify-content-between align-items-center cur-pointer tx-17 mb-4"
        onClick={() => setShow(true)}
      >
        <div>
          <span>{t('order.view')}</span>
          <span className="ms-1 tx-capitalize tx-muted">
            {isAllChecked && t('common.all')}
            {filters.length === 1 && filters[0].replace('_', ' ')}
            {!isAllChecked && filters.length > 1 && `${filters.length} ${t('order.selected-filters')}`}
            {!isAllChecked && !filters.length && t('common.nothing-filter')}
          </span>
        </div>
        <img src={filter} alt="filter" width={24} height={24} className="ms-auto"/>
      </div>
    </>
  );
};

export default OrdersFilterModal;
