import { action, makeAutoObservable, observable, reaction } from 'mobx';
import api, { Paged } from '@helpers/api';
import { ResearchPairResponse } from 'types/research/research-pair.response';
import { TokenBalanceResponse } from 'types/order/token-balance.response';
import { GasResponse } from 'types/common.types';
import { AccountStore } from './account-store';
import { OrdersStore } from './orders-store';
import { AxiosError } from 'axios';
import { ChainId, PageRoutes } from '../constants';
import { WalletType } from 'types/enums';
import { OrderResponse } from 'types/order/order.response';
import { OrderStatus, OrderType } from 'types/order/order.enum';
import BigNumber from 'bignumber.js';

export type TokenParams = Record<'chain' | 'address' | 'pairId', string>;
export type TradeParams = Record<'chain' | 'address' | 'id', string>;

export class TokenError extends Error {
  navigate?: string | number;

  constructor({
    message,
    navigate,
  }: {
    message: string;
    navigate?: string | -1;
    [key: string]: any;
  }) {
    super(message);
    this.message = message;
    this.navigate = navigate;
  }
}

export class TokenStore {
  private _accountStore: AccountStore;
  private _ordersStore: OrdersStore;

  @observable
  private _error: TokenError | null;

  @observable
  private _tokenDetails: ResearchPairResponse | null;

  @observable
  private _tokenBalance: TokenBalanceResponse | null;

  @observable
  private _tokenParams: Partial<TokenParams> | null;

  @observable
  private _gas: GasResponse | null;

  @observable
  private _orders: OrderResponse[] | null;

  get error() {
    return this._error;
  }

  /** TokenParams */
  get chain() {
    return this._tokenParams?.chain;
  }
  get address() {
    return this._tokenParams?.address;
  }
  get pairId() {
    return this._tokenParams?.pairId;
  }

  get balance() {
    return this._ordersStore.balance;
  }

  get networkChecked() {
    return this.chain === this._ordersStore.network;
  }

  get tokenDetails() {
    return this._tokenDetails;
  }

  get tokenBalance() {
    return this._tokenBalance;
  }

  get gas() {
    return this._gas;
  }

  get orders() {
    return this._orders;
  }

  get isExecuting() {
    return this.orders?.some(
      (order) =>
        [OrderType.MARKET_BUY, OrderType.MARKET_SELL].includes(order.type) &&
        order.status === OrderStatus.EXECUTING,
    );
  }

  get nativeTokenPrice() {
    return new BigNumber(this.tokenDetails?.usdPrice || 0)
      .div(this.tokenDetails?.priceInBaseToken || 0)
      .toNumber();
  }

  get nativeTokenPriceUsd() {
    return !this._ordersStore.balance
      ? 0
      : new BigNumber(this._ordersStore.balance.balanceInUsd)
          .div(this._ordersStore.balance.balance)
          .toNumber();
  }

  get tokenUsdPrice() {
    if (this.tokenBalance?.tokenBalanceInUsd) {
      return new BigNumber(this.tokenBalance.tokenBalanceInUsd).div(this.tokenBalance.tokenBalance).toString();
    }
    return this.tokenDetails?.usdPrice || '0';
  }

  get tokenBalanceFormatted() {
    return !this.tokenBalance || !this.tokenDetails
      ? null
      : {
          balance: this.tokenBalance.tokenBalance,
          balanceInUsd: this.tokenBalance.tokenBalanceInUsd,
          token: this.tokenDetails.quoteToken.symbol,
        };
  }

  get limitData() {
    return {
      price: this.tokenDetails?.usdPrice,
      priceBase: this.tokenDetails?.priceInBaseToken,
      mCap: this.tokenDetails?.mcap,
      liquidity: this.tokenDetails?.liquidity?.usd,
    };
  }

  constructor(accountStore: AccountStore, ordersStore: OrdersStore) {
    this._accountStore = accountStore;
    this._ordersStore = ordersStore;
    makeAutoObservable(this);

    reaction(
      () =>
        this._accountStore.wallets.length &&
        !!this.chain &&
        this.chain !== this._ordersStore.network,
      (rule) => {
        if (rule) {
          this._ordersStore.setNetwork(this.chain as ChainId);
          this.getBlockchainGas();
        }
      },
    );

    reaction(
      () => {
        return this.address && this._ordersStore.isInit;
      },
      (rule) => {
        if (rule) {
          this.getPairDetails();
          this.getBlockchainGas();
          this._ordersStore.loadBalance();
        }
      },
    );

    reaction(
      () => this._ordersStore.isInit && this.pairId,
      (rule) => {
        if (rule) {
          this.getOrders();
        }
      },
    );
  }

  @action
  setRouteParams(params: TokenParams) {
    this._tokenParams = params;
  }

  @action
  setTokenDetails(token: ResearchPairResponse) {
    this._tokenDetails = token;
  }

  @action
  async errorCatcher(response: AxiosError | TokenError) {
    console.log('errorCatcher', response);
    if (response instanceof AxiosError && response.response?.status === 401) {
      this._accountStore.logout();
    }
    if (response instanceof AxiosError && response.response?.data) {
      console.error(response.response.data);
    }
    if (response instanceof TokenError) {
      this._error = response;
    } else {
      console.error(response);
      return response.response;
    }
  }

  @action
  async getBlockchainGas() {
    if (this._accountStore.currentWallet?.type === WalletType.EVM)
      if (
        this.chain &&
        ![ChainId.TRON, ChainId.TON, ChainId.SOLANA].includes(
          this.chain as ChainId,
        )
      ) {
        this._accountStore.getBlockchainGas(this.chain).then((gas) => {
          this._gas = gas;
        });
      }
  }

  @action
  async getOrders(
    filter: string = 'ACTIVE',
    type: OrderType[] = Object.values(OrderType),
  ) {
    const types = type ? type.map((v) => `type=${v}`).join('&') : undefined;

    return api<Paged<OrderResponse[]>>({
      method: 'get',
      path: `/order?${types ? '&' + types : ''}`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
        pairId: this.pairId,
        filter,
      },
    })
      .then((response) => {
        this._orders = response?.content ?? [];
      })
      .catch((response) =>
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        ),
      );
  }

  @action
  async getTokenBalance() {
    return api<TokenBalanceResponse>({
      method: 'get',
      path: `/balance/token`,
      data: {
        walletId: this._accountStore.currentWallet?.id,
        tokenAddress: this.tokenDetails?.quoteToken.address,
        blockchain: this.chain,
      },
    })
      .then((response) => {
        this._tokenBalance = response;

        return response;
      })
      .catch((response) => {
        this.errorCatcher(
          new TokenError({
            ...(response.response?.data && { message: 'errors.network-error' }),
          }),
        );
      });
  }

  @action
  async getPairDetails() {
    return api<ResearchPairResponse[]>({
      method: 'get',
      path: `/pair/search`,
      data: {
        q: this.address,
      },
    })
      .then((response) => {
        if (response && !response.length)
          throw new TokenError({
            message: 'Get pair details error',
            navigate: PageRoutes.LIMIT_ORDERS,
          });
        this._tokenDetails = response[0];
        this.getTokenBalance();
      })
      .catch((response) => this.errorCatcher(response));
  }

  @action
  async reset() {
    this._error = null;
    this._orders = null;
    this._tokenParams = null;
    this._gas = null;
    this._tokenDetails = null;
    this._tokenBalance = null;
  }
}
