import React from 'react';
import checkmark from '@assets/icons/checkmark.svg';
import Badge from '@components/common/Badge';
import cn from 'classnames';

export interface SideNavItemProps {
  icon: string;
  title: string;
  onClick?: () => void;
  label?: string | number;
  badgedLabel?: boolean;
  isLast?: boolean;
  noIconBg?: boolean;
  checked?: boolean;
  variant?: 'default' | 'danger' | 'transparentDanger';
}

export default function SideNavItem({
  icon,
  title,
  onClick,
  label,
  isLast,
  noIconBg = false,
  checked,
  variant = 'default',
  badgedLabel = true,
}: SideNavItemProps) {
  const variants = {
    card: {
      default: '',
      danger: 'bg-semi-danger-10 tx-danger',
      transparentDanger: 'bg-transparent tx-danger',
    },
    icon: {
      default: '',
      danger: 'bg-semi-danger-10 tx-danger',
      transparentDanger: 'bg-transparent tx-danger',
    },
  };

  return (
    <div
      className={cn([
        'sidenav-item',
        'd-flex',
        'justify-content-start',
        'align-items-center',
        'wd-100p',
        onClick ? 'cur-pointer' : '',
        variants.card[variant],
      ])}
      onClick={onClick}
    >
      <div
        className={cn([
          noIconBg ? 'sidenav-no-bg-icon' : 'sidenav-icon',
          'mx-3',
          variants.icon[variant],
        ])}
      >
        <img src={icon} alt={title} width={noIconBg ? 24 : 20} />
      </div>
      <div
        className={cn([
          'd-flex',
          'justify-content-between',
          'align-items-center',
          'wd-100p',
          'py-3',
          !isLast ? 'border-bottom border-gray' : '',
          'pe-3',
        ])}
      >
        <div>{title}</div>
        {!!label && !badgedLabel && <span className="tx-muted">{label}</span>}
        {!!label && badgedLabel && <Badge label={label} />}
        {checked && (
          <div className="sidenav-checked">
            <img src={checkmark} alt={title} width={30} />
          </div>
        )}
      </div>
    </div>
  );
}
