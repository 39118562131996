import {ZerionAPIResponseDataType} from "./zerion.types";
import {ChainId} from "../constants";

export interface ApiWalletTransactions {
  cursor: string | string[] | undefined;
  transactions: ApiWalletTransactionsData[];
}

export interface ApiWalletTransactionsData {
  chain: ChainId;
  date: string;
  fee: string;
  feePrice: number;
  feeToken: string;
  isTaxPayout: boolean;
  operation: OperationType;
  sentFrom: string;
  sentTo: string;
  status: Status;
  transactionHash: string;
  transferred: ApiWalletTransactionTransfer[];
}

export interface ApiWalletTransactionTransfer {
  direction: Direction;
  sentFrom: string;
  sentTo: string;
  quantity: number;
  token: string;
  price: number;
  value: number;
  imageUrl?: string;
}

export interface ZerionTransactionAPIResponse {
  links: ZerionTransactionLinks;
  data: ZerionTransactionData[];
}

export interface ZerionTransactionLinks {
  self: string;
  next: string;
}

export interface ZerionTransactionData {
  type: ZerionAPIResponseDataType;
  id: string;
  attributes: ZerionTransactionAttributes;
  relationships: ZerionTransactionRelationships;
}

interface ZerionTransactionAttributes {
  operation_type: OperationType;
  hash: string;
  mined_at_block: number;
  mined_at: string;
  sent_from: string;
  sent_to: string;
  status: Status;
  nonce: number;
  fee: Fee;
  transfers: Transfer[];
  approvals: any[];
}

export enum OperationType {
  Approve = 'approve',
  Borrow = 'borrow',
  Burn = 'burn',
  Cancel = 'cancel',
  Claim = 'claim',
  Deploy = 'deploy',
  Deposit = 'deposit',
  Execute = 'execute',
  Mint = 'mint',
  Receive = 'receive',
  Repay = 'repay',
  Send = 'send',
  Stake = 'stake',
  Trade = 'trade',
  Unstake = 'unstake',
  Withdraw = 'withdraw'
}

interface Fee {
  fungible_info: FungibleInfo;
  quantity: Quantity;
  price: number | null;
  value: number | null;
}

interface FungibleInfo {
  name: string;
  symbol: string;
  icon: Icon | null;
  flags: Flags;
  implementations: Implementation[];
}

interface Flags {
  verified: boolean;
}

interface Icon {
  url: string;
}

interface Implementation {
  chain_id: string;
  address: string;
  decimals: number;
}

interface Quantity {
  int: string;
  decimals: number;
  float: number;
  numeric: string;
}

export enum Status {
  Confirmed = 'confirmed',
  Failed = 'failed',
  Pending = 'pending'
}

interface Transfer {
  nft_info?: NftInfo;
  direction: Direction;
  quantity: Quantity;
  value: number | null;
  price: number | null;
  sender: string;
  recipient: string;
  fungible_info?: FungibleInfo;
}

export enum Direction {
  In = 'in',
  Out = 'out',
  Self = 'self'
}

interface NftInfo {
  contract_address: string;
  token_id: string;
  name: string;
  interface: string;
  content?: Content;
}

interface Content {
  preview: Icon;
  detail: Icon;
}

interface ZerionTransactionRelationships {
  chain: Chain;
}

interface Chain {
  links: Links;
  data: Data;
}

interface Data {
  type: DataType;
  id: ChainId;
}

// export enum ID {
//   Arbitrum = 'arbitrum',
//   Aurora = 'aurora',
//   Ethereum = 'ethereum',
//   Optimism = 'optimism',
//   Polygon = 'polygon',
//   Xdai = 'xdai'
// }

enum DataType {
  Chains = 'chains'
}

interface Links {
  related: string;
}

enum ZerionTransactionDataType {
  Transactions = 'transactions'
}
