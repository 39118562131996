import React from "react";
import gasIcon from "@assets/icons/gas-white.svg";
import FormattedNumber from "@components/common/FormattedNumber";
import {GasResponse} from "../../types/common.types";

interface GasPriceProps {
  gas?: GasResponse | null;
  className?: string;
}

const GasPrice = ({gas, className = 'ms-auto me-0'}: GasPriceProps) => {
  return (
    <div
      className={`justify-content-end align-items-center ${className}`}>
      <img src={gasIcon} alt="gas" width={16} height={16} className="me-1"/>
      <FormattedNumber
        value={gas?.gwei}
        postfix={('gwei').toUpperCase()}
      />
    </div>
  );
}

export default GasPrice;
