import Preloader from '@components/common/Preloader';
import TabsCard from '@components/common/TabsCard';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import OrdersFilterModal from './OrdersFilterModal';
import { useTranslation } from 'react-i18next';
import LimitOrderHistoryItem from './LimitOrderHistoryItem';
import { OrderType } from 'types';
import useDebounce from '@hooks/useDebounce';
import useResponseHandler from '@hooks/useResponseHandler';
import { useStores } from '@hooks/useStores';
import { OrderResponse } from 'types/order/order.response';
import { OrderStatus } from 'types/order/order.enum';
import useInterval from '@hooks/useInterval';
import LimitOrderDetails from './LimitOrderDetails';
import { observer } from 'mobx-react-lite';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

export const allOrderFilters = Object.values(OrderType);

const LimitOrdersHistory = () => {
  const { t } = useTranslation();
  const handleResponse = useResponseHandler();
  const [w] = useAmplitude();

  const { ordersStore, tokenStore, accountStore } = useStores();
  const {
    pairId,
    tokenDetails,
    isExecuting,
    orders,
    nativeTokenPriceUsd,
    limitData,
  } = tokenStore;
  const { isTron } = ordersStore;

  const [activeTab, setActiveTab] = useState(t('common.active'));
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  const [activeFilters, setActiveFilters] =
    useState<OrderType[]>(allOrderFilters);
  const [executedFilters, setExecutedFilters] =
    useState<OrderType[]>(allOrderFilters);

  const historyTabs: {
    [key: string]: {
      label: string;
      state: [OrderType[], React.Dispatch<React.SetStateAction<OrderType[]>>];
    };
  } = {
    active: {
      label: t('common.active'),
      state: [activeFilters, setActiveFilters],
    },
    executed: {
      label: t('trading.executed'),
      state: [executedFilters, setExecutedFilters],
    },
  };

  const [isOrdersLoaded, setIsOrdersLoaded] = useState<boolean>(false);

  const [orderDetails, setOrderDetails] = useState<OrderResponse | null>(null);

  const isPendingMarket = useMemo(() => {
    return activeTab === t('common.active') && orders && isExecuting;
  }, [activeTab, orders]);

  const hadMarketPending = useRef(false);

  const updateThreshold = isTron ? triggerUpdate * 500 : 5000;

  useInterval(
    () => {
      setTriggerUpdate((v) => ++v);
    },
    isPendingMarket ? updateThreshold : null,
  );

  const currentFilters = useMemo(() => {
    const filters =
      activeTab === t('common.active') ? activeFilters : executedFilters;
    if (filters.length === 0 || filters.length === allOrderFilters.length) {
      return allOrderFilters;
    }
    return filters;
  }, [activeTab, activeFilters, executedFilters]);

  const debounceFilters = useDebounce(currentFilters, 1000);

  const getTokenBalance = () => {
    if (!tokenDetails) return;

    tokenStore.getTokenBalance();
    accountStore.loadBalance();
  };

  useEffect(() => {
    if (activeTab === t('trading.executed') && hadMarketPending.current) {
      hadMarketPending.current = false;
      return;
    }
    if (isPendingMarket && !hadMarketPending.current) {
      hadMarketPending.current = true;
      return;
    }
    if (!isPendingMarket && hadMarketPending.current) {
      hadMarketPending.current = false;
      handleResponse(t('trading.order-executed'));
      if (
        orders?.length ||
        orders?.every(
          (order) =>
            order.status === OrderStatus.EXECUTING &&
            [OrderType.MARKET_BUY, OrderType.MARKET_SELL].includes(order.type),
        )
      ) {
        setTimeout(() => {
          getTokenBalance();
          ordersStore.loadBalance();
          setActiveTab(t('trading.executed'));
        }, 200);
      }
    }
  }, [isPendingMarket, activeTab]);

  useEffect(() => {
    if (!pairId || !ordersStore.isInit) return;
    setIsOrdersLoaded(false);

    tokenStore
      .getOrders(
        activeTab === t('common.active') ? 'ACTIVE' : 'EXECUTED',
        debounceFilters,
      )
      .then(() => {
        if (Array.isArray(orders)) {
          if (
            !isOrdersLoaded &&
            activeTab === t('common.active') &&
            orders.length === 0
          ) {
            setActiveTab(t('trading.executed'));
            setIsOrdersLoaded(false);
            return;
          }
          setIsOrdersLoaded(true);
        }
      });
  }, [pairId, activeTab, debounceFilters, triggerUpdate, ordersStore.isInit]);

  const isLoaded = useMemo(() => {
    return isPendingMarket ? true : isOrdersLoaded;
  }, [isPendingMarket, isOrdersLoaded]);

  const handleCloseDetails = () => {
    setOrderDetails(null);
  };

  return (
    <>
      <div className="row flex-1 mt-4">
        <div
          className="card d-flex flex-column pb-0 mn-ht-300"
          id="assets-card"
        >
          <TabsCard
            list={[
              { value: t('common.active') },
              { value: t('trading.executed') },
            ]}
            active={activeTab}
            onClick={(tab) =>
              w(
                setActiveTab,
                tab === t('common.active')
                  ? AmplitudeEvent.TOKEN_PAGE_ACTIVE_TAB_CLICKED
                  : AmplitudeEvent.TOKEN_PAGE_EXECUTED_TAB_CLICKED,
                { token: tokenDetails },
              )(tab)
            }
            noAnimation
          >
            <div>
              {orders &&
                Object.values(historyTabs).map(
                  ({ label, state: [filters, setFilters] }) => {
                    return (
                      <div
                        key={label}
                        className={`tab-element tab-${t(label)}`}
                      >
                        {!isLoaded && (
                          <Preloader
                            className="d-flex flex-column"
                            iconSize={65}
                            textClass="mt-3"
                            inline
                          />
                        )}
                        {isLoaded && (
                          <OrdersFilterModal
                            filters={filters}
                            updateFilters={setFilters}
                          />
                        )}
                        {isLoaded && !orders.length && (
                          <div className="tx-center">
                            {t('order.no-active-orders')}
                          </div>
                        )}
                        {isLoaded &&
                          orders.map((order, index) => (
                            <LimitOrderHistoryItem
                              item={order}
                              isLast={index === orders.length - 1}
                              key={`limit-order-active-${tokenDetails?.pairAddress}-${order.id}`}
                              onClick={() => setOrderDetails(order)}
                              limitData={limitData}
                              nativePrice={nativeTokenPriceUsd}
                            />
                          ))}
                      </div>
                    );
                  },
                )}
            </div>
          </TabsCard>
          <div className="mn-ht-50">&nbsp;</div>
        </div>
      </div>

      <LimitOrderDetails
        item={orderDetails}
        limitData={limitData}
        nativePrice={nativeTokenPriceUsd}
        onClose={handleCloseDetails}
        onCancel={() => setTriggerUpdate((v) => ++v)}
      />
    </>
  );
};

export default observer(LimitOrdersHistory);
