import React from "react";
import {formatNumber, numberWithCommas} from "@helpers/numbers";
import Placeholder from "@components/common/Placeholder";
import BigNumber from "bignumber.js";
import IconWithLoading from "@components/common/IconWithLoading";

export interface FormattedNumberProps {
  value?: string | number | null;
  decimals?: number;
  floor?: boolean;
  className?: string;
  withSign?: boolean;
  postfix?: string;
  postfixClass?: string;
  suffix?: string;
  suffixClass?: string;
  subZeros?: boolean;
  subZerosThreshold?: number;
  useSpinner?: boolean;
  useFormat?: boolean;
  noSpace?: boolean;
  shorten?: number;
  hideNull?: boolean;
  onClick?: () => void;
}

const FormattedNumber = (props: FormattedNumberProps) => {
  const {
    value: originalValue,
    decimals: _decimals,
    floor,
    className,
    withSign,
    postfix,
    postfixClass,
    suffix,
    suffixClass,
    subZeros = false,
    subZerosThreshold = 0.001,
    useSpinner = false,
    useFormat = false,
    noSpace = false,
    hideNull = false,
    shorten,
    onClick,
  } = props;

  if (hideNull && originalValue === null) {
    return null;
  }

  if (originalValue === undefined || originalValue === null) {
    return (<span className={className}>{useSpinner ? <IconWithLoading className="" isLoading={true} /> : <Placeholder/>}</span>);
  }

  const bn = new BigNumber(originalValue);
  const value = bn.absoluteValue().toNumber();
  const needsZeroes = subZeros && bn.abs().isLessThan(subZerosThreshold);
  let decimalsCount = bn.abs().isGreaterThan(10) ? 2 : bn.abs().isLessThan(0.01) ? 3 : 2;
  if (bn.abs().isGreaterThan(100)) {
    decimalsCount = 0;
  }
  const decimals = _decimals === undefined ? decimalsCount : _decimals;

  return (
    <span className={className} onClick={onClick}>
      {suffix === '$' && withSign && (
        <>
          {new BigNumber(originalValue).toNumber() >= 0 ? '+' : '-'}
        </>
      )}
      {!!suffix && (
        <span className={suffixClass}>
          {suffix}
        </span>
      )}
      {suffix !== '$' && withSign && (
        <>
          {new BigNumber(originalValue).toNumber() >= 0 ? '+' : '-'}
        </>
      )}
      {useFormat ? formatNumber(value, '', decimals) : numberWithCommas(value, floor, decimals, needsZeroes, shorten)}
      {!!postfix && (
        <span className={postfixClass}>
          {noSpace || ['$', '%', '(', ')'].some(v => postfix.includes(v)) ? '' : ' '}
          {postfix}
        </span>
      )}
    </span>
  );
};

export default FormattedNumber;
