import { action, makeAutoObservable, observable } from 'mobx';
import { TokenError, TokenStore } from '@stores/token-store';
import {
  OrderTransactionType,
  OrderTrigger,
  OrderType,
  OrderVariant,
} from 'types/order/order.enum';
import { OrderResponse } from 'types/order/order.response';
import BigNumber from 'bignumber.js';
import { AxiosError } from 'axios';
import api from '@helpers/api';
import { PageRoutes } from '../constants';
import { BaseChainToken } from 'types/enums';
import { defaultOrderSettings, SettingsStore } from './settings-store';
import { hasOwnProperty } from '@helpers/object';

export type TradeParams = Record<'chain' | 'address' | 'id', string>;

export type TpSlValue = {
  tokenPercents: number | null;
  profitPercents?: number | null;
  lossPercents?: number | null;
};

export type TpSlOrders = {
  takeProfits: TpSlValue[] | null;
  stopLosses: TpSlValue[] | null;
};

export class TokenTrade {
  private _tokenStore: TokenStore;
  private _settingStore: SettingsStore;

  @observable
  private _init: boolean | null;

  @observable
  private _error: TokenError | null;

  @observable
  private _id: string | null;

  @observable
  private _chain: string | null;

  @observable
  private _currency: number | null;

  @observable
  private _address: string | null;

  @observable
  private _order: OrderResponse | null;

  @observable
  private _buySell: OrderTransactionType | null = OrderTransactionType.BUY;

  @observable
  private _buyOrderType: OrderVariant | null = OrderVariant.MARKET;

  @observable
  private _sellOrderType: OrderVariant | null = OrderVariant.MARKET;

  @observable
  private _buyAmount: string | number | null = null;

  @observable
  private _sellAmount: string | number | null = null;

  @observable
  private _buyAmountUsd: string | number | null = null;

  @observable
  private _sellAmountUsd: string | number | null = null;

  @observable
  private _triggerAmount: string | number | null = null;

  @observable
  private _triggerPercent: string | number | null = null;

  @observable
  private _triggerType: OrderTrigger | null = OrderTrigger.PRICE_IN_USD;

  @observable
  private _emptyInputsLightOn: boolean = false;

  @observable
  private _tpSlOrders: TpSlOrders = { takeProfits: null, stopLosses: null };

  constructor(tokenStore: TokenStore, settingStore: SettingsStore) {
    this._tokenStore = tokenStore;
    this._settingStore = settingStore;
    makeAutoObservable(this);
  }

  // Геттеры свойств as is
  get orderInit() {
    return this._init;
  }

  get error() {
    return this._error;
  }

  get id() {
    return this._id;
  }

  get chain() {
    return this._chain;
  }

  get currency() {
    return this._currency;
  }

  get address() {
    return this._address;
  }

  get order() {
    return this._order;
  }

  get buySell() {
    return this._buySell;
  }

  get buyOrderType() {
    return this._buyOrderType;
  }

  get sellOrderType() {
    return this._sellOrderType;
  }

  get buyAmount() {
    return isNaN(Number(this._buyAmount)) ? 0 : this._buyAmount;
  }

  get sellAmount() {
    return isNaN(Number(this._sellAmount)) ? 0 : this._sellAmount;
  }

  get buyAmountUsd() {
    return isNaN(Number(this._buyAmountUsd)) ? 0 : this._buyAmountUsd;
  }

  get sellAmountUsd() {
    return isNaN(Number(this._sellAmountUsd)) ? 0 : this._sellAmountUsd;
  }

  get triggerAmount() {
    return this._triggerAmount;
  }

  get triggerPercent() {
    return this._triggerPercent;
  }

  get triggerType() {
    return this._triggerType;
  }

  // Вычисляемые свойства
  get isBuy() {
    return this.buySell === OrderTransactionType.BUY;
  }

  get isMarket() {
    return this.isBuy
      ? this.buyOrderType === OrderVariant.MARKET
      : this.sellOrderType === OrderVariant.MARKET;
  }

  get isLimit() {
    return this.isBuy
      ? this.buyOrderType === OrderVariant.LIMIT
      : this.sellOrderType === OrderVariant.LIMIT;
  }

  get isStopLoss() {
    return this.isBuy ? false : this.sellOrderType === OrderVariant.STOP_LOSS;
  }

  get isStopLossesEnabled() {
    return Array.isArray(this._tpSlOrders.stopLosses);
  }

  get isTakeProfitsEnabled() {
    return Array.isArray(this._tpSlOrders.takeProfits);
  }

  get tpSlOrders(): TpSlOrders | undefined {
    if (!(this.isStopLossesEnabled || this.isTakeProfitsEnabled)) return;
    const { takeProfits, stopLosses } = this._tpSlOrders;
    const { takeProfitSettings, stopLossSettings } = this._settingStore;

    return {
      takeProfits:
        takeProfits?.map((o) => ({
          ...o,
          settings: takeProfitSettings
            ? takeProfitSettings[this.chain!]
            : defaultOrderSettings,
        })) ?? null,
      stopLosses:
        stopLosses?.map((o) => ({
          ...o,
          settings: stopLossSettings
            ? stopLossSettings[this.chain!]
            : defaultOrderSettings,
        })) ?? null,
    };
  }

  get stopLosses() {
    return this._tpSlOrders.stopLosses;
  }

  get takeProfits() {
    return this._tpSlOrders.takeProfits;
  }

  get emptyInputsLightOn() {
    return this._emptyInputsLightOn;
  }

  get stopLossesError() {
    const hasEmpty =
      this.emptyInputsLightOn &&
      this._tpSlOrders.stopLosses
        ?.flatMap((tp) => tp && Object.values(tp))
        .some((tp) => !tp);

    const hasExceededSum =
      Number(
        this._tpSlOrders.stopLosses?.reduce(
          (accumulator, currentValue) =>
            accumulator + (Number(currentValue.tokenPercents) || 0),
          0,
        ),
      ) > 100;

    return [
      hasExceededSum ? 'order.value-amount-should-not-exceed-100' : null,
      hasEmpty ? 'order.all-fields-required-error' : null,
    ];
  }

  get takeProfitsError() {
    const hasEmpty =
      this.emptyInputsLightOn &&
      this._tpSlOrders.takeProfits
        ?.flatMap((tp) => tp && Object.values(tp))
        .some((tp) => !tp);

    const hasExceededSum =
      Number(
        this._tpSlOrders.takeProfits?.reduce(
          (accumulator, currentValue) =>
            accumulator + (Number(currentValue.tokenPercents) || 0),
          0,
        ),
      ) > 100;

    return [
      hasExceededSum ? 'order.value-amount-should-not-exceed-100' : null,
      hasEmpty ? 'order.all-fields-required-error' : null,
    ];
  }

  get orderType() {
    if (this.isBuy) {
      if (this.isMarket) return OrderType.MARKET_BUY;
      if (this.isLimit) return OrderType.LIMIT_BUY;
    } else {
      if (this.isMarket) return OrderType.MARKET_SELL;
      if (this.isLimit) return OrderType.LIMIT_SELL;
    }
    return OrderType.STOP_LOSS;
  }

  @action
  initialize() {
    if (!this.id) this.setOrderInit(true);

    if (
      this.id &&
      this.currency &&
      this._tokenStore &&
      this._tokenStore.tokenBalance &&
      this._tokenStore.balance &&
      !this.orderInit
    ) {
      if (!this.order) {
        this.getOrder(this.id);
      } else {
        const isBuy = this.order.type.toLowerCase().includes('buy');
        this.setBuySell(
          isBuy ? OrderTransactionType.BUY : OrderTransactionType.SELL,
        );
        if (isBuy) {
          this.setBuyOrderType(
            this.order.type === OrderType.MARKET_BUY
              ? OrderVariant.MARKET
              : OrderVariant.LIMIT,
          );
        } else {
          this.setSellOrderType(
            this.order.type === OrderType.MARKET_SELL
              ? OrderVariant.MARKET
              : this.order.type === OrderType.LIMIT_SELL
                ? OrderVariant.LIMIT
                : OrderVariant.STOP_LOSS,
          );
        }
        if (this.order.trigger) {
          this.setTriggerType(this.order.trigger);
          this.setTriggerType(this.order.trigger);
          this.setTriggerAmount(this.order.targetTriggerValue);
        }
        const percent = new BigNumber(100)
          .dividedBy(
            isBuy
              ? this._tokenStore.balance!.balance
              : this._tokenStore.tokenBalance!.tokenBalance,
          )
          .multipliedBy(this.order.valueIn);
        if (isBuy) {
          this.setBuyAmount(this.order.valueIn);
          this.setBuyAmountUsd(
            new BigNumber(this.order.valueIn)
              .multipliedBy(this.currency!)
              .toString(),
          );
        } else {
          this.setSellAmount(this.order.valueIn);
          this.setSellAmountUsd(
            new BigNumber(this._tokenStore.tokenBalance!.tokenBalanceInUsd || 0)
              .multipliedBy(1 / percent.toNumber())
              .toString(),
          );
        }

        if (this._order!.metadata.tpSlOrders)
          this._tpSlOrders = this._order!.metadata.tpSlOrders;

        this.setOrderInit(true);
      }
    }
  }

  @action
  async errorCatcher(response: AxiosError | TokenError) {
    if (response instanceof AxiosError && response.response?.data) {
      console.error(response.response.data);
    }
    if (response instanceof TokenError) {
      this._error = response;
    } else {
      console.error(response);
      return response.response;
    }
  }

  @action
  setBuySell(v: typeof this.buySell) {
    this.setBuyOrderType(OrderVariant.MARKET);
    this.setSellOrderType(OrderVariant.MARKET);
    this.resetAmounts();

    this._buySell = v;
  }

  @action
  setOrderInit(v: boolean) {
    this._init = v;
  }

  @action
  setRouteParams(params: TradeParams) {
    this._chain = params.chain;
    this.getCurrency();
    this._address = params.address;
    this._id = params.id;
  }

  @action
  setBuyOrderType(v: typeof this.buyOrderType) {
    this._buyOrderType = v;
  }

  @action
  setSellOrderType(v: typeof this.sellOrderType) {
    this.resetAmounts();
    this._sellOrderType = v;
  }

  @action
  setBuyAmount(v: typeof this.buyAmount) {
    this._buyAmount = v;
  }

  @action
  setBuyAmountUsd(v: typeof this.buyAmountUsd) {
    this._buyAmountUsd = v;
  }

  @action
  setSellAmount(v: typeof this.sellAmount) {
    this._sellAmount = v;
  }

  @action
  setSellAmountUsd(v: typeof this.sellAmountUsd) {
    this._sellAmountUsd = v;
  }

  @action
  setTriggerAmount(v: typeof this.triggerAmount) {
    this._triggerAmount = v;
  }

  @action
  setTriggerPercent(v: typeof this.triggerPercent) {
    this._triggerPercent = v;
  }

  @action
  setTriggerType(v: typeof this.triggerType) {
    this.resetAmounts();
    this._triggerType = v;
  }

  @action
  switchTakeProfits(v: boolean) {
    this._tpSlOrders.takeProfits = v
      ? [{ profitPercents: null, tokenPercents: null }]
      : null;
  }

  @action
  switchStopLosses(v: boolean) {
    this._tpSlOrders.stopLosses = v
      ? [{ lossPercents: null, tokenPercents: null }]
      : null;
  }

  @action
  addTakeProfitValue() {
    this._tpSlOrders.takeProfits!.push({
      profitPercents: null,
      tokenPercents: null,
    });
  }

  @action
  setTakeProfits(values: TpSlValue[]) {
    this._tpSlOrders.takeProfits = values;
  }

  @action
  addStopLossValue() {
    this._tpSlOrders.stopLosses!.push({
      lossPercents: null,
      tokenPercents: null,
    });
  }

  @action
  setStopLosses(values: TpSlValue[]) {
    this._tpSlOrders.stopLosses = values;
  }

  @action
  removeTakeProfitValue(idx: number) {
    this._tpSlOrders.takeProfits!.splice(idx, 1);
  }

  @action
  removeStopLossValue(idx: number) {
    this._tpSlOrders.stopLosses!.splice(idx, 1);
  }

  @action
  setTakeProfitValue(idx: number) {
    return (input: 'percents' | 'value') => (value: string | number | null) => {
      if (
        this._tpSlOrders.takeProfits &&
        Array.isArray(this._tpSlOrders.takeProfits)
      ) {
        if (input === 'percents') {
          this._tpSlOrders.takeProfits[idx]!.profitPercents = value as number;
        } else if (input === 'value') {
          this._tpSlOrders.takeProfits[idx]!.tokenPercents = value as number;
        }
      }
    };
  }

  @action
  setStopLossValue(idx: number) {
    return (input: 'percents' | 'value') => (value: string | number | null) => {
      if (
        this._tpSlOrders.stopLosses &&
        Array.isArray(this._tpSlOrders.takeProfits)
      ) {
        if (input === 'percents') {
          this._tpSlOrders.stopLosses[idx]!.lossPercents = value as number;
        } else if (input === 'value') {
          this._tpSlOrders.stopLosses[idx]!.tokenPercents = value as number;
        }
      }
    };
  }

  @action
  setEmptyInputsLightOn() {
    this._emptyInputsLightOn = true;
  }

  @action
  async getOrder(id: string) {
    return api<OrderResponse>({
      method: 'get',
      path: `/order/${id}`,
    })
      .then((response) => {
        this._order = response;
      })
      .catch((response) =>
        this.errorCatcher(
          new TokenError({
            ...response.response.data,
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        ),
      );
  }

  @action
  async getCurrency() {
    return api<any>({
      method: 'get',
      path: `/token/price`,
    })
      .then((response) => {
        //@ts-ignore
        const baseToken = BaseChainToken[this.chain!];
        this._currency = response[baseToken];
        return response;
      })
      .catch((response) =>
        this.errorCatcher(
          new TokenError({
            ...response.response.data,
            navigate: PageRoutes.LIMIT_ORDERS,
          }),
        ),
      );
  }

  @action
  async resetAmounts() {
    if (!this.order) {
      this.setTriggerAmount(null);
      this.setTriggerPercent(null);
    }
  }

  @action
  async reset() {
    this._init = null;
    this._error = null;
    this._id = null;
    this._chain = null;
    this._currency = null;
    this._address = null;
    this._order = null;
    this._buySell = OrderTransactionType.BUY;
    this._buyOrderType = OrderVariant.MARKET;
    this._sellOrderType = OrderVariant.MARKET;
    this._buyAmount = null;
    this._sellAmount = null;
    this._buyAmountUsd = null;
    this._sellAmountUsd = null;
    this._triggerAmount = null;
    this._triggerPercent = null;
    this._triggerType = OrderTrigger.PRICE_IN_USD;
    this._tpSlOrders = { takeProfits: null, stopLosses: null };
    this._emptyInputsLightOn = false;
  }
}
