import React, { useEffect, useRef, useState } from 'react';
// import PinInput from "react-pin-input";
import Preloader from '@components/common/Preloader';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import useResponseHandler from '@hooks/useResponseHandler';
import lock from '@assets/images/lock.svg';
import PinInput from '@components/common/PinInput';
import { useTranslation } from 'react-i18next';

const Login = observer(() => {
  const { t } = useTranslation();
  const { authStore, accountStore } = useStores();
  const { isLogged } = authStore;
  const { wallets, redirectTo } = accountStore;
  const navigate = useNavigate();
  const handleResponse = useResponseHandler();
  const inputRef = useRef(null);
  const [pin, setPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const { state } = useLocation();

  const handleSubmit = (value: string) => {
    authStore
      .login(pin || value)
      .then((response) => {
        if (!response || !response.accessToken) {
          handleResponse(response.response, true);
          setPin('');
          setIsInvalid(true);
        }
      })
      .catch((e) => {
        setPin('');
        setIsInvalid(true);
        console.error(e);
        handleResponse(e.response);
      });
  };

  const handleComplete = (value: string) => {
    if (!pin) {
      setPin(value);
      handleSubmit(value);
    }
  };

  useEffect(() => {
    if (!isInit) {
      return;
    }
    if (isLogged) {
      navigate(redirectTo ?? PageRoutes.WALLET, {
        replace: true,
      });
    }
  }, [isLogged, isInit]);

  useEffect(() => {
    if (!isInit) {
      return;
    }

    if (inputRef.current) {
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [inputRef, isInit]);

  useEffect(() => {
    if (!isInit && state?.pin) {
      handleComplete(state.pin);
    }
    setIsInit(true);
  }, [isInit, state]);

  if (pin) {
    return (
      <div className="tx-center pt-4">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
          text=""
        />
        <div className="tx-muted mt-3">
          {t('login.loading-wallet')}
          <br />
          «Alpha Wallet»
        </div>
      </div>
    );
  }

  return (
    <div className="tx-center full-page d-flex flex-column">
      <img src={lock} alt="lock" className="wd-45 ht-45 mx-auto mt-5 mb-2" />

      <div className={`tx-17 tx-muted mb-3 ${isInvalid ? 'tx-danger' : "'"}`}>
        {t('login.enter-pin')}
      </div>

      <div className={`flex-1 ${isInvalid ? 'invalid-pin' : ''}`}>
        <PinInput
          length={4}
          onChange={() => setIsInvalid(false)}
          onComplete={handleComplete}
          isInvalid={isInvalid}
        />
        {/*<PinInput*/}
        {/*  length={4}*/}
        {/*  initialValue=""*/}
        {/*  onChange={() => setIsInvalid(false)}*/}
        {/*  secret*/}
        {/*  secretDelay={100}*/}
        {/*  type="numeric"*/}
        {/*  inputMode="number"*/}
        {/*  onComplete={handleComplete}*/}
        {/*  autoSelect={true}*/}
        {/*  regexCriteria={/^[0-9]*$/}*/}
        {/*  ref={inputRef}*/}
        {/*/>*/}
      </div>
    </div>
  );
});

export default Login;
