import React, { useCallback, useEffect, useState } from 'react';
import cross from '@assets/icons/cross.svg';
import InfoTooltip from '@components/common/InfoTooltip';
import BigNumber from 'bignumber.js';
import cn from 'classnames';

interface SettingsInputProps {
  data: any;
  setHandler: (_v: any) => void;
  dataKey: string;
  children: React.ReactNode;
  isInvalidHandler?: (_key: string, _req?: boolean) => boolean;
  placeholder?: string;
  tooltip?: string;
  unit?: string;
  isRequired?: boolean;
  isString?: boolean;
  disabled?: boolean;
  onChange?: (_k: string, _v: string | number | null) => void;
  inputClassName?: string;
  className?: string;
}

const SettingsInput = (props: SettingsInputProps) => {
  const {
    data,
    setHandler,
    dataKey,
    isInvalidHandler,
    placeholder,
    isRequired,
    isString,
    tooltip,
    unit,
    disabled,
    onChange,
    inputClassName = 'bg-semi-transparent-10',
    className = '',
    children,
  } = props;
  const [value, setValue] = useState<string | null>(data[dataKey]);

  const isInvalid = useCallback(
    (k: string, r?: boolean) => {
      if (isInvalidHandler) {
        return isInvalidHandler(k, r);
      }
      return false;
    },
    [isInvalidHandler],
  );

  const handleChange = useCallback(
    (v: string | number | null) => {
      let value = v;
      if (!isString && typeof v === 'string') {
        if (v.indexOf('.') === v.length - 1) {
          setHandler({ ...data, [dataKey]: v.split('.')[0] });
          return;
        }
        value = v !== '' ? new BigNumber(v.replace(',', '.')).toNumber() : null;
      }
      setHandler({ ...data, [dataKey]: value });
    },
    [setHandler, data, dataKey, isString],
  );

  useEffect(() => {
    handleChange(value);
  }, [value]);

  useEffect(() => {
    !data[dataKey] && setValue(data[dataKey]);
  }, [data]);

  return (
    <div className={cn(['mn-wd-45p', 'flex-1', className])}>
      <div className="tx-13 d-flex align-items-center mb-1">
        {children}
        {!!tooltip && <InfoTooltip text={tooltip} />}
      </div>
      <div className="input-group">
        <input
          onBlur={() => window.scrollTo(0, 0)}
          type="text"
          inputMode={isString ? 'text' : 'decimal'}
          className={`form-control appearance-none ${inputClassName} ${isInvalid(dataKey, isRequired) ? 'is-invalid' : ''}`}
          placeholder={placeholder}
          value={value === undefined || value === null ? '' : value}
          onChange={(e) => {
            const value = e.target.value !== '' ? e.target.value : null;
            setValue(value);
            if (onChange) {
              onChange(dataKey, value);
            }
          }}
          disabled={disabled}
        />
        {value !== undefined && value !== null && (
          <div className="input-group-text">
            {!!unit && <span className="me-2">{unit}</span>}
            <img src={cross} alt="Clear" onClick={() => setValue(null)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsInput;
