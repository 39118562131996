import React from 'react';
import { useNavigate } from 'react-router-dom';
import regi from '@assets/images/regi.png';
import { PageRoutes } from '../../../constants';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import TermsAndConditionsNote from './TermsAndConditionsNote';
import { isMobile } from '@helpers/device';
import cn from 'classnames';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const StoryIntro = () => {
  const { t } = useTranslation();
  const [w] = useAmplitude([
    AmplitudeEvent.ONBOARDING_SLIDE_VIEWED,
    { slide: 1 },
  ]);
  const navigate = useNavigate();
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-start ht-100p wd-100p`}
    >
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1, duration: 0.5 }}
        src={regi}
        alt="Pro DEX for everyone"
        className={cn([
          isMobile() ? 'wd-90p' : 'wd-70p',
          'px-0',
          'pos-absolute',
          'top-0',
          'start-5',
        ])}
      />
      <img
        src={regi}
        alt="Pro DEX for everyone"
        className={cn([isMobile() ? 'wd-85p' : 'wd-70p', 'mt-4', 'opacity-0'])}
      />

      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="tx-28 mb-0 mt-1"
      >
        {t('onboarding.pro-dex-for-everyone')}
      </motion.h2>

      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="tx-16 mt-2 mb-3 tx-muted"
      >
        {t('onboarding.are-you-ready-to-the-moon')}
      </motion.p>

      <motion.div
        className={cn([
          'd-flex',
          'flex-column',
          'position-fixed',
          'bottom-0',
          isMobile() ? 'pb-5' : 'pb-3',
        ])}
        style={{ width: '100vw', left: 0 }}
      >
        <TermsAndConditionsNote />

        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="btn btn-primary-10 mx-3 flex-grow-1 text-center"
          onClick={w(
            () => navigate(PageRoutes.CREATE_WALLET),
            AmplitudeEvent.CREATE_WALLET_CLICKED,
            { slide: 1 },
          )}
        >
          {t('onboarding.create-wallet')}
        </motion.button>

        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="btn btn-semi-10 mt-3 mx-3 flex-grow-1"
          onClick={w(
            () => navigate(PageRoutes.IMPORT_PIN),
            AmplitudeEvent.IMPORT_WALLET_CLICKED,
            { slide: 1 },
          )}
        >
          {t('onboarding.import-wallet')}
        </motion.button>
      </motion.div>
    </div>
  );
};

export default StoryIntro;
