import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import SideNavItem from "@pages/Wallet/SideNavItem";
import key from "@assets/icons/key.svg";
import info from "@assets/icons/info-yellow.svg";
import {PageRoutes} from "../../../constants";
import { useTranslation } from 'react-i18next';

const PrivateKeyModal = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNavigate = () => {
    setShowModal(true);
    navigate(PageRoutes.EXPORT_PRIVATE_KEY);
  };

  return (
    <>
      <div className="card p-0">
        <SideNavItem
          title={t('wallet.private-key')}
          icon={key}
          onClick={() => setShowModal(true)}
          isLast
        />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} onExited={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('wallet.export-private-key')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center">
          <img src={info} alt="info" className="mb-3 mt-1 wd-45" />

          <div className="tx-28 tx-semibold tx-center mb-2">
            {t('wallet.important-information')}
          </div>

          <div className="tx-muted tx-17">
            {t('wallet.do-not-share-key')}
          </div>

          <button
            className="btn btn-primary-10 wd-100p mt-3"
            onClick={handleNavigate}
          >
            {t('wallet.show-key')}
          </button>

          <button
            className="btn btn-transparent wd-100p mt-2 mb-3"
            onClick={handleClose}
          >
            {t('common.cancel')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PrivateKeyModal;
