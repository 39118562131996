import React, {useEffect, useState} from "react";
import {getEllipsisTxt} from "@helpers/formatters";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import confetti from "canvas-confetti";
import useResponseHandler from "@hooks/useResponseHandler";
import IconWithLoading from "@components/common/IconWithLoading";
import UserAvatar from "@components/common/UserAvatar";
import { useTranslation } from "react-i18next";

const WalletName = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountStore } = useStores();
  const { address } = accountStore;
  const [isInit, setIsInit] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const handleResponse = useResponseHandler();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!isInit) {
      confetti();
      setIsInit(true);
    }
  }, [isInit]);

  const handleContinue = () => {
    if (!name) {
      navigate(PageRoutes.WALLET);
      return;
    }

    setIsSaving(true);

    accountStore.updateWalletName(name).then((response) => {
      if (response && response.data && response.data.address) {
        accountStore.loadUser();
        navigate(PageRoutes.WALLET)
      } else {
        handleResponse(response.response, true);
      }
      setIsSaving(false);
    }).catch((e) => {
      handleResponse(e, true);
      setIsSaving(false);
    });
  };

  return (
    <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center pt-5">
      <div className="rounded-30 bg-gray-800 px-2 py-1 d-flex align-items-center mb-3">
        <div className="wd-35 ht-35 rounded-circle overflow-hidden">
          <UserAvatar string={address} size={35} />
        </div>
        {!name && <div className="tx-semibold ms-2">{getEllipsisTxt(address, 4)}</div>}
        {!!name && <div className="tx-semibold ms-2">{name} ({getEllipsisTxt(address, 4)})</div>}
      </div>

      <h1 className="tx-28 mb-3">{t('wallet.great-set-name')}</h1>
      <div className="tx-muted">
        {t('wallet.set-name-description')}
      </div>

      <input
        type="text"
        className="form-control mt-5"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => window.scrollTo(0, 0)}
        placeholder={t('wallet.wallet-name-optional')}
      />

      <button
        className="btn btn-primary-10 wd-100p mt-auto mb-0"
        onClick={handleContinue}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} className="me-2" />
        {t('common.continue')}
      </button>
    </div>
  )
});

export default WalletName;
