import React from 'react';
import TabsCard from '@components/common/TabsCard';
import { useTranslation } from 'react-i18next';
import { OrderVariant, OrderTransactionType } from 'types/order/order.enum';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const TradeOrderType = () => {
  const { tokenTrade } = useStores();
  const { t } = useTranslation();
  const [w] = useAmplitude();

  const { order, buySell, buyOrderType, sellOrderType } = tokenTrade;

  return (
    <>
      <div className="tx-17 tx-semibold mt-4 mb-2">
        {t('operation.order-type')}
      </div>

      <div className="card gap-2">
        <TabsCard
          list={[
            { value: OrderTransactionType.BUY, label: 'common.buy' },
            { value: OrderTransactionType.SELL, label: 'common.sell' },
          ]}
          active={buySell!}
          onClick={(val) =>
            w(
              (v) => tokenTrade.setBuySell(v),
              val === OrderTransactionType.BUY
                ? AmplitudeEvent.ORDER_BUY_BUTTON_CLICKED
                : AmplitudeEvent.ORDER_SELL_BUTTON_CLICKED,
            )(val)
          }
          className="limit-orders-tabs overflow-hidden rounded-2"
          buttonsClassName="d-flex flex-row p-1 justify-content-between bg-semi-transparent-10 pos-relative"
          disabled={!!order}
        />
        {buySell === OrderTransactionType.BUY && (
          <TabsCard
            list={[
              { value: OrderVariant.MARKET, label: 'common.market' },
              { value: OrderVariant.LIMIT, label: 'common.limit' },
            ]}
            active={buyOrderType!}
            onClick={(val) =>
              w(
                (v) => tokenTrade.setBuyOrderType(v),
                val === OrderVariant.MARKET
                  ? AmplitudeEvent.MARKET_BUTTON_CLICKED
                  : AmplitudeEvent.LIMIT_BUTTON_CLICKED,
              )(val)
            }
            className="overflow-hidden rounded-2"
            buttonsClassName="d-flex flex-row p-1 justify-content-between bg-semi-transparent-10 pos-relative"
            disabled={!!order}
          />
        )}
        {buySell === OrderTransactionType.SELL && (
          <TabsCard
            list={[
              { value: OrderVariant.MARKET, label: 'common.market' },
              { value: OrderVariant.LIMIT, label: 'common.limit' },
              { value: OrderVariant.STOP_LOSS, label: 'common.stop-loss' },
            ]}
            active={sellOrderType!}
            onClick={(val) =>
              w(
                (v) => tokenTrade.setSellOrderType(v),
                val === OrderVariant.MARKET
                  ? AmplitudeEvent.MARKET_BUTTON_CLICKED
                  : AmplitudeEvent.LIMIT_BUTTON_CLICKED,
              )(val)
            }
            className="overflow-hidden rounded-2"
            buttonsClassName="d-flex flex-row p-1 justify-content-between bg-semi-transparent-10 pos-relative"
            disabled={!!order}
          />
        )}
      </div>
    </>
  );
};

export default observer(TradeOrderType);
