import React, {useCallback, useEffect, useState} from "react";
import {AutoTradeSource, IAutoTradeSettings, StrategyModel} from "../../../types";
import {observer} from "mobx-react-lite";
import SnipeRow from "@pages/Profile/SnipeRow";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import edit from "@assets/icons/edit.svg";
import chevron from "@assets/icons/chevron.svg";
import ether from "@assets/images/chains/ether.svg";
import {numberRegex, PageRoutes} from "../../../constants";
import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
// import {useShowPopup} from "@vkruglikov/react-telegram-web-app";
import usePopup from "@hooks/usePopup";
import useNotification from "@hooks/useNotification";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import {useLocation, useNavigate} from "react-router-dom";
import SettingsInput from "@components/common/SettingsInput";
import whale from "@assets/images/auto-trade/whale.svg";
import Badge from "@components/common/Badge";
import telegram from "@assets/images/auto-trade/telegram.svg";
import { useTranslation } from "react-i18next";

interface StrategySettingsProps {
  data?: StrategyModel;
  onSave?: (position: StrategyModel) => void;
  onClose?: () => void;
  isSaving?: (value: boolean) => void;
}

const StrategySettings = observer((props: StrategySettingsProps) => {
  const { accountStore, settingsStore } = useStores();
  const {amounts} = settingsStore;
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  // const [position, setPosition] = useState<StrategyModel>();
  const [settings, setSettings] = useState<IAutoTradeSettings | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [source, setSource] = useState<AutoTradeSource | null>(null);
  // const params = useParams();
  const notify = useNotification();
  const showPopup = usePopup();
  const {state} = useLocation();
  const [name, setName] = useState<string>(state?.strategy?.name || 'Strategy 1');
  const { t } = useTranslation();

  // const mainData = useMemo(() => {
  //   if (!position) return null;
  //
  //   const {
  //     createdAt,
  //     updatedAt,
  //     id,
  //     userId,
  //     label,
  //     ...rest
  //   } = position.settings;
  //
  //   return rest;
  // }, [position]);

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!settings) {
      return false;
    }
    // @ts-ignore
    const value = settings[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    return isNaN(value) || !numberRegex.test(value.toString());
  }, [settings]);

  // useEffect(() => {
  //   if (!settings && mainData) {
  //     setSettings(JSON.parse(JSON.stringify(mainData)));
  //   }
  // }, [settings, mainData]);

  const handleClear = () => {
    setSettings(JSON.parse(JSON.stringify(state?.strategy?.positionSettings || accountStore.snipeSettings)));
    setAmount(state?.strategy?.positionSettings.ethAmount || '');
  };

  useEffect(() => {
    if (!settings && (state?.strategy || accountStore.snipeSettings)) {
      handleClear();
    }
  }, [state, settings, accountStore.snipeSettings]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({...settings, stopLossEnabled: true});
    }
  }, [settings]);

  useEffect(() => {
    if (props.isSaving) {
      props.isSaving(isSaving);
    }
  }, [props, isSaving]);

  const handleSubmit = () => {
    if (!settings) return;

    const checks = [
      { check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: t('common.stop-loss') },
      { check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: t('trading.take-profit') },
      { check: isInvalid('stopLossSellPercent', settings.stopLossEnabled), label: t('trading.stop-loss-amount') },
      { check: isInvalid('takeProfitSellPercent', settings.takeProfitEnabled), label: t('trading.take-profit-amount') },
      { check: isInvalid('sellSlippage', true), label: t('common.sell-slippage') },
      { check: isInvalid('buySlippage', true), label: t('trading.buy-slippage') },
      { check: isInvalid('sellGasDelta'), label: t('trading.sell-priority-fee') },
      { check: isInvalid('buyGasDelta'), label: t('trading.buy-priority-fee') },
      { check: isInvalid('approveGasDelta'), label: t('common.approve-priority-fee') },
    ];

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid or empty', {type: 'danger'});
      return;
    }

    navigate(PageRoutes.AUTO_TRADE_FILTERS, {state: {settings, amount, name, strategy: state?.strategy}})
  };

  // useEffect(() => {
  //   if (position) return;
  //   if (props.data) {
  //     setPosition(props.data);
  //   } else if (params.hasOwnProperty('id') && params.id) {
  //     accountStore.getPosition(params.id || '')
  //       .then((response) => {
  //         if (response) {
  //           setPosition(response);
  //         }
  //       });
  //   }
  // }, [position, props, params]);

  if (!state?.strategy && !source) {
    return (
      <div className="pt-3 pb-5">
        <div className="tx-28 tx-semibold mb-3">
          {t('common.auto-trade')}
        </div>

        <div>
          <div className="d-flex flex-row gap-3 mb-3">
            <div
              className="card card-button wd-50p active"
              onClick={() => setSource(AutoTradeSource.ETH)}
            >
              <img src={ether} alt="ether" width={32} height={32} className="mr-2" />
              <div>
              {t('trading.all-ethereum-new-pairs')}
              </div>
            </div>
            <div className="card wd-50p card-button disabled">
              <img src={whale} alt="whale" width={32} height={32} className="mr-2" />
              <div className="d-flex align-items-center justify-content-between wd-100p">
                <div>
                  {t('common.whale-signals')}
                </div>
                <Badge label={t('common.soon')} />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <div className="card wd-50p card-button disabled">
              <img src={telegram} alt="telegram" width={32} height={32} className="mr-2" />
              <div className="d-flex align-items-center justify-content-between wd-100p">
                <div>
                  {t('snipe-scope')}
                </div>
                <Badge label={t('common.soon')} />
              </div>
            </div>
            <div className="card wd-50p card-button disabled">
              <img src={telegram} alt="telegram" width={32} height={32} className="mr-2" />
              <div className="d-flex align-items-center justify-content-between wd-100p">
                <div>
                  {t('block-x-ray')}
                </div>
                <Badge label={t('common.soon')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!settings) {
    return (
      <div className="tx-center pt-5">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text={t('strategy.loading-settings')} />
      </div>
    )
  }

  return (
    <div className="pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="tx-28 tx-semibold">
          {t('strategy.strategy-settings')}
        </div>
        <button
          onClick={() => {
            showPopup({
              title: t('strategy.clear-settings-t'),
              message: t('strategy.clear-settings-m'),
              buttons: [
                {
                  text: t('common.clear'),
                  type: 'danger',
                  id: 'clear',
                },
                {
                  text: t('common.cancel'),
                  id: 'close',
                },
              ],
            }).then((result) => {
              if (result === 'clear') {
                handleClear();
              }
            });
          }}
          className="btn btn-link text-decoration-none tx-semibold tx-primary tx-13 tx-right px-0 py-0"
        >
          {t('strategy.clear-settings-l')}
        </button>
      </div>

      <div className="card mb-4">
        <div className="d-flex flex-row justify-content-between align-items-center wd-100p pb-3 border-bottom border-semi-transparent">
          <HistoryLogo mainLogo={ether} size={45} />

          <div className="wd-100p ms-2">
            {!isNameEditing && (
              <div className="d-flex align-items-center justify-content-start cur-pointer" onClick={() => setIsNameEditing(true)}>
                <div className="tx-17 tx-semibold me-2">{name}</div>
                <img src={edit} alt="edit" width={16} height={16} />
              </div>
            )}
            {isNameEditing && (
              <form onSubmit={() => setIsNameEditing(false)}>
                <input
                  onBlur={() => window.scrollTo(0, 0)}
                  type="text"
                  className="form-control appearance-none px-0 py-0 tx-17 tx-semibold bg-transparent tx-white border-0"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                />
              </form>
            )}
            <div className="tx-12 tx-muted">{t('strategy.all-new-eth-pairs')}</div>
          </div>
        </div>

        <div className="mt-3 tx-13 mb-1">
          {t('common.amount')}
        </div>
        <input
          onBlur={() => window.scrollTo(0, 0)}
          type="number"
          placeholder="0.1 ETH"
          className="form-control wd-100p appearance-none"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2 tx-13 tx-semibold">
          {amounts.map((a) => (
            <button
              className="btn btn-semi-10 p-2 justify-content-center align-items-center wd-100p"
              key={`strategy-settings-amount-${a}`}
              onClick={() => setAmount(a.toString())}
            >
              {a} ETH
            </button>
          ))}
        </div>
      </div>

      <div>
        <div>{t('trading.take-profit-stop-loss')}</div>
        <div className="card mt-1 mb-2">
          <SnipeRow>
            <SnipeColumn>
              <div>{t('trading.take-profit')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.takeProfitEnabled}
                onChange={(e) => setSettings({...settings, takeProfitEnabled: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('common.stop-loss')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.stopLossEnabled}
                onChange={(e) => setSettings({...settings, stopLossEnabled: e.target.checked, trailingStopLossEnabled: false})}
              />
            </SnipeColumn>
          </SnipeRow>

          {settings.takeProfitEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip={t('order.percentage-profit-sell-executed')}
                isRequired
              >
                {t('trading.take-profit')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip={t('order.percentage-total-token-sold-profit-level-reached')}
              >
                {t('trading.take-profit-amount')}
              </SettingsInput>
            </SnipeRow>
          )}

          {settings.stopLossEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip={t('trading.percentage-loss-sell-executed')}
                isRequired
              >
                Stop Loss
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip={t('trading.percentage-total-token-sold-loss-level-reached')}
              >
                {t('common.stop-loss')}
              </SettingsInput>
            </SnipeRow>
          )}
        </div>

        <div className={`mt-4 mb-2 ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
          <div>{t('trading.general-settings')}</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="maxGasPrice"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip={t('trading.max-gas-price-description')}
              >
                {t('trading.max-gas-price')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="approveGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip={t('trading.extra-tip-priority-fee-description')}
              >
                {t('common.approve-priority-fee')}
              </SettingsInput>
            </SnipeRow>

            <SnipeRow className="mt-3">
              <SnipeColumn>
                <div>{t('trading.mev-guard')}</div>
                <Toggle
                    icons={false}
                    className="styled-toggle my-2"
                    checked={settings.mevGuardEnabled}
                    onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>{t('trading.fail-guard')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.failGuard}
                  onChange={(e) => setSettings({...settings, failGuard: e.target.checked})}
                />
              </SnipeColumn>
            </SnipeRow>

            {/*<SnipeRow className="mt-3">*/}
            {/*  <SnipeColumn>*/}
            {/*    <div className="tx-gray-300">Anti Rug</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      disabled*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*  <SnipeColumn>*/}
            {/*    <div>MEV Guard</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      checked={settings.mevGuardEnabled}*/}
            {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*</SnipeRow>*/}

            <SnipeRow className="mt-3">
              <SnipeColumn className="tx-12 tx-semibold">
                <div>{t('trading.auto-approve')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.autoApprove}
                  onChange={(e) => setSettings({...settings, autoApprove: e.target.checked})}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>{t('trading.trailing-stop-loss')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.trailingStopLossEnabled}
                  onChange={(e) => setSettings({...settings, trailingStopLossEnabled: e.target.checked})}
                />
              </SnipeColumn>
            </SnipeRow>
          </div>

          <div className="mt-4">{t('trading.buy-settings')}</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buySlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip={t('trading.percentage-price-change-execution')}
                isRequired
              >
                {t('trading.slippage')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buyGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip={t('trading.extra-tip-priority-fee-description')}
              >
                {t('trading.priority-fee')}
              </SettingsInput>
            </SnipeRow>
          </div>

          <div className="mt-4">{t('trading.sell-settings')}</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellSlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip={t('trading.percentage-price-change-execution')}
                isRequired
              >
                {t('common.sell-slippage')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip={t('trading.extra-tip-priority-fee-description')}
              >
                {t('trading.priority-fee')}
              </SettingsInput>
            </SnipeRow>
          </div>
        </div>

        <div className="pb-4 mb-2 mt-4 tx-13 tx-center border-bottom border-semi-transparent cur-pointer with-hover" onClick={() => setIsDetailsOpen((prev) => !prev)}>
          <span>{t('trading.more-settings')}</span>
          <img src={chevron} alt="toggle details" width={16} height={16} className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`} />
        </div>
      </div>

      <button
        className="btn btn-primary-10 wd-100p mt-3"
        onClick={handleSubmit}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} />
        {t('common.continue')}
      </button>

      <button
        className="btn btn-transparent wd-100p mt-2 mb-3"
        onClick={() => {
          showPopup({
            title: t('strategy.close-auto-trade-settings'),
            message: t('strategy.close-trade-settings-warning'),
            buttons: [
              {
                text: t('common.cancel'),
                type: 'danger',
                id: 'cancel-settings',
              },
              {
                text: t('common.close'),
                id: 'cancel',
              },
            ],
          }).then((result) => {
            if (result === 'cancel-settings') {
              navigate(-1);
            }
          });
        }}
        disabled={isSaving}
      >
        {t('common.cancel')}
      </button>
    </div>
  );
});

export default StrategySettings;
