import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import './styles/main.scss';
import MainLayout from '@components/MainLayout';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PageRoutes } from './constants';
import routes from 'routes';
import {
  BackButton,
  useExpand,
  useWebApp,
  WebAppProvider,
} from '@vkruglikov/react-telegram-web-app';
import { useStores } from '@hooks/useStores';
import useQuery from '@hooks/useQuery';
import RouteWrapper from '@components/RouteWrapper';
import { observer } from 'mobx-react-lite';
import useResponseHandler from '@hooks/useResponseHandler';
import { hasOwnProperty } from '@helpers/object';
import parseStartParam from '@helpers/parseStartParam';
import { useBackButton } from '@telegram-apps/sdk-react';

const preserveRedirectRoutes = [
  '/swap',
  '/position-processing',
  '/position-settings',
  '/token-snipe/',
  '/token-snipe-bot/',
  '/auto-trade/',
  '/test',
  '/limit-orders',
  '/referral',
  '/research',
];

const noBackRoutes = [
  PageRoutes.HOME,
  PageRoutes.LOGIN,
  PageRoutes.WALLET,
  PageRoutes.WALLET_CREATED,
  PageRoutes.BOT_SETTINGS,
];

const noBackRoutesPatterns = [
  '/position-processing',
  '/position-settings/',
  '/token-snipe-bot/',
];

const App = observer(() => {
  const [isExpanded, expand] = useExpand();
  const { authStore, accountStore, swapStore, ordersStore } = useStores();
  const { isTokenSelection } = swapStore;
  const query = useQuery();
  const navigate = useNavigate();
  const WebApp = useWebApp();
  const [backAvailable, setBackAvailable] = useState(false);
  const { isLogged } = authStore;
  const { wallets, currentWallet, redirectTo } = accountStore;
  const { pathname } = location;
  const handleResponse = useResponseHandler();
  // const [bb] = useBackButton();

  // Разворачивание телеги на полный экран
  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, [isExpanded, expand]);

  useEffect(() => {
    if (
      !wallets.length &&
      (routes.find((r) => r.isPrivate && r.path === location.pathname) ||
        preserveRedirectRoutes.some((p) => location.pathname.includes(p)))
    ) {
      accountStore.setRedirectTo(location.pathname);
    }
  }, [wallets, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/' && wallets.length) {
      navigate(redirectTo ?? PageRoutes.WALLET, { replace: true });
    }
  }, [isLogged]);

  // Сеттинг кнопки назад в телеге
  useEffect(() => {
    if (
      noBackRoutes.includes(location.pathname as string as PageRoutes) ||
      noBackRoutesPatterns.some((p) => location.pathname.includes(p))
    ) {
      setBackAvailable(false);
      // console.log('no back route', location.pathname);
    } else {
      if (location.pathname === PageRoutes.SEND_CONFIRM) {
        setBackAvailable(false);
      } else {
        setBackAvailable(true);
      }
      // console.log('with back route', location.pathname);
    }
  }, [location.pathname]);

  // Сеттинг параметров телеги
  useEffect(() => {
    if (WebApp && hasOwnProperty(WebApp, 'setHeaderColor')) {
      WebApp.setHeaderColor('#0C0C0C');
    }
  }, [WebApp]);

  // Сеттинг стартовых парамсов
  useEffect(() => {
    if (WebApp && hasOwnProperty(WebApp, 'initDataUnsafe')) {
      const { start_param: startParam } = WebApp.initDataUnsafe;

      if (startParam) {
        const params = parseStartParam(startParam);

        if (hasOwnProperty(params, 'route')) {
          const parsedRoute = params.route.replaceAll('=-=', '/').replaceAll('__', '/');
          accountStore.setRedirectTo('/' + parsedRoute);
        }
      }

      const initParams = sessionStorage.getItem('__telegram__initParams');

      if (initParams) {
        const initParamsJson = JSON.parse(initParams);
        const tgWebAppDataObj = new URLSearchParams(
          initParamsJson.tgWebAppData,
        );
        tgWebAppDataObj.delete('start_param');
        const tgWebAppData = tgWebAppDataObj.toString();
        sessionStorage.setItem(
          '__telegram__initParams',
          JSON.stringify({ ...initParamsJson, tgWebAppData }),
        );
      }
    }
  }, [WebApp]);

  // Сеттинг бэкграунда шапки телеги
  useEffect(() => {
    if (WebApp && hasOwnProperty(WebApp, 'setBackgroundColor')) {
      const isLimitOrderToken =
        location.pathname.includes('/limit-orders/') &&
        !location.pathname.includes('/trade') &&
        location.pathname.split('/').length === 4;
      if (location.pathname.includes('/asset/') || isLimitOrderToken) {
        WebApp.setBackgroundColor('#161616');
      } else {
        WebApp.setBackgroundColor('#0C0C0C');
      }
    }
  }, [WebApp, location]);

  const handleBack = () => {
    if (
      location.pathname.includes('/limit-orders/') &&
      !location.pathname.includes('/trade')
    ) {
      navigate(PageRoutes.LIMIT_ORDERS, { replace: true });
      return;
    }
    if (isTokenSelection) {
      swapStore.setIsTokenSelection(false);
      swapStore.setIsReceiveSelection(false);
      return;
    }
    navigate(-1);
  };

  const wrap = useCallback((element: React.ReactNode) => {
    return <RouteWrapper>{element}</RouteWrapper>;
  }, []);

  useEffect(() => {
    if (authStore.isLogged) return;
    if (authStore.isActivated) {
      if (authStore.isPinEnabled) {
        navigate(PageRoutes.LOGIN, { replace: true });
      }
    } else if (!authStore.isActivated) {
      navigate(PageRoutes.HOME, { replace: true });
    }
    accountStore.setAppInitialized(true);
  }, [authStore.isLogged, authStore.isActivated]);

  useEffect(() => {
    if (isLogged && redirectTo && redirectTo !== PageRoutes.WALLET) {
      navigate(redirectTo, { replace: false });
      accountStore.setRedirectTo(null);
    }
  }, [redirectTo, pathname]);

  // Ресет
  useEffect(() => {
    if (location.hash === '#reset') {
      accountStore.resetStore();
    }
  }, [location.hash]);

  // Сентри юзер сеттинг
  useEffect(() => {
    const username = localStorage.getItem('userId');
    if (username && currentWallet) {
      Sentry.setUser({ id: currentWallet.id, username });
    }
  }, [currentWallet]);

  return (
    <WebAppProvider
      options={{
        smoothButtonsTransition: true,
      }}
    >
      {WebApp.platform === 'ios' && <span className="collapse-anchor" />}
      <MainLayout>
        <Routes>
          {routes.map(({ path, component }) => (
            <Route
              path={path}
              element={wrap(component)}
              key={`all-routes-${path}`}
            />
          ))}
        </Routes>
      </MainLayout>
      {backAvailable && <BackButton onClick={handleBack} />}
    </WebAppProvider>
  );
});

export default App;
