import React from "react";
import {chainLogo, chainName, chainToShort} from "@helpers/chains";
import {ChainId} from "../constants";

interface NonEvmChainLabelProps {
  short?: boolean;
  className?: string;
  chainId?: ChainId;
}

const NonEvmChainLabel = ({chainId = ChainId.SOLANA, short = false, className = ''}: NonEvmChainLabelProps) => {
  return (
    <div className={`d-flex align-items-center tx-12 wd-100p ${className}`}>
      <img src={chainLogo(chainId)} alt="chain" width={16}/>
      <span className="ms-1">{short ? chainToShort(chainId) : chainName(chainId)}</span>
    </div>
  );
};

export default NonEvmChainLabel;
