import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import UserAvatar from '@components/common/UserAvatar';
import { getRandomInt } from '@helpers/numbers';
import { walletTypeName } from '@helpers/chains';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { WalletType } from '../../../types/enums';
import useNotification from '@hooks/useNotification';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';

const AvatarModal = observer(() => {
  const { t } = useTranslation();
  const { accountStore, settingsStore } = useStores();
  const { currentWallet: wallet } = accountStore;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<number>(1);
  const [randoms, setRandoms] = useState<number[]>([]);
  const [selected, setSelected] = useState<number>();
  const notify = useNotification();
  const [updateAvatar, setUpdateAvatar] = useState<number>(1);

  useEffect(() => {
    const randoms = [];
    for (let i = 0; i < 3; i++) {
      randoms.push(getRandomInt(trigger * 1488));
    }
    setRandoms(randoms);
    setSelected(undefined);
  }, [trigger]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!selected) {
      notify(t('avatar.select-avatar'), { type: 'danger' });
      return;
    }
    setShowModal(false);
    settingsStore.setAvatarOption(wallet?.address || '', `${selected}`);
    setTimeout(() => {
      setUpdateAvatar((v) => v + 1);
    }, 100);
  };

  return (
    <>
      <div className="tx-center btn btn-link p-0 tx-12 tx-muted" onClick={() => setShowModal(true)}>
        <UserAvatar
          key={`avatar-modal-${updateAvatar}`}
          string={wallet?.address || ''}
          size={50}
        />
        <div>{t('avatar.change')}</div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} onExited={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('avatar.choose-new-avatar')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {randoms.map((value) => (
            <Vibrutton
              className={`btn ${selected === value ? 'btn-primary' : 'btn-semi-10 bg-semi-transparent-10'} d-flex align-items-center px-3 py-3 wd-100p mb-3 tx-17 tx-noraml`}
              onClick={() => setSelected(value)}
              key={`new-avatar-selection-item-${value}`}
            >
              <div className="d-flex wd-45 ht-45 align-items-center justify-content-center rounded-circle">
                <UserAvatar string={`${wallet?.address}${value}`} size={50} />
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center ms-3">
                <div className="tx-13 tx-muted lh-1">{walletTypeName(wallet?.type || WalletType.EVM)}</div>
                <div className="tx-17 me-2">{wallet?.name}</div>
              </div>
            </Vibrutton>
          ))}

          <button className="btn btn-link wd-100p tx-center py-0 mb-4" onClick={() => setTrigger((v) => v + 1)}>
            {t('avatar.refresh-avatars')}
          </button>

          <button
            className="btn btn-primary-10 wd-100p mt-3"
            onClick={handleSave}
          >
            {t('avatar.update-avatar')}
          </button>

          <button
            className="btn btn-transparent wd-100p mt-2 mb-3"
            onClick={handleClose}
          >
            {t('common.cancel')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default AvatarModal;
