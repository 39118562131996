import React, { useEffect, useMemo, useRef, useState } from 'react';
import md5 from 'md5';
import IconWithLoading from '@components/common/IconWithLoading';
import TokenName from '@components/common/TokenName';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { ChainId } from '../../constants';

interface UserAvatarProps {
  className?: string,
  size?: number,
  string: string,
  network?: ChainId,
}

const UserAvatar = observer((props: UserAvatarProps) => {
  const { settingsStore } = useStores();
  const {
    className = 'identicon rounded-circle',
    size = 40,
    string = '',
    network,
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const prev = useRef<string | null>(null);

  const avatarOption = useMemo(() => {
    return settingsStore.avatarOption(string);
  }, [string]);

  const imageUrl = useMemo(() => {
    return `https://unicornify.pictures/avatar/${md5(`${string}${avatarOption}`)}?s=128`;
  }, [string, avatarOption]);

  useEffect(() => {
    if (!prev.current) {
      prev.current = avatarOption;
    }
  }, []);

  useEffect(() => {
    if (prev.current !== avatarOption) {
      setIsLoaded(false);
      setError(false);
      prev.current = avatarOption;
    }
  }, [string, avatarOption]);

  const innerSize = network ? size - 5 : size;

  return (
    <div
      className={`ms-0 wd-${size} mn-wd-${size} mx-wd-${size} ht-${size} rounded-50 overflow-hidden token-logo d-flex ${className} ${network ? `avatar-network` : ''}`}>
      {network && <div className={`avatar-network-border avatar-network-${network.toLowerCase()}`} />}
      <div
        className="wd-100p ht-100p absolute-item pos-absolute z-index-10 d-flex align-items-center justify-content-center">
        <IconWithLoading isLoading={!isLoaded} className="" />
      </div>
      {error && <TokenName text="Error" size={innerSize} />}
      <img
        alt="user-avatar"
        key={`${string}${settingsStore.avatarOption(string)}`}
        src={imageUrl}
        width={size}
        height={size}
        className={`z-index-50 ${isLoaded && !error ? 'd-block' : 'd-none'} ${className}`}
        onError={(currentTarget) => {
          // @ts-ignore
          currentTarget.onerror = null;
          setError(true);
        }}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
});

export default UserAvatar;
