import React, { useEffect, useMemo, useState } from 'react';
import NetworkSelection from '@components/NetworkSelection';
import QRCode from 'qrcode.react';
import logo from '@assets/images/logo-dark.svg';
import { getEllipsisTxt } from '@helpers/formatters';
import copy from '@assets/icons/copy.svg';
import share from '@assets/icons/share.svg';
import CopyButton from '@components/common/CopyButton';
import { allowedChains, chainLogo, chainName, chainToken, chainTokenName } from '@helpers/chains';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import { ChainId } from '../../constants';
import WalletSelection from '@components/WalletSelection';
import arrow from '@assets/icons/chevron-down.svg';
import UserAvatar from '@components/common/UserAvatar';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import chevron from '@assets/icons/chevron-yellow.svg';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

interface ReceiveWarningProps {
  network: ChainId;
  isErc: boolean;
}

const ReceiveWarning = ({ network, isErc }: ReceiveWarningProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [w] = useAmplitude();
  const [more, setMore] = useState(false);

  const list = useMemo(() => {
    return allowedChains
      .filter((key) => key !== network && key !== ChainId.ALL)
      .map((key) => chainName(key))
      .join(', ');
  }, [network]);

  const desc: { [locale: string]: JSX.Element } = {
    'en-EN': (
      <>
        <div>
          You're are about to receive funds in{' '}
          <span className="tx-bold">
            {chainName(network)}
            {isErc ? ' ERC-20' : ''}
          </span>{' '}
          network.
        </div>
        <div className="tx-bold mt-1">
          {chainTokenName(network)} tokens on this network can't be used on
          other networks.
        </div>
        <div className={`${more ? 'd-block' : 'd-none'} mt-1`}>
          So received tokens in{' '}
          <span className="tx-bold">{chainName(network)}</span> network will not
          be tradable on other networks{' '}
          <span className="tx-bold">({list})</span>.
        </div>
      </>
    ),
    'ru-RU': (
      <>
        <div>
          Вы собираетесь получить средства в сети{' '}
          <span className="tx-bold">
            {chainName(network)}
            {isErc ? ' ERC-20' : ''}
          </span>
          .
        </div>
        <div className="tx-bold mt-1">
          {chainTokenName(network)} токены в этой сети нельзя использовать в
          других сетях.
        </div>
        <div className={`${more ? 'd-block' : 'd-none'} mt-1`}>
          Таким образом, полученные токены в сети{' '}
          <span className="tx-bold">{chainName(network)}</span> не будут
          доступны для торговли в других сетях{' '}
          <span className="tx-bold">({list})</span>.
        </div>
      </>
    ),
  };

  return (
    <div className="card bg-semi-warning-10 tx-warning mt-3">
      {desc[language] ?? desc['en-EN']}

      <div
        className="mt-3 tx-13 cur-pointer"
        onClick={w(
          () => setMore((prev) => !prev),
          more && AmplitudeEvent.RECEIVE_WARNING_VIEWED,
        )}
      >
        <span>{t('common.details')}</span>
        <img
          src={chevron}
          alt="toggle details"
          width={16}
          height={16}
          className={`ms-1 will-rotate ${more ? 'rotate-180' : ''}`}
        />
      </div>
    </div>
  );
};

const Receive = observer(() => {
  const { t } = useTranslation();
  const [w, AmplitudeWrapper] = useAmplitude();
  const { accountStore } = useStores();
  const { address, isTron, isEvm, currentWallet, network } = accountStore;
  const canShare = !!navigator.canShare && !!navigator.share;
  const { state } = useLocation();

  const isErc = chainToken(network) === 'ETH';

  const handleShare = () => {
    try {
      navigator.share({
        title: `${t('wallet.alpha-wallet-my')} Alpha Wallet ${chainName(network)} ${t('wallet.alpha-wallet-address')}`,
        text: `${address}`,
      });
    } catch (error) {
      console.error(error);
      // notify('Share failed', {duration: 3000, type: 'danger'});
    }
  };

  useEffect(() => {
    if (state?.network) {
      accountStore.setNetwork(state?.network);
    }
  }, [state]);

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between align-items-center">
        <WalletSelection className="btn btn-transparent p-0 wd-40 ht-40">
          <UserAvatar string={address} />
        </WalletSelection>
        <div className="ms-2 me-auto d-flex flex-column justify-content-center">
          <div className="tx-17 lh-3">
            {currentWallet?.name ||
              getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
          </div>
          <NetworkSelection
            network={network}
            onChange={(chain) => accountStore.setNetwork(chain)}
          >
            <div className="d-flex align-items-center tx-12">
              <img
                src={chainLogo(network)}
                alt="chain"
                width={16}
                className={`me-1 ${network === ChainId.ALL ? 'd-none' : ''}`}
              />
              <span className="me-1">{chainName(network)}</span>
              <img src={arrow} alt="Choose network" width={16} />
            </div>
          </NetworkSelection>
        </div>
      </div>

      {isTron && (
        <div className="card bg-semi-warning-10 tx-warning tx-14 mt-3">
          {t('alerts.trx-min-deposit')}
        </div>
      )}

      <AmplitudeWrapper eventInput={AmplitudeEvent.COPY_ADDRESS_BUTTON_CLICKED}>
        <CopyButton text={address} className="btn btn-link p-0 wd-100p" noIcon>
          <div className="bg-white rounded-10 d-flex p-4 qr-code my-4 wd-70p mx-auto pos-relative">
            <QRCode
              value={`${address}`}
              size={1000}
              bgColor="#FFFFFF"
              fgColor="#252525"
              imageSettings={{
                src: logo,
                excavate: true,
                width: 198,
                height: 150,
              }}
            />
            <img src={logo} alt="Alpha Wallet" className="qr-code-logo" />
          </div>
        </CopyButton>

        <CopyButton
          text={address}
          className="btn btn-link p-0 wd-100p text-decoration-none"
          noIcon
        >
          <div className="card tx-center px-1">
            <div className="tx-17 tx-semibold tx-capitalize mb-2">
              {isErc ? 'ERC-20 ' : ''}
              {t('wallet.alpha-wallet-address')}
            </div>
            <div className="tx-muted tx-13">{address}</div>
          </div>
        </CopyButton>
      </AmplitudeWrapper>

      <ReceiveWarning network={network} isErc={isErc} />

      <div
        className={`d-flex justify-content-between align-items-center mt-3 ${canShare ? 'gap-4' : ''}`}
      >
        <AmplitudeWrapper
          eventInput={AmplitudeEvent.COPY_ADDRESS_BUTTON_CLICKED}
          className={`wd-100p`}
        >
          <CopyButton
            text={address}
            className="btn btn-semi-10 tx-17 tx-semibold px-0 d-flex align-items-center justify-content-center"
            noIcon
          >
            <>
              <img src={copy} alt="Copy" width={16} className="me-2" />
              <span>{t('common.copy')}</span>
            </>
          </CopyButton>
        </AmplitudeWrapper>

        {canShare && (
          <button
            className="btn btn-semi-10 tx-17 tx-semibold wd-100p px-0 d-flex align-items-center justify-content-center"
            onClick={w(
              handleShare,
              AmplitudeEvent.SHARE_ADDRESS_BUTTON_CLICKED,
            )}
          >
            <img src={share} alt="Share" width={16} className="me-2" />
            <span>{t('common.share')}</span>
          </button>
        )}
      </div>
    </div>
  );
});

export default Receive;
