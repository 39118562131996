import React, { useEffect } from 'react';
import cn from 'classnames';
import TPSLInputGroup from './TPSLInputGroup';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import useNotification from '@hooks/useNotification';
import OrderSettingsModal from '../OrderSettingsModal';
import { defaultOrderSettings } from '@stores/settings-store';
import { IOrderSettings } from 'types';

const StopLossValue = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { tokenTrade, settingsStore } = useStores();
  const { stopLossSettings } = settingsStore;

  const {
    chain,
    isStopLossesEnabled,
    stopLosses,
    stopLossesError,
    emptyInputsLightOn,
  } = tokenTrade;

  const settings = stopLossSettings ?? { [chain!]: defaultOrderSettings };

  const updateSettings = (val: IOrderSettings) => {
    settingsStore.setStopLossSettings({ ...settings, [chain!]: val });
  };

  useEffect(() => {
    if ((stopLosses?.length ?? 0) >= 3)
      notify(t('order.max-stop-loss-orders-reached'), { type: 'danger' });
  }, [stopLosses?.length]);

  return (
    <>
      {isStopLossesEnabled && (
        <div className={cn(['card', 'rounded-3-px', 'mg-y-2', 'gap-2'])}>
          <div className="d-flex justify-content-between mb-1">
            <span className="tx-17 tx-semibold">{t('order.stop-loss')}</span>
            <OrderSettingsModal
              data={settings[chain!]}
              updateSettings={updateSettings}
            />
          </div>
          <div className="d-flex gap-2">
            <span className="d-flex wd-50p tx-muted tx-13">
              {t('order.price-drop')} %
            </span>
            <span className="d-flex wd-50p tx-muted tx-13">
              {t('order.sell-percents-price-growth')}
            </span>
          </div>
          {stopLosses?.map((val, idx) => {
            return (
              <TPSLInputGroup
                disableAdding={stopLosses.length >= 3}
                lightEmptyInputs={emptyInputsLightOn}
                hasSumError={!!stopLossesError[0]}
                idx={idx}
                key={`stop-loss-${idx}`}
                values={val}
                onChange={tokenTrade.setStopLossValue(idx)}
                onAdd={() => tokenTrade.addStopLossValue()}
                onRemove={() => tokenTrade.removeStopLossValue(idx)}
              />
            );
          })}
          {stopLossesError && (
            <ul className="mb-0 mt-1 ps-4">
              {stopLossesError.map(
                (error, idx) =>
                  error && (
                    <li key={`${error}-${idx}`} className="tx-13 tx-danger">
                      {t(error)}
                    </li>
                  ),
              )}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default observer(StopLossValue);
