import FormattedNumber from "@components/common/FormattedNumber";
import React, {useMemo, useState} from "react";
import CopyButton from "@components/common/CopyButton";
import {getEllipsisTxt} from "@helpers/formatters";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import chevron from "@assets/icons/chevron.svg";
import confirmAlert from "@components/ConfirmAlert";
import {useNavigate} from "react-router-dom";
import {
  PositionModel,
  PositionStatus,
  PositionTransactionStatus,
} from "../../../types";
import BigNumber from "bignumber.js";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import usePopup from "@hooks/usePopup";
import arrowRight from "@assets/icons/arrow-right.svg";
import cross from "@assets/icons/cross-red.svg";
import times from "@assets/icons/times-red.svg";
import share from "@assets/icons/share.svg";
import chart from "@assets/whale-signals/chart.svg";
import PositionSettingsModal from "@pages/TokenSnipe/components/PositionSettingsModal";
import {format, formatDistanceToNow} from "date-fns";
import usePositionData from "@hooks/usePositionData";
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "@helpers/timeAgo";

interface TokenSnipeOpenOrderProps {
  data: PositionModel;
  updatePosition?: (position: PositionModel) => void;
  sharePosition?: () => void;
  cancelOrder?: (id: string) => void;
}

interface DetailsItem {
  label: string;
  text?: string;
  value?: number | string | null;
  unit?: string;
  altValue?: number;
  altUnit?: string;
  decimals?: number;
  enabled?: boolean;
  isMain?: boolean;
}

const TokenSnipeOrder = observer(({data, updatePosition, sharePosition, cancelOrder}: TokenSnipeOpenOrderProps) => {
  const { t, i18n: { language } } = useTranslation();
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const {settings} = data;
  const handleResponse = useResponseHandler();
  const showPopup = usePopup();
  const [showFullDate, setShowFullDate] = useState<boolean>(false);
  const {
    isRobot,
    isOpen,
    buyTransaction,
    sellTransaction,
    approveTransaction,
    gasCost,
    soldAmount,
    realizedProfitEth,
    positionEthValue,
    positionDiffPercent,
  } = usePositionData(data);

  const guards = [
    {
      label: t('trading.mev-blocker'),
      value: settings.mevGuardEnabled,
    },
    {
      label: t('trading.fail-guard'),
      value: settings.failGuard,
    },
    {
      label: t('trading.anti-rug'),
      value: false,
    },
    {
      label: t('trading.trailing-stop-loss'),
      value: settings.trailingStopLossEnabled,
    },
    {
      label: t('trading.auto-approve'),
      value: settings.autoApprove,
    },
  ];

  const detailsClosed: DetailsItem[] = [
    {
      label: t('common.buy-amount'),
      value: data.buyAmount,
      unit: 'ETH',
      decimals: 4,
    },
    {
      label: t('common.sold-amount'),
      value: soldAmount,
      unit: 'ETH',
      decimals: 4,
      enabled: !!soldAmount,
    },
    {
      label: t('common.gas-cost'),
      value: gasCost,
      unit: 'ETH',
      decimals: 4,
      enabled: !!gasCost,
    },
    {
      label: t('common.sold-percents'),
      value: sellTransaction?.meta.sellAmountPercent,
      unit: '%',
      enabled: !!soldAmount,
    },
  ];

  const details: DetailsItem[] = [
    {
      label: t('trading.take-profit-sell'),
      text: `${settings.takeProfitPercent}% / ${settings.takeProfitSellPercent}%`,
      enabled: settings.takeProfitEnabled,
      isMain: true,
    },
    {
      label: t('common.stop-loss-sell'),
      text: `${settings.stopLossPercent}% / ${settings.stopLossSellPercent}%`,
      enabled: settings.stopLossEnabled,
      isMain: true,
    },
    {
      label: t('common.buy-amount'),
      value: data.buyAmount,
      decimals: 4,
      unit: 'ETH',
      enabled: data.status === PositionStatus.OPEN,
      isMain: true
    },
    {
      label: t('common.unrealized-profit'),
      value: (parseFloat(data.curPrice) / parseFloat(data.buyPrice) - 1) * data.buyAmount,
      unit: 'ETH',
      decimals: 4,
      isMain: true
    },
    {
      label: t('order.opened'),
      text: data.createdAt ? (showFullDate ? format(new Date(data.createdAt), 'dd.MM.yyyy HH:mm:ss') : formatDistanceToNow(new Date(data.createdAt), { locale: getDateFnsLocale(language) })) : undefined,
      enabled: !!data.createdAt && data.status === PositionStatus.OPEN,
      isMain: true,
    },
    {
      label: t('common.spent-for-gas'),
      value: data.transactions.map(tx => parseFloat(tx.meta.gasFeeInEth || "0")).reduce((r, v) => r + v, 0),
      unit: 'ETH',
      decimals: 4
    },
    {
      label: t('common.sell-priority-tip'),
      value: settings.sellGasDelta,
      unit: 'GWEI',
      enabled: !!settings.sellGasDelta
    },
    {
      label: t('common.sell-slippage'),
      value: settings.sellSlippage,
      unit: '%'
    },
    {
      label: t('common.options'),
      text: guards.filter((guard) => guard.value).map((guard) => guard.label).join(', ') || 'None',
    },
    {
      label: t('common.pair'),
      text: data.pair,
    },
  ];

  const transactionList = [
    {
      label: t('order.buy-tx'),
      status: buyTransaction?.status,
      hash: buyTransaction?.hash,
    },
    {
      label: t('order.approve-tx'),
      status: approveTransaction?.status,
      hash: approveTransaction?.hash,
    },
    {
      label: t('order.sell-tx'),
      status: sellTransaction?.status,
      hash: sellTransaction?.hash,
    },
  ];

  const handleSell = () => {
    // confirmAlert({
    //   title: t('common.sell'),
    //   closeButton: true,
    //   okLabel: t('common.sell'),
    //   confirmation: (
    //     <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-2 mb-4">
    //       <HistoryLogo symbolIn={data.token.symbol} size={45} />
    //       <div className="tx-semibold tx-28 mt-2 mb-1">{t('common.sell')} {amount || 100}%</div>
    //       <div className="tx-muted tx-17">{t('order.confirm-sell')}</div>
    //       <div className="tx-muted tx-17">«{data.token.name}» {t('order.sell-for')} {amount || 100}%</div>
    //     </div>
    //   ),
    // }).then((response) => {
    //   if (response) {
    //     accountStore.sellPosition(data.id, new BigNumber(amount || 100).toNumber()).then((succeed) => {
    //       if (succeed) {
    //         navigate(`/position-processing/${data.id}`);
    //       }
    //     }).catch((e) => {
    //       handleResponse(e);
    //       console.error(e);
    //     });
    //   }
    // })
  };

  const bg = useMemo(() => {
    if (data.status === PositionStatus.COMPLETED) {
      return 'bg-semi-success-10';
    }

    if (data.status === PositionStatus.ERROR) {
      return 'bg-semi-danger-10';
    }

    if (data.status === PositionStatus.ABORTED) {
      return 'bg-semi-warning-10';
    }

    return '';
  }, [data.status]);

  return (
    <div className={`card mb-4 ${bg}`}>
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p pb-3 border-bottom border-semi-transparent">
        <HistoryLogo symbolIn={data.token.symbol} size={45} bg={bg || undefined} isRobot={isRobot} />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div>{data.token.name}</div>
            <FormattedNumber
              value={positionEthValue}
              postfix="ETH"
              subZeros
              decimals={4}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <CopyButton text={data.token.address} className="btn btn-link p-0 tx-13 tx-left text-decoration-none" iconClass="tx-white ms-1">
              {getEllipsisTxt(data.token.address,3, '0x')}
            </CopyButton>
            <a
              href={`https://www.dextools.io/app/en/ether/pair-explorer/${data.pair.toLowerCase()}`}
              rel="noreferrer"
              target="_blank"
              className="me-auto"
            >
              <img src={chart} alt="link" width={16} height={16} className="ms-2" />
            </a>

            <FormattedNumber
              value={positionDiffPercent}
              decimals={1}
              withSign={!!data.curPrice && !!data.buyPrice}
              className={positionDiffPercent > 0 ? 'tx-success' : 'tx-danger'}
              postfix="%"
            />
          </div>
        </div>
      </div>

      <div className="tx-13 mt-3">
        {(!isOpen ? detailsClosed : details).filter(({isMain, enabled}) => enabled !== false && (!isOpen ? true : isMain)).map(({label, text, value, unit, altValue, altUnit, decimals}, index) => (
          <div
            className={`d-flex wd-100p align-items-center justify-content-between ${index && 'mt-1'}`}
            key={`token-${data.token.address}-main-details-${label}`}
            onClick={() => {
              if (label === 'Age') {
                setShowFullDate((prev) => !prev);
              }
            }}
          >
            <div className="tx-muted">{label}</div>
            {!!text && <div className="tx-right">{text}</div>}
            {!!value && (
              <div>
                <FormattedNumber
                  value={value}
                  postfix={unit}
                  withSign={value < 0}
                  subZeros
                  decimals={decimals}
                />
                {!!altValue && (
                  <FormattedNumber
                    value={altValue}
                    suffix=" ("
                    postfix={`${altUnit})`}
                    withSign={altValue < 0}
                    subZeros
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="my-1 tx-13 cur-pointer" onClick={() => setIsDetailsOpen((prev) => !prev)}>
        <span>{t('common.details')}</span>
        <img src={chevron} alt="toggle details" width={16} height={16} className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`} />
      </div>

      <div className={`tx-13 ${data.status === PositionStatus.OPEN && 'mb-3'} ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
        {details.filter(({isMain, enabled}) => enabled !== false && (!isOpen ? true : !isMain)).map(({label, text, value, unit, altValue, altUnit, decimals}, index) => (
          <div className={`d-flex align-items-center wd-100p justify-content-start ${index && 'mt-1'}`} key={`token-${data.token.address}-main-details-${label}`}>
            <div className="tx-muted">{label}</div>
            {label !== t('common.pair') && !!text && <div className="tx-right ms-auto me-0">{text}</div>}
            {!!value && (
              <div className="ms-auto me-0">
                <FormattedNumber
                  value={value}
                  postfix={unit}
                  withSign={value < 0}
                  subZeros
                  decimals={decimals}
                />
                {!!altValue && (
                  <FormattedNumber
                    value={altValue}
                    suffix=" ("
                    postfix={`${altUnit})`}
                    withSign={altValue < 0}
                  />
                )}
              </div>
            )}
            {label === t('common.pair') && !!text && (<>
              <CopyButton
                text={text}
                className="btn btn-link p-0 tx-13 tx-left text-decoration-none ms-auto me-0"
                iconClass="tx-white ms-1"
                iconSize={14}
              >
                {getEllipsisTxt(text,3, '0x')}
              </CopyButton>
            </>)}
          </div>
        ))}
        {transactionList.map(({label, status, hash}, index) => !!status && (status === PositionTransactionStatus.CONFIRMED || status === PositionTransactionStatus.ERROR) && (
          <div className={`d-flex align-items-center justify-content-between mt-1`} key={`token-${data.token.address}-main-details-${label}`}>
            <div className="tx-muted">{label}</div>
            <div className="tx-right">
              {!!hash && status === PositionTransactionStatus.CONFIRMED && (<a
                className="tx-white tx-13 text-decoration-none"
                href={`https://etherscan.io/tx/${hash}`}
                target={`_blank`}
                rel="noreferrer"
              >
                {getEllipsisTxt(hash, 4)} <img src={arrowRight} alt="Hash" width={16} height={16} />
              </a>)}
              {status === PositionTransactionStatus.ERROR && (<>
                <span className="tx-danger">{t('common.failed')}</span>
                <img src={cross} alt="failed" width={14} height={14} className="ms-1" />
              </>)}
            </div>
          </div>
        ))}
      </div>

      {isOpen && (<>
        <div className="mt-3 tx-13 mb-1">
          {t('common.sell')} (%)
        </div>
        <div className="d-flex justify-content-between align-items-center wd-100p gap-3">
          <input
            type="number"
            placeholder="100%"
            className="form-control ht-50 appearance-none"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            onBlur={() => window.scrollTo(0, 0)}
          />
          <button className="btn btn-primary-10 ht-50" onClick={handleSell}>{t('common.sell')}</button>
        </div>
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2 pb-3 border-bottom border-semi-transparent mb-3 tx-13 tx-semibold">
          <div className="card bg-semi-transparent-10 p-2 justify-content-center align-items-center wd-100p cur-pointer" onClick={() => setAmount('25')}>
            25%
          </div>
          <div className="card bg-semi-transparent-10 p-2 justify-content-center align-items-center wd-100p cur-pointer" onClick={() => setAmount('50')}>
            50%
          </div>
          <div className="card bg-semi-transparent-10 p-2 justify-content-center align-items-center wd-100p cur-pointer" onClick={() => setAmount('75')}>
            75%
          </div>
          <div className="card bg-semi-transparent-10 p-2 justify-content-center align-items-center wd-100p cur-pointer" onClick={() => setAmount('100')}>
            100%
          </div>
        </div>
        {!!cancelOrder && !!updatePosition && (<div className="d-flex justify-content-end align-items-center wd-100p tx-semibold tx-13">
          {!!cancelOrder && (<div
            className="wd-35 ht-35 bg-semi-danger-10 rounded-1 d-flex justify-content-center align-items-center cur-pointer ms-0 me-auto"
            onClick={() => {
              showPopup({
                title: t('order.cancel-order'),
                message: t('order.cancel-order-confirm'),
                buttons: [
                  {
                    text: t('common.yes'),
                    type: 'danger',
                    id: 'cancel-order',
                  },
                  {
                    text: t('common.close'),
                    id: 'cancel',
                  },
                ],
              }).then((result) => {
                if (result === 'cancel-order') {
                  cancelOrder(data.id);
                }
              });
            }}
          >
            <img src={times} alt="cancel" width={16} height={16} />
          </div>)}
          {!!sharePosition && (
            <div
              className="ht-35 bg-semi-transparent-10 rounded-1 d-flex justify-content-center align-items-center cur-pointer me-2 px-2"
              onClick={sharePosition}
            >
              <img src={share} alt="share" width={16} height={16} />
              <span className="ms-2">{t('common.share')}</span>
            </div>
          )}
          {!!updatePosition && <PositionSettingsModal position={data} updatePosition={updatePosition} />}
        </div>)}
      </>)}
      {!isOpen && !!sharePosition && (
        <div className="pt-3 border-top border-semi-transparent">
          <div
            className="wd-100p card bg-semi-transparent-10 py-2 tx-13 d-flex flex-row justify-content-center align-items-center cur-pointer"
            onClick={sharePosition}
          >
            <img src={share} alt="share" width={16} height={16} className="me-2" />
            <span>{t('common.share')}</span>
          </div>
        </div>
      )}
    </div>
  )
});

export default TokenSnipeOrder;
