import React, { useMemo, useState } from 'react';
import IdentIcon from '@components/common/IndetIcon';
import { chainLogo } from '@helpers/chains';
import IconWithLoading from '@components/common/IconWithLoading';
import { ChainId } from '../../constants';
import TokenName from '@components/common/TokenName';
import solana from '@assets/images/solana.svg';

interface TokenLogoProps {
  address: string;
  logo?: string | null;
  name: string;
  size?: number;
  className?: string;
  containerClassName?: string;
  placeholderClassName?: string;
  chain?: string;
  useName?: boolean;
  hideChain?: boolean;
}

export default function TokenLogo(props: TokenLogoProps) {
  const {
    logo: logoUrl,
    address,
    name,
    size = 50,
    containerClassName = '',
    placeholderClassName = '',
    className = '',
    chain,
    useName = false,
    hideChain = false,
  } = props;
  const [dsError, setDsError] = useState(false);
  const [awsError, setAwsError] = useState(chain === ChainId.SOLANA);
  const [guruError, setGuruError] = useState(chain === ChainId.SOLANA);
  const [isError, setIsError] = useState(false);
  const [dsLoaded, setDsLoaded] = useState(false);
  const [awsLoaded, setAwsLoaded] = useState(false);
  const [guruLoaded, setGuruLoaded] = useState(false);
  const [rayLoaded, setRayLoaded] = useState(false);
  const urlAddress = (chain !== ChainId.TON && address.includes('-') ? address.split('-')[0] : address).toLowerCase();

  const logo = useMemo(() => {
    if (chain === ChainId.SOLANA && address === 'So11111111111111111111111111111111111111112') {
      return solana;
    }
    return logoUrl;
  }, [chain, address, logoUrl]);

  const logoLoaded = useMemo(() => {
    if (!dsError && dsLoaded) {
      return true;
    }
    if (!awsError && awsLoaded) {
      return true;
    }
    if (!guruError && guruLoaded) {
      return true;
    }
    if (!isError && rayLoaded) {
      return true;
    }
    return dsError || awsError || isError;
  }, [dsError, dsLoaded, awsError, awsLoaded, guruError, guruLoaded, rayLoaded]);

  const dsKey = useMemo(() => {
    return urlAddress.slice(-6);
  }, [urlAddress]);

  return (
    <div className={`pos-relative ${containerClassName}`}>
      <div
        className={`ms-0 wd-${size} mn-wd-${size} mx-wd-${size} ht-${size} rounded-50 overflow-hidden token-logo d-flex ${className}`}>
        <div
          className={`wd-100p ht-100p absolute-item pos-absolute z-index-10 d-flex align-items-center justify-content-center ${placeholderClassName}`}>
          <IconWithLoading isLoading={!logoLoaded} className="" />
          {isError && (<>
            {!useName ? <IdentIcon string={address.toLowerCase()} size={size} /> : <TokenName text={name} size={45} />}
          </>)}
        </div>
        {!dsError && (
          <img
            src={logo || `https://dd.dexscreener.com/ds-data/tokens/${(chain || '').toLowerCase()}/${urlAddress}.png?key=${dsKey}&size=lg`}
            alt={name}
            className={`rounded-50 z-index-50 ${dsLoaded ? 'd-block' : 'd-none'}`}
            onError={(currentTarget) => {
              // console.log('onError', currentTarget);
              // @ts-ignore
              currentTarget.onerror = null;
              setDsError(true);
            }}
            onLoad={() => setDsLoaded(true)}
          />
        )}
        {dsError && !awsError && (
          <img
            src={`https://token-icons.s3.amazonaws.com/${urlAddress}.png`}
            alt={name}
            className={`rounded-50 z-index-50 ${awsLoaded ? 'd-block' : 'd-none'}`}
            onError={(currentTarget) => {
              // console.log('onError', currentTarget);
              // @ts-ignore
              currentTarget.onerror = null;
              setAwsError(true);
            }}
            onLoad={() => setAwsLoaded(true)}
          />
        )}
        {awsError && !guruError && (
          <img
            src={`https://assets-stage.dex.guru/icons/${urlAddress}-${(chain || '').toLowerCase()}.png`}
            alt={name}
            className={`rounded-50 z-index-50 ${guruLoaded ? 'd-block' : 'd-none'}`}
            onError={(currentTarget) => {
              // console.log('onError', currentTarget);
              // @ts-ignore
              currentTarget.onerror = null;
              setGuruError(true);
            }}
            onLoad={() => setGuruLoaded(true)}
          />
        )}
        {dsError && guruError && !isError && (
          <img
            src={logo || `https://img.raydium.io/icon/${address}.png`}
            alt={name}
            className={`rounded-50 z-index-50 ${rayLoaded ? 'd-block' : 'd-none'}`}
            onError={(currentTarget) => {
              // console.log('onError', currentTarget);
              // @ts-ignore
              currentTarget.onerror = null;
              setIsError(true);
            }}
            onLoad={() => setRayLoaded(true)}
          />
        )}
      </div>
      {!hideChain && chain && (
        <div className="chain-label z-index-50">
          <img src={chainLogo(chain)} alt={chain} className="wd-20 ht-20" />
        </div>
      )}
    </div>
  );
}
