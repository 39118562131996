import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import CopyButton from '@components/common/CopyButton';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import useInterval from '@hooks/useInterval';
import { vibrate } from '@helpers/webApp';

interface SeedPhraseProps {
  isRegister?: boolean;
}

const SeedPhrase = observer(({ isRegister }: SeedPhraseProps) => {
  const threshold = 2000;
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const { mnemonic } = accountStore;
  const navigate = useNavigate();
  const phrase = mnemonic.toLowerCase().split(' ');
  const [reveal, setReveal] = useState<boolean>(false);
  const [touched, setTouched] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const seedPhrase = useMemo(() => {
    if (reveal) return phrase;
    return phrase.map((w) => w.replace(/./g, '•'));
  }, [reveal]);

  useEffect(() => {
    if (!touched || reveal) {
      setOffset(37.68);
    }
  }, [touched, reveal]);

  useInterval(() => {
    if (touched) {
      const progress = 100 - (100 - ((Date.now() - touched) / threshold) * 100);
      setOffset(6 * Math.PI * 2 * ((100 - progress) / 100));
      if (Date.now() - touched > threshold) {
        vibrate('heavy');
        setReveal(true);
        setOffset(37.68);
        setTouched(0);
      } else {
        vibrate('light');
      }
    }
  }, touched ? 100 : null);

  useInterval(() => {
    if (touched && !reveal) {
        vibrate('light');
    }
  }, touched ? 200 : null);

  return (
    <div className="tx-center pb-5">
      <div className="card mt-5">
        <div className="d-flex flex-wrap">
          {seedPhrase.map((word, index) => (
            <div className="wd-50p mb-2" key={`seed-phrase-${index}`}>
              <div
                className={`rounded border-1 border-solid border-muted-20 d-flex p-2 ${index % 2 === 0 ? 'me-1' : 'ms-1'}`}>
                <div className="tx-muted me-1">{index + 1}</div>
                <div>{word.replace('.', '').replace(',', '')}</div>
              </div>
            </div>
          ))}
        </div>

        <CopyButton text={mnemonic} className="btn btn-semi-10 wd-100p mt-2" noIcon>
          {t('common.copy')}
        </CopyButton>
      </div>

      <div className="tx-13 tx-muted my-4">
        {t('wallet.wallet-key-backup')}
      </div>

      {reveal && (
        <button
          className="btn btn-semi-10 wd-100p"
          onClick={() => setReveal(false)}
        >
          {t('common.hide')}
        </button>
      )}

      {!reveal && (
        <button
          className="btn btn-primary-10 wd-100p"
          onTouchStart={(e) => {
            e.preventDefault();
            setTouched(Date.now());
          }}
          onTouchEnd={(e) => {
            e.preventDefault();
            setTouched(0);
            setOffset(37.68);
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            setTouched(Date.now());
          }}
          onMouseUp={(e) => {
            e.preventDefault();
            setTouched(0);
            setOffset(37.68);
          }}
        >
          {touched > 0 && (
            <svg width="16" height="16" viewBox="0 0 16 16" style={{transform: 'rotate(-90deg)'}} className="me-1">
              <circle r="6" cx="8" cy="8" fill="transparent" stroke="rgba(0, 219, 255, 0.5)" strokeWidth="3px"></circle>
              <circle r="6" cx="8" cy="8" fill="transparent" stroke="#00DBFF" strokeLinecap="round"
                      strokeWidth="3px" strokeDasharray="37.68px" strokeDashoffset={`${offset}px`}></circle>
            </svg>
          )}
          {t('wallet.hold-to-reveal')}
        </button>
      )}

      {reveal && isRegister && (
        <button
          className="btn btn-primary-10 wd-100p mt-3"
          onClick={() => navigate(PageRoutes.WALLET_READY)}
        >
          {t('common.continue')}
        </button>
      )}
    </div>
  );
});

export default SeedPhrase;
