import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { WalletType } from '../../types/enums';
import plus from '@assets/whale-signals/plus.svg';
import { useTranslation } from 'react-i18next';

const NoSolanaWallet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="tx-center full-page d-flex flex-column full-page">
      <h1 className="tx-28 mt-auto">
        {t('wallet.no-wallet', { type: 'SOLANA' })}
      </h1>

      <div className="tx-muted my-3 px-4">
        {t('wallet.no-wallet-message', { type: 'SOLANA' })}
      </div>

      <div className="mt-auto mb-0">
        <button
          className="btn btn-primary wd-100p tx-17 mb-3"
          onClick={() => {
            navigate(PageRoutes.NEW_WALLET_CREATE, {
              state: { type: WalletType.SOL },
            });
          }}
        >
          {t('wallet.create-wallet')}
        </button>
        <button
          className="btn btn-semi-10 wd-100p tx-17 mb-3"
          onClick={() => {
            navigate(PageRoutes.NEW_WALLET_KEY_IMPORT, {
              state: { type: WalletType.SOL },
            });
          }}
        >
          <img src={plus} alt="Import" className="me-1" width={16} />
          {t('wallet.import-wallet')}
        </button>
        <button
          className="btn btn-outline wd-100p tx-17"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('common.cancel')}
        </button>
      </div>
    </div>
  );
};

export default NoSolanaWallet;
