import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import infoIcon from "@assets/whale-signals/info.svg";

/* eslint-disable react/prop-types */
const SignalAnomaly = ({signal}) => {
  const { t } = useTranslation();
  
  const tooltip = (
    <Tooltip id="signal-anomaly-tooltip">
      <div>
        🐳 {t('signal.big-players')}
      </div>
      <div>
        ⭐️ {t('signal.influencer-wallets')}
      </div>
      <div>
        🍀 {t('signal.new-holders')}
      </div>
      <div>{t('signal.quick-growth')}</div>
    </Tooltip>
  );

  return (
    <>
      <div className="d-block d-lg-none mt-3">
        <div className="tx-17 tx-semibold">{t('common.anomaly')}&nbsp;
          <OverlayTrigger overlay={tooltip}>
             <img src={infoIcon} alt={'Info'} width='16'/>
          </OverlayTrigger>
        </div>
        <div className="row g-2 g-sm-2">
          <div className="col-4 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 flex-row align-content-center justify-content-center">
              <div>
                🐳 {signal.whales}
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 flex-row align-content-center justify-content-center">
              <div>
                ⭐️ {signal.kyc}
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 flex-row align-content-center justify-content-center">
              <div>
                🍀 {signal.freshWallets}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalAnomaly;

