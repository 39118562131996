import React from 'react';
import { useTelegram } from '@hooks/useTelegram';

interface AlphaLinkProps {
  children: React.ReactNode;
  path?: string;
  className?: string;
}

const AlphaLink = ({ children, path, className = 'cur-pointer' }: AlphaLinkProps) => {
  const { webApp } = useTelegram();

  const handleClick = (e: any) => {
    if (webApp && path && path.toLowerCase().includes('t.me/')) {
      webApp.openTelegramLink(path);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <a href={path} onClick={handleClick} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default AlphaLink;
