import { MeResponse } from '../types/user/me.response';
import { LoginResponse } from '../types/user/login.response';
import {
  action,
  autorun,
  makeAutoObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import api, {
  ApiProps,
  apiRaw,
  ApiServer,
  axiosInstance,
  ResponseData,
} from '@helpers/api';
import { AxiosError } from 'axios';
import { ConfirmRegistrationResponse } from 'types/user/confirm-registration.response';
import * as Sentry from '@sentry/react';
import { ENV } from '../constants';
import { init as amplitudeInit } from '@amplitude/analytics-browser';

export class AuthStore {
  @observable
  private _accessToken: string | null = null;
  private _initData: string | null = null;
  private _refreshToken: string | null = null;
  private _userId: string | null = null;
  private _me?: MeResponse;

  constructor() {
    const cachedUserId = localStorage.getItem('userId');
    const cachedAccessToken = localStorage.getItem('token');
    const cachedRefreshToken = localStorage.getItem('refreshToken');

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) this._accessToken = null;
        return Promise.reject(error);
      },
    );

    makeAutoObservable(this);

    const { initData: tgInitData }: { initData: string } =
      //@ts-ignore
      window.Telegram.WebApp;
    const { token, force } = Object.fromEntries(
      new URLSearchParams(location.search),
    );

    reaction(
      () => this.accessToken,
      () => {
        if (this.accessToken) {
          axiosInstance.defaults.headers.common['Authorization'] =
            `Bearer ${this.accessToken}`;

          localStorage.setItem('token', this.accessToken);
        }
      },
    );

    reaction(
      () => this.refreshToken,
      () => {
        if (this.refreshToken) {
          localStorage.setItem('refreshToken', this.refreshToken);
        }
      },
    );

    reaction(
      () => !this.accessToken && !!(this.refreshToken || this.initData),
      async () => {
        if (!this.accessToken) await this.checkMe();
      },
    );

    reaction(
      () => this._userId,
      () => {
        this._userId && localStorage.setItem('userId', this._userId);

        !window.location.origin.includes('localhost') &&
          Sentry.init({
            dsn: 'https://547790eb834f4800cdeee9f2b899d4b1@o4507446582312960.ingest.de.sentry.io/4507446614687824',
            integrations: [
              Sentry.browserTracingIntegration(),
              Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
                networkDetailAllowUrls: [
                  window.location.origin,
                  `${ENV.API_URL}/api/v1`,
                ],
              }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              /^https:\/\/alpha-server\.pro/,
              /^https:\/\/stage\.wallet-ui\.pages\.dev/,
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });

        !window.location.origin.includes('localhost') &&
          amplitudeInit(
            'aaed9812a1e8e083bfee2bd1e1529cf6',
            String(localStorage.getItem('userId')),
            { defaultTracking: true },
          );
      },
    );

    autorun(() => {
      !!cachedUserId && (this._userId = cachedUserId);
      !!force && (this._accessToken = null);
      this._refreshToken = token || cachedRefreshToken;

      tgInitData && (this._initData = tgInitData);
      this._accessToken = cachedAccessToken;
    });
  }

  get isLogged() {
    return !!(
      this.accessToken &&
      (this.initData || this.refreshToken)
    );
  }

  get accessToken() {
    return this._accessToken;
  }

  get initData() {
    return this._initData;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  get isPinEnabled() {
    return !!this._me?.pinCodeEnabled;
  }

  get isActivated() {
    return this._me?.isActivated;
  }

  get userId() {
    return this._me?.userId;
  }

  get isBot() {
    return !!this._initData;
  }

  get apiAuthProps() {
    return (path: string, dataExt: Record<string, any> = {}): ApiProps =>
      this.isBot
        ? {
            method: 'post',
            path: `/bot${path}`,
            data: { ...dataExt, initData: this.initData },
          }
        : {
            method: 'post',
            path: `/user${path}`,
            data: { ...dataExt, refreshToken: this.refreshToken },
          };
  }

  @action
  async checkMe() {
    const request = this.apiAuthProps('/me');
    localStorage.removeItem('userId');

    return apiRaw<MeResponse>(request)
      .then(({ data }) => {
        this._me = data;
        this._userId = data.userId;

        if (data.pinCodeEnabled === false) this.login();
      })
      .catch((response) => {
        console.log('error me', response);
        this._me = undefined;
      });
  }

  @action
  async login(pin?: string) {
    const request = this.apiAuthProps('/login', { pin });

    return api<LoginResponse>(request)
      .then((response) => {
        if (response.accessToken) {
          this._accessToken = response.accessToken;
        }
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async register(pin: string, mnemonic?: string) {
    const request = this.apiAuthProps('/register/confirm', { mnemonic, pin });

    return api<ConfirmRegistrationResponse>(request)
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }
}
