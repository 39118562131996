import React, { useEffect, useRef, useState } from 'react';
import Preloader from '@components/common/Preloader';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import { useStores } from '@hooks/useStores';
import useResponseHandler from '@hooks/useResponseHandler';
import PinInput from '@components/common/PinInput';
import { observer } from 'mobx-react-lite';
import { WalletType } from '../../types/enums';
import { WalletResponse } from '../../types/wallet/wallet.response';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

interface CreateWalletProps {
  isImport?: boolean;
}

const CreateWallet = observer(({ isImport = false }: CreateWalletProps) => {
  const { t } = useTranslation();
  const { authStore, accountStore } = useStores();
  const { wallets } = accountStore;
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const inputRefSecond = useRef(null);
  const [pin, setPin] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [pinStarted, setPinStarted] = useState<boolean>(false);
  const [confirmPinStarted, setConfirmPinStarted] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const [w] = useAmplitude([AmplitudeEvent.PIN_PAGE_VIEWED]);

  const handleReset = () => {
    setPin('');
    setConfirmPin('');
    setPinStarted(false);
    setConfirmPinStarted(false);
    setError('');
  };

  const handleSubmit = () => {
    if (isImport) {
      navigate(PageRoutes.IMPORT_WALLET, { replace: true, state: { pin } });
      return;
    }

    authStore
      .register(pin)
      .then((response) => {
        if (response && response.userId) {
          navigate(PageRoutes.WALLET_CREATED, {
            replace: true,
            state: {
              pin,
              address: response.wallets.find(
                (w: WalletResponse) => w.type === WalletType.EVM,
              )?.address,
            },
          });
        } else {
          handleResponse(response.response, true);
        }
        handleReset();
      })
      .catch((e) => {
        console.error(e);
        setIsInvalid(true);
        handleResponse(e.response);
        handleReset();
      });
  };

  const handleComplete = (value: string) => {
    if (!pin) {
      w(setPin, AmplitudeEvent.PIN_ENTRY_COMPLETED, { isImport })(value);
      // clearPin();
    } else {
      if (pin !== value) {
        // clearPin();
        setIsInvalid(true);
        setPin('');
        w(setError, AmplitudeEvent.PIN_CONFIRMATION_ERROR, { isImport })(
          'PIN codes do not match',
        );
      } else {
        w(setConfirmPin, AmplitudeEvent.PIN_CONFIRMATION_COMPLETED, {
          isImport,
        })(value);
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (!isImport && confirmPin && pin === confirmPin && wallets.length) {
      navigate(PageRoutes.WALLET_CREATED, { replace: true });
    }
  }, [pin, confirmPin, wallets, isImport]);

  useEffect(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (inputRefSecond.current) {
      // @ts-ignore
      inputRefSecond.current?.focus();
    }
  }, [inputRefSecond]);

  if (confirmPin && pin === confirmPin) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
          text=""
        />
        <div className="tx-muted mt-3">
          Great! We are creating your wallet
          <br />
          «Alpha Wallet»
        </div>
      </div>
    );
  }

  return (
    <div className="tx-center full-page d-flex flex-column">
      <h1 className="tx-28 mt-5">
        {t(!pin ? 'registration.create-pin' : 'registration.repeat-pin')}
      </h1>

      <div className="tx-muted my-3">
        {t(
          !pin
            ? 'registration.create-pin-description'
            : 'registration.repeat-pin-description',
        )}
      </div>

      {isInvalid && !!error && (
        <div className="tx-bold tx-danger mb-2">{error}</div>
      )}
      <div className={`flex-1 ${isInvalid ? 'invalid-pin' : ''}`}>
        {!pin && (
          <PinInput
            length={4}
            onChange={() => {
              !pinStarted &&
                w(setPinStarted, AmplitudeEvent.PIN_ENTRY_STARTED, {
                  isImport,
                })(true);
              setIsInvalid(false);
            }}
            onComplete={handleComplete}
            isInvalid={isInvalid}
          />
        )}
        {!!pin && (
          <PinInput
            length={4}
            onChange={() => {
              !confirmPinStarted &&
                w(
                  setConfirmPinStarted,
                  AmplitudeEvent.PIN_CONFIRMATION_STARTED,
                  { isImport },
                )(true);
              setIsInvalid(false);
            }}
            onComplete={handleComplete}
            isInvalid={isInvalid}
          />
        )}
      </div>
    </div>
  );
});

export default CreateWallet;
