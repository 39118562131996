import React from 'react';
import { chainLogo, chainName } from '@helpers/chains';
import { OperationType } from '../../../types/transactions.types';
import TokenName from '@components/common/TokenName';
import TokenLogo from '@components/common/TokenLogo';

interface TokenLogoProps {
  type?: OperationType;
  size?: number;
  className?: string;
  containerClassName?: string;
  symbolIn?: string;
  symbolOut?: string;
  chain?: string;
  mainLogo?: string;
  secondLogo?: string;
  addressIn?: string;
  addressOut?: string;
  bg?: string;
  onClick?: () => void;
  isRobot?: boolean;
  index?: number | string;
}

export default function HistoryLogo(props: TokenLogoProps) {
  const {
    symbolIn,
    symbolOut,
    size = 45,
    containerClassName = '',
    className = 'tx-12',
    chain,
    type = OperationType.Approve,
    mainLogo,
    secondLogo,
    addressIn,
    addressOut,
    bg = 'bg-gray-800',
    onClick,
    isRobot,
    index,
  } = props;

  const isSend = type === OperationType.Send;
  const isTrade = type === OperationType.Trade;
  const isApprove = type === OperationType.Approve;
  const chainLogoImage = chainLogo(chain || '');
  const network = chainName(chain || '');

  return (
    <div className={`pos-relative ${containerClassName}`} onClick={onClick}>
      <div
        className={`ms-0 wd-${size} mn-wd-${size} mx-wd-${size} ht-${size} token-logo d-flex ${className} ${isTrade && 'token-logo--double'}`}>
        {(isSend || isTrade) && !secondLogo && !addressOut && !!symbolOut && (
          <TokenName text={symbolOut} size={size} bg={bg} className="token-logo--main-text" />
        )}
        {isTrade && !secondLogo && !!addressOut && (
          <TokenLogo address={addressOut} name={symbolOut || ''} size={size} chain={chain}
                     placeholderClassName="token-logo--main-text" hideChain useName />
        )}
        {(isTrade || isSend) && !!secondLogo && (
          <img src={secondLogo} alt={symbolIn || symbolOut} />
        )}
        {!!mainLogo && (
          <img src={mainLogo} alt={symbolOut || symbolIn || network} />
        )}
        {!mainLogo && !!addressIn && (
          <TokenLogo address={addressIn} name={symbolIn || ''} size={size} chain={chain}
                     placeholderClassName="token-logo--second-text" hideChain useName />
        )}
        {(isSend || isApprove) && !mainLogo && !symbolIn && (
          <img src={chainLogoImage} alt={network} />
        )}
        {!isSend && !mainLogo && !addressIn && (symbolIn || symbolOut) && (
          <TokenName text={symbolIn || symbolOut || ''} size={size} bg={bg} className="token-logo--second-text" />
        )}
      </div>
      {((isApprove && !mainLogo && chain) || (!isApprove && chain)) && (
        <div className={`chain-label z-index-100`}>
          <img src={chainLogoImage} alt={chain} className="wd-20 ht-20" />
        </div>
      )}
      {!!index && (
        <div className="left-label">
          {index}
        </div>
      )}
      {isRobot && (
        <div className="chain-label is-robot">
          🤖
        </div>
      )}
    </div>
  );
};
