import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import evm from '@assets/images/evm.svg';
import info from '@assets/icons/info-circle.svg';
import { chainLogo, chainName } from '@helpers/chains';
import { ChainId } from '../../../constants';

const WhatIsEvmModal = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const l = (language: string) => {
    if ('ru-RU'.includes(language)) return 'ru-RU';
    if ('en-EN'.includes(language)) return 'en-EN';
  };
  const [w] = useAmplitude();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const networks = (
    <div className="px-3 my-2 tx-14 tx-muted">
      <div className="d-flex align-items-center mb-1">
        <img src={chainLogo(ChainId.ETHER)} alt="eth" className="wd-20 me-2" />
        <span>{chainName(ChainId.ETHER)}</span>
      </div>
      <div className="d-flex align-items-center mb-1">
        <img src={chainLogo(ChainId.BASE)} alt="eth" className="wd-20 me-2" />
        <span>{chainName(ChainId.BASE)}</span>
      </div>
      <div className="d-flex align-items-center">
        <img src={chainLogo(ChainId.BSC)} alt="eth" className="wd-20 me-2" />
        <span>Binance Smart Chain (BSC)</span>
      </div>
    </div>
  );

  const desc: { [locale: string]: JSX.Element } = {
    'en-EN': (
      <>
        <div className="tx-18 tx-semibold tx-center mb-2">
          What is an EVM chains?
        </div>
        <div className="tx-14 tx-muted">
          The Ethereum Virtual Machine (EVM) is a runtime environment for smart contracts, supported by several
          blockchains. When you create a new wallet in an EVM network, it is automatically generated for the following
          networks:
        </div>
        {networks}
        <div className="tx-14 tx-muted">
          This allows you to manage assets in these networks from a single wallet. There is no need to create separate
          wallets for each network, as your single private key is compatible with all of them. This ensures convenience
          and security, allowing you to easily manage crypto assets across multiple networks simultaneously.
        </div>
      </>
    ),
    'ru-RU': (
      <>
        <div className="tx-18 tx-semibold tx-center mb-2">
          Что такое сети EVM?
        </div>
        <div className="tx-14 tx-muted">
          Ethereum Virtual Machine (EVM) - это среда выполнения для смарт-контрактов, поддерживаемая несколькими
          блокчейнами. При создании нового кошелька в сети EVM он автоматически генерируется для следующих сетей:
        </div>
        {networks}
        <div className="tx-14 tx-muted">
          Это позволяет управлять активами в этих сетях из одного кошелька. Нет необходимости создавать отдельные
          кошельки для каждой сети, так как ваш один приватный ключ совместим со всеми. Это обеспечивает удобство и
          безопасность, позволяя легко управлять криптоактивами на нескольких сетях одновременно.
        </div>
      </>
    ),
  };

  return (
    <div className="ms-auto"
         onClick={(e) => {
           e.stopPropagation();
         }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="pt-4">
          <div className="d-flex align-items-center justify-content-center mb-3">
            <div className="d-inline-block p-2 rounded-pill border-1 border-solid border-semi-transparent-10">
              <img src={evm} alt="evm" className="ht-30" />
            </div>
          </div>

          {desc[l(language) ?? 'en-EN']}

          <button
            className="btn btn-semi-10 wd-100p mt-4"
            onClick={handleClose}
          >
            {t('common.close')}
          </button>
        </Modal.Body>
      </Modal>

      <button
        className="btn btn-link p-0 ms-auto"
        onClick={(e) => {
          setShow(true);
          e.stopPropagation();
        }}
        id="evm-modal"
      >
        <img src={info} alt="info" className="wd-auto" height={24} />
      </button>
    </div>
  );
};

export default WhatIsEvmModal;
