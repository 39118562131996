import { createContext } from 'react';
import { AuthStore } from '@stores/auth-store';
import { AccountStore } from '@stores/account-store';
import { SettingsStore } from '@stores/settings-store';
import { SwapStore } from '@stores/swap-store';
import { OrdersStore } from '@stores/orders-store';
import { TokenStore } from '@stores/token-store';
import { TokenTrade } from '@stores/token-trade';

const authStore = new AuthStore();
const accountStore = new AccountStore(authStore);
const settingsStore = new SettingsStore(authStore);
const ordersStore = new OrdersStore(accountStore);
const tokenStore = new TokenStore(accountStore, ordersStore);
const tokenTrade = new TokenTrade(tokenStore, settingsStore);

export const storesContext = createContext({
  authStore,
  accountStore,
  settingsStore,
  swapStore: new SwapStore(),
  ordersStore,
  tokenStore,
  tokenTrade,
});
