import React from "react";
import { useTranslation } from "react-i18next";

const dollars = (number) => `$${number.toLocaleString()}`

/* eslint-disable react/prop-types */
const SignalMetrics = ({signal}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="metrics d-block">
        <div className="tx-17 tx-semibold">Metrics</div>
        <div className="grid">
          <div className="row g-2 g-md-2 g-sm-2">
            <div className="col-4 col-md-4 d-flex align-items-stretch">
              <div className="card bg-semi-transparent-10 px-2 py-1 d-flex flex-fill align-items-start">
                <div>
                  <div className="tx-muted tx-12">{t('common.mcap')}</div>
                  {dollars(signal.marketCapUsd)}
                </div>
              </div>
            </div>

            <div className="col-4 col-md-4 d-flex align-items-stretch ">
              <div className="card bg-semi-transparent-10 px-2 py-1 d-flex flex-fill align-items-start">
                <div>
                  <div className="tx-muted tx-12">{t('common.holders')}</div>
                  {signal.security.holderCount}
                </div>

              </div>
            </div>

            <div className="col-4 col-md-4 d-flex align-items-stretch ">
              <div className="card bg-semi-transparent-10 px-2 py-1 d-flex flex-fill align-items-start">
                <div>
                  <div className="tx-muted tx-12">{t('common.volume')}</div>
                  {dollars(Math.round(signal.volumeUsd))}
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default SignalMetrics;

