import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  ChainId,
  ethRegex,
  PageRoutes,
  solanaRegex,
  tonRegex,
  tronRegex,
} from '../../constants';
import useDebounce from '@hooks/useDebounce';
import { useStores } from '@hooks/useStores';
import FormattedNumber from '@components/common/FormattedNumber';
import BadgeIcon from '@components/common/BadgeIcon';
import Preloader from '@components/common/Preloader';
import useResponseHandler from '@hooks/useResponseHandler';
import NetworkSelection from '@components/NetworkSelection';
import { chainLogo, chainToShort } from '@helpers/chains';
import LimitOrdersSummary from '@pages/LimitOrders/components/LimitOrdersSummary';
import { GasResponse } from '../../types/common.types';
import searchIcon from '@assets/icons/search.svg';
import gasIcon from '@assets/icons/gas-white.svg';
import cross from '@assets/icons/cross.svg';
import quickTrade from '@assets/icons/quick-trade.svg';
import copytrade from '@assets/icons/copytrade.svg';
import { OrdersSummaryResponse } from '../../types/order/orders-summary.response';
import SummaryModal from '@pages/LimitOrders/components/SummaryModal';
import TradeWalletHeader from '@pages/LimitOrders/components/TradeWalletHeader';
import NonEvmChainLabel from '@components/NonEvmChainLabel';
import { useTranslation } from 'react-i18next';
import { hasOwnProperty } from '@helpers/object';
import { WalletType } from 'types/enums';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import NetworkSelectionPill from '@components/NetworkSelectionPill';
import Badge from '@components/common/Badge';
import { initUtils } from '@telegram-apps/sdk';

const LimitOrders = observer(() => {
  const { t } = useTranslation();
  const { accountStore, ordersStore, settingsStore } = useStores();
  const { refId, currentWallet } = accountStore;
  const { balance, summary, isEvm, isLoadingSummary, network } = ordersStore;
  const { analyticsBot } = settingsStore;
  const navigate = useNavigate();
  const [w] = useAmplitude();
  const utils = initUtils();

  const [isInit, setIsInit] = useState<boolean>(false);

  const [gas, setGas] = useState<GasResponse>();

  const [search, setSearch] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);

  const debounceSearch = useDebounce(search, 500);
  const handleResponse = useResponseHandler();
  const prevProfile = useRef<string | null>(null);

  const [selected, setSelected] = useState<OrdersSummaryResponse | null>(null);4

  const addressInputRef = useRef<HTMLInputElement>(null);

  const filteredSummary = useMemo(() => {
    if (!search) return null;
    const s = search.toLowerCase();
    return !search
      ? null
      : summary.filter((item) => {
          return (
            item.pairAddress.toLowerCase().includes(s) ||
            item.tokenName.toLowerCase().includes(s) ||
            item.tokenSymbol.toLowerCase().includes(s) ||
            item.tokenAddress.toLowerCase().includes(s)
          );
        });
  }, [summary, search]);

  const handleChainChange = (v: ChainId) => {
    setGas(undefined);
    ordersStore.setNetwork(v);
  };

  useEffect(() => {
    if (isEvm && !gas && network !== ChainId.ALL) {
      accountStore.getBlockchainGas(network).then((response) => {
        if (response) {
          setGas(response);
        }
      });
    }
  }, [isEvm, gas, network]);

  const addressType = useMemo(() => {
    if (filteredSummary?.length) return true;

    const typeToRx = {
      [WalletType.EVM]: ethRegex,
      [WalletType.SOL]: solanaRegex,
      [WalletType.TON]: tonRegex,
      [WalletType.TRON]: tronRegex,
    };

    const [type] = Object.entries(typeToRx).filter(([_, rx]) =>
      rx.test(search),
    );

    return search.length > 31 && search.length < 52 && type;
  }, [search, filteredSummary]);

  const loadSummary = (seamless = false) => {
    !balance && ordersStore.loadBalance();
    ordersStore
      .loadSummary(seamless)
      .then(() => {
        setIsInit(true);
      })
      .catch((e) => {
        setIsInit(true);
        console.error(e);
        handleResponse(e.response);
        setIsError(true);
      });
  };

  useEffect(() => {
    if (!isInit && ordersStore.isInit) {
      prevProfile.current = ordersStore.profile()?.id || null;
      loadSummary();
    }
  }, [isInit, ordersStore.isInit]);

  useEffect(() => {
    if (isInit) {
      loadSummary();
    }
  }, [currentWallet]);

  useEffect(() => {
    if (search) {
      setIsSearching(true);
    }
    setIsInvalid(false);
  }, [search]);

  useEffect(() => {
    if (debounceSearch && addressType && !filteredSummary?.length) {
      accountStore
        .getPairDetails(debounceSearch)
        .then((response) => {
          setIsSearching(false);
          if (
            response &&
            response.length &&
            response[0] &&
            hasOwnProperty(response[0], 'pairAddress')
          ) {
            w(navigate, AmplitudeEvent.TOKEN_SEARCH_SUCCESS, {
              token: response[0],
            })(
              `/limit-orders/${response[0].blockchain}/${response[0].pairAddress}/trade`,
              { state: { token: response[0] } },
            );
          } else {
            w(setIsInvalid, AmplitudeEvent.TOKEN_SEARCH_NOT_FOUND, {
              address: debounceSearch,
            })(true);
          }
        })
        .catch(() => {
          setIsInvalid(true);
          setIsSearching(false);
        });
    }
  }, [addressType, debounceSearch, filteredSummary]);

  useEffect(() => {
    const pasteHandler = (e: ClipboardEvent) => {
      const paste = e.clipboardData?.getData('text');
      if (paste && document.activeElement?.nodeName !== 'INPUT') {
        setSearch(paste);
      }
    };

    document.onpaste = pasteHandler;

    return () => {
      document.onpaste = null;
    };
  }, []);

  useEffect(() => {
    if (search && search === debounceSearch && !addressType) {
      w(() => null, AmplitudeEvent.TOKEN_SEARCH_INVALID_ADDRESS, { search })();
      setIsSearching(false);
      setIsInvalid(true);
    }
  }, [search, debounceSearch, addressType]);

  useEffect(() => {
    utils.readTextFromClipboard().then((data) => {
      console.log('Clipboard data:', data);
      // Output: string or null
    })
  }, []);

  // useEffect(() => {
  //   try {
  //     if (document.hasFocus()) {
  //       navigator.clipboard.readText().then((text) => {
  //         console.log('Pasted content 2:', text);
  //       });
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, []);

  return (
    <div className="pt-3 pb-nav">
      <div className="d-flex justify-content-between align-items-center wd-100p mb-3">
        <TradeWalletHeader className="flex-1" />
      </div>


      <div className="d-flex align-items-center justify-content-between gap-2">
        <NetworkSelectionPill />
        <div
          className={`flex-1 input-group rounded-2 ${isInvalid ? 'is-invalid' : ''}`}
        >
          <div
            className={`input-group-text py-0 ht-44 pe-2 ps-12-px border-0 ${isInvalid ? 'is-invalid' : 'tx-muted'}`}
          >
            <img src={searchIcon} alt="search" width={20} height={20} />
          </div>
          <input
            ref={addressInputRef}
            type="text"
            className={`form-control ht-44 ps-0 py-1 border-0 outline-none ${isInvalid ? 'tx-danger is-invalid' : 'tx-muted'}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('trading.token-or-pair-address')}
            onBlur={() => window.scrollTo(0, 0)}
          />
          {!!search && (
            <div className="input-group-text py-0 ht-44 pe-12-px ps-2 border-0">
              <img
                src={cross}
                alt="Clear"
                width={20}
                height={20}
                onClick={() => setSearch('')}
              />
            </div>
          )}
        </div>
      </div>

      <div className="d-flex gap-3 my-3">
        <div className="d-flex wd-100p">
          <button
            className="p-12-px wd-100p btn btn-semi-10 d-flex align-items-center justify-content-center gap-2 flex-column"
            onClick={() => navigate(PageRoutes.QUICK_TRADE)}
          >
            <img src={quickTrade} alt="Quick Trade" width={20} height={20} />
            <span className="tx-16 tx-normal">Quick trade</span>
          </button>
        </div>
        <div className="wd-100p">
          <button
            className="p-12-px btn btn-semi-10 wd-100p d-flex align-items-center justify-content-center gap-2 flex-column"
          >
            <span>
              <img
                className="opacity-02"
                src={copytrade}
                alt="Quick Trade"
                width={20}
                height={20}
              />
              <div
                style={{
                  width: 0,
                  height: 0,
                  transform: 'translate(45px, -35px)',
                }}
              >
                <Badge className="tx-10" label="Soon" />
              </div>
            </span>
            <span className="opacity-02 tx-16 tx-normal">Copytrading</span>
          </button>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between tx-13">
        <span className="tx-16 tx-semibold">Traded tokens</span>
        {!search && (
          <button
            className="btn ht-24 btn-link p-0 tx-primary text-decoration-none"
            onClick={() => navigate(PageRoutes.LIMIT_ORDERS_HIDDEN)}
          >
            {t('order.show-hidden')}
          </button>
        )}
      </div>
      {/* <div className="d-flex align-items-center justify-content-between tx-13 mt-1">
        {isEvm && (
          <div
            className={`${network === ChainId.ALL ? 'd-none' : 'd-flex'} justify-content-end align-items-center ms-auto me-0 tx-right`}
          >
            <img
              src={gasIcon}
              alt="gas"
              width={16}
              height={16}
              className="me-1"
            />
            <FormattedNumber value={gas?.gwei} postfix="GWEI" />
          </div>
        )}
      </div> */}

      {search && !filteredSummary?.length && isSearching && (
        <div className="tx-center pt-5">
          <Preloader
            inline
            iconSize={64}
            className="d-flex flex-column align-items-center"
            textClass="d-block tx-semibold tx-28 mt-4"
            iconClass="d-block"
            text={t('order.searching-token')}
          />
        </div>
      )}

      {search && !filteredSummary?.length && !isSearching && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">
            🔎
          </BadgeIcon>
          <h1 className="tx-28 mt-4">{t('order.empty-message')}</h1>
          <div className="tx-muted my-3">{t('common.nothing-found')}</div>
        </div>
      )}

      {!search && isError && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">
            🚫
          </BadgeIcon>
          <h1 className="tx-28 mt-4">{t('common.error')}</h1>
          <div className="tx-muted mt-2 mb-3">
            {t('common.something-went-wrong')}
          </div>
        </div>
      )}

      {!isError && (!search || !!filteredSummary?.length) && (
        <>
          {isLoadingSummary && (
            <div className="tx-center pt-5">
              <Preloader
                inline
                iconSize={64}
                className="d-flex flex-column align-items-center"
                textClass="d-block tx-semibold tx-28 mt-4"
                iconClass="d-block"
              />
            </div>
          )}
          {!isLoadingSummary && isInit && !summary.length && (
            <div className="d-flex flex-column align-items-center justify-content-center tx-center pt-5">
              <BadgeIcon badgeSize={85} className="tx-38 mb-2">
                📊
              </BadgeIcon>
              <div className="tx-semibold tx-22">
                {t('trading.no-trades-yet')}
              </div>
              <div className="tx-muted tx-17 px-4">
                {t('trading.find-first-gem')}
              </div>
            </div>
          )}
          {!isLoadingSummary && isInit && !!summary.length && (
            <div className="mt-3">
              {summary.map((item) => (
                <LimitOrdersSummary
                  key={`limit-orders-summary-${item.pairAddress}`}
                  data={item}
                  toggleSelected={() => setSelected(item)}
                  chartLink={settingsStore.getChartLink(
                    item.blockchain,
                    item.pairAddress,
                  )}
                  analyticsLink={`https://t.me/${analyticsBot}?start=${item.pairAddress}`}
                  isHidden={
                    !!filteredSummary && !filteredSummary.includes(item)
                  }
                  network={network}
                />
              ))}
            </div>
          )}
        </>
      )}

      <SummaryModal
        onClose={() => setSelected(null)}
        item={selected}
        refId={refId ?? undefined}
      />
    </div>
  );
});

export default LimitOrders;
