import React from "react";
import {formatNumber} from "@helpers/numbers";
import {isNumber} from "@helpers/bignumber";

interface LabelValueCardProps {
  label: string;
  value?: number | string;
  unit?: string;
  className?: string;
  valueClassName?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  borderBottom?: boolean;
  borderTop?: boolean;
  isSingle?: boolean;
}

const LabelValueCard = ({ label, value, unit, className = '', labelClassName = 'tx-14 tx-muted mb-1', valueClassName = '', isSingle, borderBottom, borderTop, children}: LabelValueCardProps) => {
  const formattedValue = value !== undefined && !children
    ? isNumber(value) ? formatNumber(value as number, unit) : value
    : children;

  return (
    <div
      className={`${isSingle ? 'card bg-semi-transparent-10' : ''} ${borderBottom ? 'pb-2 mb-2 border-bottom border-semi-transparent' : ''} ${borderTop ? 'pt-2 mt-2 border-bottom border-semi-transparent' : ''} ${className}`}>
      <div className={labelClassName}>{label}</div>
      <div className={`word-break ${valueClassName}`}>
        {formattedValue}
      </div>
    </div>
  );
};

export default LabelValueCard;
