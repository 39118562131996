import React from "react";
import {useNavigate} from "react-router-dom";
import BadgeIcon from "@components/common/BadgeIcon";
import {PageRoutes} from "../../constants";
import { useTranslation } from "react-i18next";

const GetSeedPhrase = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="tx-center">
      <BadgeIcon className="mt-5">
        <div className="tx-38 lh--1">🔒</div>
      </BadgeIcon>
      <h1 className="tx-28 mt-4">{t('wallet.get-seed-phrase')}</h1>

      <div className="tx-muted my-3">{t('common.read-warnings')}</div>

      <div className="card d-flex flex-row justify-content-start align-items-center tx-left mb-3">
        <div className="tx-24 lh--1 me-3">💾</div>
        <div>{t('common.save-seed-phrase')}</div>
      </div>
      <div className="card d-flex flex-row justify-content-start align-items-center tx-left mb-3">
        <div className="tx-24 lh--1 me-3">🤫</div>
        <div>{t('common.dont-share-seed-phrase')}</div>
      </div>
      <div className="card d-flex flex-row justify-content-start align-items-center tx-left mb-3">
        <div className="tx-24 lh--1 me-3">📱</div>
        <div>{t('common.no-seed-no-access')}</div>
      </div>

      <button
        className="btn btn-primary-10 wd-200"
        onClick={() => navigate(PageRoutes.SEED_PHRASE)}
      >
        {t('wallet.get-seed-phrase-button')}
      </button>
    </div>
  )
};

export default GetSeedPhrase;
