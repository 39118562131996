export enum OrderTrigger {
  PRICE_IN_USD = 'PRICE_IN_USD',
  PRICE_IN_BASE_TOKENS = 'PRICE_IN_BASE_TOKENS',
  MCAP = 'MCAP',
}

export enum OrderStatus {
  OPEN = 'OPEN',
  EXECUTING = 'EXECUTING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export enum OrderType {
  MARKET_BUY = 'MARKET_BUY',
  MARKET_SELL = 'MARKET_SELL',
  LIMIT_BUY = 'LIMIT_BUY',
  LIMIT_SELL = 'LIMIT_SELL',
  STOP_LOSS = 'STOP_LOSS',
}

export enum OrderTransactionType {
  BUY = 'BUY',
  SELL = 'SELL',
  APPROVE = 'APPROVE',
}

export enum OrderTransactionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  LOST = 'LOST',
}

export enum OrderVariant {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP_LOSS = 'STOP_LOSS',
}

export const ALL_ORDER_FILTERS = Object.values(OrderType);