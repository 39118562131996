import React, {useEffect, useState} from "react";
import Preloader from "@components/common/Preloader";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import PinInput from "@components/common/PinInput";
import {observer} from "mobx-react-lite";
import SeedPhrase from "@pages/Wallet/SeedPhrase";
import { useTranslation } from 'react-i18next';

const ExportSeedPhrase = observer(() => {
  const {t} = useTranslation();
  const { accountStore } = useStores();
  const { isPinEnabled } = accountStore;
  const [mnemonic, setMnemonic] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const handleResponse = useResponseHandler();

  useEffect(() => {
    if (!mnemonic && (isPinEnabled ? pin : true)) {
      accountStore
        .loadMnemonic(pin ?? undefined)
        .then((response) => {
          if (response && response?.mnemonic) {
            setMnemonic(response.mnemonic);
          } else {
            setIsInvalid(true);
            setPin('');
            setError(response.response.data.error);
          }
        })
        .catch((e) => {
          setPin('');
          setMnemonic('');
          setIsInvalid(true);
          handleResponse(e.response);
          setError('');
        });
    }
  }, [pin, mnemonic, isPinEnabled]);

  useEffect(() => {
    return () => {
      accountStore.resetMnemonic();
    }
  }, []);

  if (isPinEnabled && !pin) {
    return (
      <div className="tx-center full-page d-flex flex-column">
        <h1 className="tx-28 mt-5">
          PIN
        </h1>

        <div className="tx-muted my-3">
          {t('login.enter-pin')}
        </div>

        {isInvalid && !!error && (
          <div className="tx-bold tx-danger mb-2">{error}</div>
        )}

        <div className={`flex-1 ${isInvalid ? 'invalid-pin' : ''}`}>
          <PinInput
            length={4}
            onChange={() => setIsInvalid(false)}
            onComplete={setPin}
            isInvalid={isInvalid}
          />
        </div>
      </div>
    )
  }

  if (!mnemonic) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" />
      </div>
    )
  }

  return (
    <SeedPhrase />
  );
});

export default ExportSeedPhrase;
