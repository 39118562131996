import { action, autorun, makeAutoObservable, observable } from 'mobx';
import api, { ApiProps, apiRaw, ApiServer, ResponseData } from '@helpers/api';
import * as Sentry from '@sentry/react';
import { clearAuthTokens } from 'axios-jwt';
import {
  ApiAllowanceParams,
  ApiBlockchainTransaction,
  ApiCalculateGas,
  ApiConnectWallet,
  ApiGasPrice,
  ApiGetAllowanceParams,
  ApiQueue,
  ApiQueueParams,
  ApiSimulateParams,
  ApiSnipeSettings,
  ApiSwapParams,
  ApiTransactionHash,
  AssetsRaw,
  BlockchainTransaction,
  HotToken,
  IAsset,
  IFungible,
  ISnipeSettings,
  Mnemonic,
  OpenPositionParams,
  Portfolio,
  PortfolioRaw,
  PositionModel,
  QuickMode,
  TokenDetails,
  TransactionDetails,
  UpdatePinParams,
  WalletBalance,
} from '../types';
import { ApiWalletTransactions, ApiWalletTransactionsData } from '../types/transactions.types';
import { ChainId, zeroAddress } from '../constants';
import { allowedChains, chainToWallet } from '@helpers/chains';
import { Strategy, StrategyMainData, StrategyRaw } from '@pages/AutoTrade/constants';
import { WalletResponse } from '../types/wallet/wallet.response';
import { ResearchPairResponse } from '../types/research/research-pair.response';
import { GasResponse } from '../types/common.types';
import { TransferEstimateGasResponse } from '../types/trading/transfer-estimate-gas.response';
import { SendTokensRequest } from '../types/trading/send-tokens.request';
import { ReferralProfileResponse } from '../types/referral/referral-profile.response';
import { WalletType } from '../types/enums';
import { ExportWalletResponse } from '../types/wallet/export-wallet.response';
import { CreateWalletRequest } from '../types/wallet/create-wallet.request';
import { ImportWalletRequest } from '../types/wallet/import-wallet.request';
import { EstimateBridgeRouteRequest } from '../types/wallet/estimate-bridge-route.request';
import { EstimateBridgeRouteResponse } from '../types/wallet/estimate-bridge-route.response';
import { BridgeTokensRequest } from '../types/wallet/bridge-tokens.request';
import { BridgeTokensResponse } from '../types/wallet/bridge-tokens.response';
import clone from '@helpers/clone';
import { TopTokensFilter } from '../types/research/research.request';
import { InviteLink } from '../types/research/invite-links.response';
import { AxiosError } from 'axios';
import { BridgeRouteResponse } from '../types/wallet/bridge-routes.response';
import { AuthStore } from './auth-store';

export class AccountStore {
  private _authStore: AuthStore;

  @observable
  private _address: string | null = null;

  @observable
  private _redirectTo: string | null = null;

  @observable
  private _token: string | null = null;

  @observable
  private _pinEnabled: boolean | null = null;

  @observable
  private _wallets: WalletResponse[] = [];

  @observable
  private _currentWallet: WalletResponse | null = null;

  @observable
  private _portfolio: Portfolio | null = null;

  @observable
  private _assets: IAsset[] = [];

  @observable
  private _transactions: ApiWalletTransactionsData[] = [];

  @observable
  private _balance: WalletBalance | null = null;

  @observable
  private _network: ChainId = (localStorage.getItem('_network') as ChainId) ?? ChainId.ETHER;

  @observable
  private _connectWallet: ApiConnectWallet | null = null;

  @observable
  private _snipeSettings: ApiSnipeSettings | null = null;

  @observable
  private _isLoggedIn = false;

  @observable
  private _checkDone = 0;

  @observable
  private _isAppInitialized = false;

  @observable
  private _mnemonic = '';

  @observable
  private _assetsLoaded = false;

  @observable
  private _transactionsLoaded = false;

  @observable
  private _selectedWalletGroup: WalletType | 'all' | null = 'all';

  @observable
  private _quickTradeSettings: QuickMode[] = [];

  @observable
  private _quickTradeSettingsLoaded: boolean = false;

  @action
  public loadAccountInfo() {
    if (!this.isLoggedIn) {
      return;
    }

    if (!this._wallets.length) {
      this.loadUser();
    }
  }

  @observable
  private _refId: string | null = null;

  constructor(authStore: AuthStore) {
    this._authStore = authStore;

    makeAutoObservable(this);

    autorun(() => {
      if (this._authStore.accessToken) {
        this._isLoggedIn = true;
        this.loadAccountInfo();
      }
    });
  }

  @action
  logout(force?: boolean) {
    this.resetStore(!force);
  }

  @action
  setAddress(value: string | null) {
    this._address = value;
  }

  get redirectTo() {
    return this._redirectTo;
  }

  @action
  setRedirectTo(value: string | null) {
    this._redirectTo = value;
  }

  get address() {
    return this.currentWallet?.address || this._address || zeroAddress;
  }

  get isEvm() {
    return this.currentWallet?.type === WalletType.EVM;
  }

  get isSolana() {
    return this.currentWallet?.type === WalletType.SOL;
  }

  get isTon() {
    return this.currentWallet?.type === WalletType.TON;
  }

  get isTron() {
    return this.currentWallet?.type === WalletType.TRON;
  }

  get currentWalletType() {
    return this.currentWallet?.type;
  }

  get initData() {
    return this._authStore.initData;
  }

  get isBot() {
    return !!this.initData;
  }

  get token() {
    return this._token;
  }

  get lockedToken() {
    return this._authStore.refreshToken;
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }

  get isPinEnabled() {
    return this._pinEnabled;
  }

  get wallets() {
    return this._wallets;
  }

  get existingWalletTypes() {
    const order = [
      WalletType.EVM,
      WalletType.SOL,
      WalletType.TRON,
      WalletType.TON,
    ];
    return new Set(
      this._wallets
        .map(({ type }) => type)
        .sort((a, b) => {
          return order.indexOf(a) - order.indexOf(b);
        }),
    );
  }

  get currentWallet() {
    return this._currentWallet;
  }

  get walletsByType() {
    return this._wallets.filter((w) => w.type === this.currentWallet?.type);
  }

  @action
  setCurrentWallet(value: WalletResponse | null) {
    this._currentWallet = value;
    if (value) {
      localStorage.setItem('currentWallet', value.id);
    } else {
      localStorage.removeItem('currentWallet');
    }
  }

  @action
  setCurrentWalletById(value: string) {
    const found = this._wallets.find((w) => w.id === value);
    if (found) {
      this.setCurrentWallet(found);
    }
  }

  get portfolio() {
    return this._portfolio;
  }

  get balance() {
    return this._balance;
  }

  get checkDone() {
    return this._checkDone;
  }

  get isAppInitialized() {
    return this._isAppInitialized;
  }

  @action
  setAppInitialized(value: boolean) {
    this._isAppInitialized = value;
  }

  get mnemonic() {
    return this._mnemonic;
  }

  @action
  resetMnemonic() {
    this._mnemonic = '';
  }

  get assets() {
    return this._assets.filter(
      (asset) =>
        asset.flags.displayable === true ||
        asset.flags.displayable === undefined,
    );
  }

  get transactions() {
    try {
      return this._transactions.filter((t) =>
        this.isSolana
          ? t.chain === ChainId.SOLANA
          : allowedChains.includes(t.chain),
      );
    } catch {
      return [];
    }
  }

  get assetsLoaded() {
    return this._assetsLoaded;
  }

  get transactionsLoaded() {
    return this._transactionsLoaded;
  }

  get connectWalletInfo() {
    return this._connectWallet;
  }

  get snipeSettings() {
    return this._snipeSettings;
  }

  get selectedWalletGroup() {
    return this._selectedWalletGroup;
  }

  get network() {
    if (this.isTon) {
      return ChainId.TON;
    }
    if (this.isSolana) {
      return ChainId.SOLANA;
    }
    if (this.isTron) {
      return ChainId.TRON;
    }
    return this._network;
  }

  get apiAuthProps() {
    return (path: string, dataExt: Record<string, any> = {}): ApiProps =>
      this.isBot
        ? {
          method: 'post',
          path: `/bot${path}`,
          data: { ...dataExt, initData: this.initData },
        }
        : {
          method: 'post',
          path: `/user${path}`,
          data: { ...dataExt, refreshToken: this.lockedToken },
        };
  }

  get refId() {
    return this._refId;
  }

  @action
  setNetwork(value: ChainId) {
    const chainWallet = chainToWallet(value);
    if (this._currentWallet?.type !== chainWallet) {
      const found = this._wallets.find((w) => w.type === chainToWallet(value));
      if (found) {
        this.setCurrentWallet(found);
      }
    }
    this._network = value;
    localStorage.setItem('_network', value);
  }

  @action
  setSelectedWalletGroup(value: WalletType | 'all') {
    this._selectedWalletGroup = value;
  }

  @action
  async loadData() {
    this.loadPortfolio();
    this.loadAssets();
    return this.loadTransactions();
    // this.loadSnipeSettings();
  }

  @action
  async loadUser() {
    if (!this.isLoggedIn) return;

    this.getQuickTradeSettings();

    return apiRaw<WalletResponse[]>({ method: 'get', path: '/wallet' })
      .then((response) => {
        this._wallets = response.data || this._wallets || [];
        if (!this._currentWallet) {
          const lsWallet = localStorage.getItem('currentWallet');
          const evmWallet = this._wallets.find(
            (w) => w.type === WalletType.EVM,
          );
          if (lsWallet) {
            this.setCurrentWallet(
              response.data?.find((w) => w.id === lsWallet) || evmWallet!,
            );
          } else {
            this.setCurrentWallet(evmWallet!);
          }
          if (!response.data.find((w) => w.type === WalletType.TON)) {
            this.createWallet(WalletType.TON).then((r) => {
              if (r && r.id) {
                this.loadUser();
              }
            });
          }
          if (!response.data.find((w) => w.type === WalletType.TRON)) {
            this.createWallet(WalletType.TRON).then((r) => {
              if (r && r.id) {
                this.loadUser();
              }
            });
          }
        } else {
          this.setCurrentWallet(
            response.data?.find((w) => w.id === this._currentWallet?.id) ||
            this._currentWallet,
          );
        }
        this._checkDone = response.status;
        this._isLoggedIn = true;
        if (response.status && response.data) {
          this.getReferralData();
        }
        return response;
      })
      .catch((response) => {
        this._checkDone = response.response.status;
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout(this.isBot);
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async loadBalance() {
    if (!this.currentWallet) return;

    this._balance = null;

    return apiRaw<WalletBalance>({
      method: 'get',
      path: `/balance/native`,
      data: {
        walletId: this.currentWallet.id,
        blockchain: this.network === ChainId.ALL ? ChainId.ETHER : this.network,
      },
    })
      .then((response) => {
        this._balance = response.data || null;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          // console.error(response.response.data);
          Sentry.captureEvent(
            { message: 'Wallet ID Error' },
            { data: { response, source: 'account-store' } },
          );
        } else {
          console.error(response);
        }
      });
  }

  @action
  async loadPortfolio() {
    const data = { walletType: this.currentWallet?.type };
    return apiRaw<PortfolioRaw>({
      method: 'get',
      path: `/portfolio/${this.address}`,
      data,
    })
      .then((response) => {
        this._portfolio = response.data?.portfolio || null;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
      });
  }

  @action
  async loadAssets() {
    this._assetsLoaded = false;
    const data = { walletType: this.currentWallet?.type };
    return apiRaw<AssetsRaw>({
      method: 'get',
      path: `/portfolio/${this.address}/positions`,
      data,
    })
      .then((response) => {
        this._assets = clone(response.data?.positions) || [];
        this._assetsLoaded = true;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
      });
  }

  @action
  async loadFungible(fungibleId: string) {
    const data = { walletType: this.currentWallet?.type };
    return api<IFungible>({
      method: 'get',
      path: `/portfolio/${this.address}/fungible/${fungibleId}`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async getTransactions({
                          fungibleId,
                          chainId,
                        }: { fungibleId?: string; chainId?: ChainId } = {}) {
    const data: Record<string, typeof fungibleId> = {
      walletType: this.currentWallet?.type,
    };
    if (fungibleId) {
      data['filter[fungible_ids]'] = fungibleId;
    }
    if (chainId) {
      data['filter[chain_ids]'] = chainId;
    }
    return api<ApiWalletTransactions>({
      method: 'get',
      path: `/portfolio/${this.address}/transactions`,
      data,
    })
      .then((response) => {
        return response?.transactions || [];
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async getBlockchainTransactions(walletAddress: string) {
    return api<BlockchainTransaction[]>({
      method: 'get',
      path: `/blockchain/transactions/${walletAddress}`,
    })
      .then((response) => {
        return response || [];
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async getBlockchainGas(network: string = ChainId.ETHER) {
    return api<GasResponse>({
      method: 'get',
      path: `/gas`,
      data: {
        blockchain: network.toUpperCase(),
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async calculateGas(data: ApiCalculateGas) {
    return api<TransferEstimateGasResponse>({
      method: 'get',
      path: `/gas/estimate/transfer`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async calculateAllowanceGas(data: ApiSimulateParams) {
    return api<ApiGasPrice>({
      method: 'post',
      path: `/token/allowance/simulate`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getQueue(data: ApiQueueParams) {
    return api<ApiQueue>({
      method: 'get',
      path: `/token/quote`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getAllowance(data: ApiGetAllowanceParams) {
    return api<{ allowance: string }>({
      method: 'get',
      path: `/token/allowance`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async setAllowance(data: ApiAllowanceParams) {
    return api<{ transactionHash: string }>({
      method: 'post',
      path: `/token/allowance`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async sendToken(data: SendTokensRequest) {
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/token/send`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async swapTokens(data: ApiSwapParams) {
    return api<ApiTransactionHash>({
      method: 'post',
      path: `/token/swap`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getBlockchainTransaction(transactionHash: string, network: ChainId) {
    return api<TransactionDetails>({
      method: 'get',
      path: `/blockchain/transaction/${network}/${transactionHash}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getApiTransaction(transactionHash: string) {
    return api<ApiBlockchainTransaction>({
      method: 'get',
      path: `/blockchain/transaction/${transactionHash}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getTokenDetails(address: string) {
    return api<TokenDetails>({
      method: 'get',
      path: `/token/details/${address}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getPosition(positionId: string) {
    return api<PositionModel>({
      method: 'get',
      path: `/snipe-engine/position/${positionId}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getSnipePositions() {
    return api<PositionModel[]>({
      method: 'get',
      path: `/snipe-engine/positions`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getHotTokensDeprecated() {
    return api<ResponseData<HotToken[]>>(
      {
        method: 'get',
        path: `/pairs/top`,
      },
      ApiServer.DEX,
    )
      .then((response) => {
        return response.data || [];
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getHotTokens(blockchain: ChainId = ChainId.ETHER) {
    // if (blockchain === ChainId.ETHER) {
    //   return this.getHotTokensDeprecated();
    // }

    return api<ResponseData<HotToken[]>>({
      method: 'get',
      path: `/research/tokens`,
      data: {
        blockchain,
        filter: TopTokensFilter.TRENDING_POOLS,
      },
    })
      .then((response) => {
        return response || [];
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async openPosition(data: OpenPositionParams) {
    if (!this.currentWallet) return;

    return api<PositionModel>({
      method: 'post',
      path: `/snipe-engine/position`,
      data: {
        ...data,
        walletId: this.currentWallet!.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async updatePosition(id: string, data: ISnipeSettings) {
    return api<PositionModel>({
      method: 'post',
      path: `/snipe-engine/position/${id}`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async sellPosition(id: string, amount: number) {
    return apiRaw<undefined>({
      method: 'post',
      path: `/snipe-engine/position/${id}/sell`,
      data: {
        amount,
      },
    })
      .then((response) => {
        return response.status === 204;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async cancelPosition(id: string) {
    return apiRaw<undefined>({
      method: 'post',
      path: `/snipe-engine/position/${id}/cancel`,
    })
      .then((response) => {
        return response.status === 204;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async searchTokens(query: string) {
    const data: any = {};
    data['filter[search_query]'] = query;
    return api<IAsset[]>({
      method: 'get',
      path: `/token/search`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async getReferralData() {
    return api<ReferralProfileResponse>({
      method: 'get',
      path: `/referral/profile`,
    })
      .then((response) => {
        this._refId = response.refCode;

        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async claimReferral(refWalletId: string, walletId: string) {
    return api<{ txHash: string; claimTxId: string }>({
      method: 'post',
      path: `/referral/reward/claim`,
      data: {
        refWalletId,
        walletId,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async loadConnectWalletInfo() {
    api<ApiConnectWallet>({
      method: 'get',
      path: `/wallet/connect`,
    })
      .then((response) => {
        if (response && response?.addresses?.length) {
          this._connectWallet = response;
        }
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async loadSnipeSettings() {
    api<ApiSnipeSettings>({
      method: 'get',
      path: `/snipe-engine/position/settings`,
    })
      .then((response) => {
        if (response && response?.label) {
          this._snipeSettings = response;
        }
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async saveSnipeSettings(data: ISnipeSettings) {
    return api<ApiSnipeSettings>({
      method: 'put',
      path: `/snipe-engine/position/settings`,
      data,
    })
      .then((response) => {
        if (response && response?.label) {
          this._snipeSettings = response;
        }
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async loadMnemonic(pinCode?: string) {
    const data = pinCode ? { pinCode } : undefined;
    return api<Mnemonic>({
      method: 'get',
      path: `/wallet/seed`,
      data,
    })
      .then((response) => {
        if (response && response?.mnemonic) {
          this._mnemonic = response.mnemonic;
        }
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async loadPrivateKey() {
    return api<ExportWalletResponse>({
      method: 'get',
      path: `/wallet/export`,
      data: {
        walletId: this.currentWallet?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async createWallet(type: WalletType, name?: string) {
    const data: CreateWalletRequest = { type };

    if (name) {
      data.name = name;
    }

    return api<WalletResponse>({
      method: 'post',
      path: `/wallet/create`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async importWallet(
    type: WalletType,
    privateKey: string,
    name = 'Imported Wallet',
  ) {
    const data: ImportWalletRequest = { type, privateKey, name };

    return api<WalletResponse>({
      method: 'post',
      path: `/wallet/import`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async loadTransactions() {
    this._transactionsLoaded = false;
    Promise.all([this.getTransactions()])
      .then((response) => {
        this._transactions = response[0];
        this._transactionsLoaded = true;
      })
      .catch(() => {
        this._transactionsLoaded = true;
      });
  }

  @action
  setPinEnabled(value: string | null) {
    const v = !['null', 'false'].includes(String(value));
    localStorage.setItem('pinEnabled', v.toString());
    this._pinEnabled = !['null', 'false'].includes(v.toString());
  }

  @action
  async updatePin(data: UpdatePinParams) {
    return api<null>({
      method: 'patch',
      path: `/user/pin`,
      data,
    })
      .then((response) => {
        this.setPinEnabled(data.pinCode);
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async updateWalletName(name: string) {
    return apiRaw<WalletResponse>({
      method: 'post',
      path: `/wallet/update`,
      data: {
        name,
        walletId: this.currentWallet?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async deleteWallet() {
    return apiRaw<WalletResponse>({
      method: 'post',
      path: `/wallet/delete`,
      data: {
        userId: this.lockedToken,
        walletId: this.currentWallet?.id,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response;
      });
  }

  @action
  async getStrategyList() {
    return api<Strategy[]>({
      method: 'get',
      path: `/strategy`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getStrategyItem(id: string) {
    return api<Strategy>({
      method: 'get',
      path: `/strategy/${id}`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async createStrategy(data: StrategyRaw) {
    return api<Strategy>({
      method: 'post',
      path: `/strategy`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async updateStrategy(id: string, data: StrategyMainData) {
    return api<Strategy>({
      method: 'patch',
      path: `/strategy/${id}`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async deleteStrategy(id: string) {
    return apiRaw<undefined>({
      method: 'delete',
      path: `/strategy/${id}`,
    })
      .then((response) => {
        return response.status === 204;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async getPairDetails(address: string) {
    return api<ResearchPairResponse[]>({
      method: 'get',
      path: `/pair/search`,
      data: {
        q: address,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async estimateBridge(data: EstimateBridgeRouteRequest) {
    return api<EstimateBridgeRouteResponse[]>({
      method: 'get',
      path: `/bridge/route/estimate`,
      data,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async bridgeRoutes(blockchain: ChainId) {
    return api<BridgeRouteResponse[]>({
      method: 'get',
      path: `/bridge/route`,
      data: {
        blockchain,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async confirmBridge(data: BridgeTokensRequest) {
    return apiRaw<BridgeTokensResponse>({
      method: 'post',
      path: `/bridge`,
      data,
    })
      .then((response) => {
        if (response.status === 201 && response.data) {
          return response.data as unknown as BridgeTokensResponse;
        } else if (response.status === 401) {
          this.logout();
        }
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
      });
  }

  @action
  async getResearchInviteLinks() {
    return api<InviteLink[]>({
      method: 'get',
      path: `/research/invite-links`,
    })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  get quickTradeSettings() {
    return this._quickTradeSettings;
  }

  get quickTradeSettingsLoaded() {
    return this._quickTradeSettingsLoaded;
  }

  @action
  async getQuickTradeSettings() {
    return api<QuickMode[]>({
      method: 'get',
      path: `/quick-mode`,
    })
      .then((response) => {
        this._quickTradeSettings = response;
        this._quickTradeSettingsLoaded = true;
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async createQuickTradeSettings(data: QuickMode) {
    return api<QuickMode[]>({
      method: 'post',
      path: `/quick-mode`,
      data,
    })
      .then((response) => {
        this._quickTradeSettings = response;
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async updateQuickTradeSettings(data: QuickMode) {
    const method = !this._quickTradeSettings.find((s) => s.blockchain === data.blockchain) ? 'post' : 'patch';
    return api<QuickMode[]>({
      method,
      path: `/quick-mode`,
      data,
    })
      .then((response) => {
        this._quickTradeSettings = response;
        return response;
      })
      .catch((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === 401
        ) {
          this.logout();
        }
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  resetStore(soft = false) {
    if (!soft) {
      localStorage.removeItem('refreshToken');
    }
    localStorage.removeItem('token');
    clearAuthTokens();
    this._wallets = [];
    this._address = null;
    this._isLoggedIn = false;
    this._pinEnabled = null;
    this._token = null;
  }
}
