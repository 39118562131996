import React from "react";
import { observer } from "mobx-react-lite";
import SideNavItem from "@pages/Wallet/SideNavItem";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import russian from "@assets/images/flags/russian.svg";
import english from "@assets/images/flags/english.svg";
import chinese from "@assets/images/flags/chinese.svg";
import lang from "@assets/images/language.svg";

enum Locale {
  RU = 'ru-RU',
  EN = 'en-EN',
  CN = 'cn-CN',
}

const Language = observer(() => {
  const { t, i18n: {changeLanguage, language} } = useTranslation();
  const navigate = useNavigate()

  const changeLanguageHandler = (locale: Locale) => {
    changeLanguage(locale);
    navigate(-1)
  }

  return (
    
    <div className="tx-left full-page d-flex flex-column">
      <div className="tx-28 tx-semibold my-3 d-flex align-items-center">
        <img src={lang} alt={t('common.language')} className="wd-25 me-2" />
        <span>{t('common.language')}</span>
      </div>
      <div className="card p-0 mb-4 overflow-hidden">
        <SideNavItem
          key={`lang-nav-item-${Locale.RU}`}
          icon={russian}
          title="Русский"
          noIconBg
          checked={language === Locale.RU}
          onClick={() => changeLanguageHandler(Locale.RU)}
        />
        <SideNavItem
          key={`lang-nav-item-${Locale.EN}`}
          icon={english}
          title="English"
          noIconBg
          checked={language === Locale.EN}
          onClick={() => changeLanguageHandler(Locale.EN)}
          />
        <SideNavItem
          key={`lang-nav-item-${Locale.CN}`}
          icon={chinese}
          title="中国人"
          noIconBg
          isLast={true}
          checked={language === Locale.CN}
          onClick={() => changeLanguageHandler(Locale.CN)}
        />
      </div>
    </div>
  );
});

export default Language;
