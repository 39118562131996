import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import { chainLogo, chainToken, chainTxExplorer } from '@helpers/chains';
import { getEllipsisTxt } from '@helpers/formatters';
import { ChainId, PageRoutes, zeroAddress } from '../../constants';
import Preloader from '@components/common/Preloader';
import BadgeIcon from '@components/common/BadgeIcon';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import useResponseHandler from '@hooks/useResponseHandler';
import { BaseToken, Blockchain, WalletType } from '../../types/enums';
import { EstimateBridgeRouteResponse } from '../../types/wallet/estimate-bridge-route.response';
import UserAvatar from '@components/common/UserAvatar';
import swap from '@assets/images/chains/swap.svg';
import { BridgeTokensRequest } from '../../types/wallet/bridge-tokens.request';
import { useTranslation } from 'react-i18next';
import { hasOwnProperty } from '@helpers/object';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { BridgeTokensResponse } from 'types/wallet/bridge-tokens.response';

interface RouteParams {
  network: ChainId;
  networkReceive: ChainId;
  bridge: EstimateBridgeRouteResponse;
  walletToId: string;
}

const BridgeConfirm = observer(() => {
  const { accountStore } = useStores();
  const { assets, currentWallet, wallets } = accountStore;
  const navigate = useNavigate();
  const location = useLocation();
  const [w] = useAmplitude();
  const { network, networkReceive, bridge, walletToId } =
    (location.state as RouteParams) || {};
  const [txHash, setTxHash] = useState<string>('');
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const { t } = useTranslation();

  const networkTokenLogo = useCallback((n: ChainId) => {
    const ethChains = [ChainId.ETHER, ChainId.BASE, ChainId.ARBITRUM];
    if (ethChains.includes(n)) {
      return chainLogo(ChainId.ETHER);
    }
    return chainLogo(n);
  }, []);

  const walletTo = useMemo(() => {
    return wallets.find((wallet) => wallet.id === walletToId);
  }, [walletToId, wallets]);

  const networkTokens = useMemo(() => {
    return assets.filter((asset) => asset.assetChain === network);
  }, [assets, network]);

  const networkReceiveTokens = useMemo(() => {
    return assets.filter((asset) => asset.assetChain === networkReceive);
  }, [assets, networkReceive]);

  const nativeToken = useMemo(() => {
    const networkToken = chainToken(network);
    return networkTokens.find(
      (asset) => asset.assetSymbol.toLowerCase() === networkToken.toLowerCase(),
    );
  }, [networkTokens, network]);

  const nativeTokenReceive = useMemo(() => {
    const networkToken = chainToken(networkReceive);
    return networkReceiveTokens.find(
      (asset) => asset.assetSymbol.toLowerCase() === networkToken.toLowerCase(),
    );
  }, [networkReceiveTokens, network]);

  const totalFee = useMemo(() => {
    if (!bridge) return 0;
    return new BigNumber(bridge.estimateGasFee)
      .plus(bridge.bridgeFee)
      .toNumber();
  }, [bridge]);

  const totalAmount = useMemo(() => {
    if (!bridge) return 0;
    return new BigNumber(totalFee).plus(bridge.amountIn).toNumber();
  }, [bridge]);

  const totalFeeUsd = useMemo(() => {
    if (!bridge) return 0;
    return new BigNumber(bridge.estimateGasFeeInUsd)
      .plus(bridge.bridgeFeeInUsd)
      .toNumber();
  }, [bridge]);

  const isInterType = useMemo(() => {
    return network === ChainId.SOLANA || networkReceive === ChainId.SOLANA;
  }, [network, networkReceive]);

  const handleSubmit = () => {
    if (!network || !networkReceive || !bridge) {
      return;
    }
    setIsProcessing(true);
    const data: BridgeTokensRequest = {
      bridge: bridge.bridge,
      value: bridge.amountIn,
      fromBlockchain: network as unknown as Blockchain,
      toBlockchain: networkReceive as unknown as Blockchain,
      fromToken: nativeToken?.assetSymbol as unknown as BaseToken,
      toToken: chainToken(networkReceive) as unknown as BaseToken,
      fromWalletId: currentWallet!.id,
    };
    if (isInterType && walletToId) {
      data.toWalletId = walletToId;
    }
    w(accountStore.confirmBridge, AmplitudeEvent.BRIDGE_CONFIRM_CLICKED, {
      amount: data.value,
    })(data)
      .then((response: BridgeTokensResponse) => {
        if (response?.transactionHash) {
          setTxHash(response.transactionHash);
          setIsProcessing(false);
          setIsSent(true);
        } else {
          setIsProcessing(false);
          console.log(t('bridge.bridge-failed'), response);
          if (response) {
            // @ts-ignore
            handleResponse(response, true);
          } else {
            handleResponse(t('bridge.bridge-failed'), true);
          }
        }
      })
      .catch((error: any) => {
        setIsProcessing(false);
        setIsSent(false);
        handleResponse(error, true);
        navigate(-1);
      });
  };

  if (isProcessing) {
    return (
      <div className="py-4 full-page align-items-start justify-content-center">
        <Preloader
          className="d-flex flex-column align-items-center justify-content-center ht-100p tx-center"
          iconSize={65}
          textClass="mt-3"
          text={t('common.confirming')}
          inline
        />
      </div>
    );
  }

  if (isSent && !isProcessing) {
    return (
      <div className="full-page d-flex flex-column align-items-center justify-content-center tx-center ht-100p">
        <BadgeIcon badgeSize={110} className="tx-64 mb-3">
          ⌛️
        </BadgeIcon>

        <div className="tx-semibold tx-warning tx-28">
          <FormattedNumber
            suffix="+"
            value={bridge.amountOut}
            postfix={chainToken(networkReceive)}
            decimals={5}
            className="d-block"
          />
          <div>{t('bridge.bridge-in-progress')}</div>
        </div>

        <div className="tx-muted my-3">
          {t('bridge.sending-process-duration')}
        </div>

        <button
          className="btn btn-primary-10 wd-230 my-4"
          onClick={() => navigate(PageRoutes.WALLET)}
        >
          {t('common.done')}
        </button>

        <div className="tx-13 tx-muted">
          {t('bridge.check-tx-on')}
          <a
            href={chainTxExplorer(network, txHash)}
            target="_blank"
            rel="noreferrer"
            className="tx-white ms-1"
          >
            {
              chainTxExplorer(network, txHash)
                .split('https://')[1]
                .split('.')[0]
            }
          </a>
        </div>
      </div>
    );
  }

  if (!network || !networkReceive || !bridge) {
    return (
      <div className="py-4">
        <div className="card tx-center mb-4">
          {t('bridge.error-asset-not-found')}
        </div>

        <button
          className="btn btn-danger-10 wd-100p"
          onClick={() => navigate(-1)}
        >
          {t('common.back')}
        </button>
      </div>
    );
  }

  return (
    <div className="py-3">
      <div className="py-3 d-flex justify-content-between align-items-center">
        <div className="wd-40 ht-40">
          <UserAvatar string={currentWallet?.address || ''} />
        </div>
        <div className="ms-2 me-auto d-flex flex-column justify-content-center">
          <div className="tx-17 lh-2">
            {currentWallet?.name ||
              getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
          </div>
          <div className="tx-muted tx-12">
            {currentWallet?.name
              ? getEllipsisTxt(currentWallet?.address || '', 8, '0x')
              : ''}
          </div>
        </div>
      </div>

      {isInterType && walletTo && (
        <div className="card px-3 py-4 mb-3">
          <div className="tx-17 tx-semibold mb-2">{t('common.receiver')}</div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="wd-35 ht-35">
              <UserAvatar string={walletTo.address} size={35} />
            </div>
            <div className="ms-2 me-auto d-flex flex-column justify-content-center tx-left tx-normal">
              <div className="tx-17 lh-2">{walletTo.name}</div>
              <div className="tx-muted tx-12">
                {getEllipsisTxt(walletTo.address, 8, '0x')}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card py-4">
        <div className="d-flex justify-content-start align-items-center">
          <div className="pos-relative mn-wd-35 mx-wd-35">
            <img src={networkTokenLogo(network)} alt="chain" width={35} />
            <div className="chain-label z-index-50 bottom-0">
              <img
                src={chainLogo(network)}
                alt={network}
                className="wd-20 ht-20"
              />
            </div>
          </div>
          <div className="ms-2 lh-2">
            <div className="tx-13 tx-muted">{t('bridge.total-sent')}</div>
            <FormattedNumber
              value={totalAmount}
              postfix={chainToken(network)}
              className="tx-17"
              decimals={4}
              subZeros
            />
          </div>
        </div>

        <div className="d-flex justify-content-start align-items-center mt-4">
          <div className="pos-relative mn-wd-35 mx-wd-35">
            <img
              src={networkTokenLogo(networkReceive)}
              alt="chain"
              width={35}
            />
            <div className="chain-label z-index-50 bottom-0">
              <img
                src={chainLogo(networkReceive)}
                alt={networkReceive}
                className="wd-20 ht-20"
              />
            </div>
          </div>
          <div className="ms-2 lh-2">
            <div className="tx-13 tx-muted">{t('common.received')}</div>
            <FormattedNumber
              value={bridge.amountOut}
              postfix={chainToken(networkReceive)}
              className="tx-17"
              decimals={4}
              subZeros
            />
          </div>
        </div>

        <div className="d-flex justify-content-start align-items-center mt-4">
          <div className="pos-relative mn-wd-35 mx-wd-35">
            <img src={swap} alt="bridge" className="wd-35 ht-35" />
          </div>
          <div className="ms-2 lh-2">
            <div className="tx-13 tx-muted">{t('bridge.route')}</div>
            <div className="tx-17">{bridge.bridge}</div>
          </div>
        </div>
      </div>

      <div className="card py-4 mt-3 tx-muted">
        <div className="tx-white tx-17">{t('bridge.total-fee')}</div>
        <div className="tx-17">
          <FormattedNumber
            value={totalFeeUsd}
            suffix="$"
            className="tx-white"
            decimals={2}
            floor={false}
          />
          <FormattedNumber
            value={totalFee}
            suffix="("
            postfix={` ${nativeToken?.assetSymbol})`}
            className="ms-1"
            decimals={4}
            subZeros
          />
        </div>

        <div className="tx-13">
          <div className="mt-3">{t('bridge.estimated-gas-fee')}</div>
          <div>
            <FormattedNumber
              value={bridge.estimateGasFeeInUsd}
              suffix="$"
              className="tx-white"
              floor={false}
              decimals={2}
            />
            <FormattedNumber
              value={bridge.estimateGasFee}
              suffix="("
              postfix={` ${nativeToken?.assetSymbol})`}
              className="ms-1"
              decimals={4}
              subZeros
            />
          </div>

          <div className="mt-3">{t('bridge.bridge-fee')}</div>
          <div>
            <FormattedNumber
              value={bridge.bridgeFeeInUsd}
              suffix="$"
              className="tx-white"
              floor={false}
              decimals={2}
            />
            <FormattedNumber
              value={bridge.bridgeFee}
              suffix="("
              postfix={` ${nativeToken?.assetSymbol})`}
              className="ms-1"
              decimals={4}
              subZeros
            />
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary-10 wd-100p mt-3"
        onClick={handleSubmit}
      >
        {t('bridge.confirm-and-send')}
      </button>
    </div>
  );
});

export default BridgeConfirm;
