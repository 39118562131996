import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TermsAndConditionsNote = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const shouldBreak = useMemo(() => ['en-EN'].includes(language), [language]);

  return (
    <motion.button
      className="btn-a tx-muted mb-3 text-center tx-12 mx-3"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3, duration: 0.5 }}
      onClick={() =>
        window.open('https://alpha-dex.io/termsconditions', '_blank')
      }
    >
      {t('onboarding.terms-note')} {shouldBreak && <br />}
      <a
        style={{ textDecoration: 'none' }}
        href="https://alpha-dex.io/termsconditions"
      >
        <strong>{t('onboarding.terms-link')}</strong>
      </a>
    </motion.button>
  );
};

export default TermsAndConditionsNote;
