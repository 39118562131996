import React from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import gasIcon from '@assets/icons/gas-white.svg';
import { useStores } from '@hooks/useStores';
import { ChainId } from '../../../constants';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

const LimitOrderWalletBalance = () => {
  const { tokenStore, ordersStore } = useStores();
  const { isEvm, balance } = ordersStore;
  const { chain, gas } = tokenStore;

  return (
    <div className="d-flex align-items-center justify-content-between tx-13 mb-4">
      <div className="d-flex align-items-center justify-content-start tx-muted">
        <div>{t('common.balance')}</div>
        <FormattedNumber
          value={balance?.balance}
          decimals={4}
          subZeros
          floor
          postfix={balance?.token}
          className="tx-white ms-1"
        />
        <FormattedNumber
          value={balance?.balanceInUsd}
          decimals={2}
          className="tx-white ms-1"
          suffix="($"
          postfix=")"
          noSpace
          floor
          subZeros
        />
      </div>
      <div
        className={`${chain === ChainId.ALL || !isEvm ? 'd-none' : 'd-flex'} justify-content-end align-items-center`}
      >
        <img src={gasIcon} alt="gas" width={16} height={16} className="me-1" />
        <FormattedNumber value={gas?.gwei} postfix="GWEI" />
      </div>
    </div>
  );
};

export default observer(LimitOrderWalletBalance);
