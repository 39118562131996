import React, { useRef, useEffect } from 'react';
import md5 from 'md5';

interface IdenticonProps {
  className?: string,
  bg?: string,
  count?: number,
  palette?: string,
  fg?: string,
  padding?: number,
  size?: number,
  string: string,
}

const IdentIcon = (props: IdenticonProps) => {
  const {
    className = 'identicon rounded-circle',
    bg = 'white',
    count = 5,
    palette = null,
    padding = 0,
    size = 400,
    string = ''
  } = props;
  let fg = props.fg;

  const range = (n: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
    return ((n - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    updateCanvas();
  });

  const updateCanvas = () => {
    const hash = md5(string) as string;
    const hashcolor = hash.slice(0, 6);

    if (!canvas.current) {
      return;
    }

    const ctx = canvas.current.getContext('2d');

    if (!ctx) {
      return;
    }

    const devicePixelRatio = window.devicePixelRatio || 1;

    // determine the 'backing store ratio' of the canvas context
    const backingStoreRatio = (
      // @ts-ignore
      ctx.webkitBackingStorePixelRatio ||
      // @ts-ignore
      ctx.mozBackingStorePixelRatio ||
      // @ts-ignore
      ctx.msBackingStorePixelRatio ||
      // @ts-ignore
      ctx.oBackingStorePixelRatio ||
      // @ts-ignore
      ctx.backingStorePixelRatio || 1
    );

    // determine the actual ratio we want to draw at
    const ratio = devicePixelRatio / backingStoreRatio;

    const block = Math.floor(size * ratio / count);

    if (palette && palette.length) {
      const index = Math.floor(
        range(parseInt(hash.slice(-3), 16), 0, 4095, 0, palette.length)
      );
      fg = palette[index];
    }

    const pad = padding;
    const arr = hash.split('').map((el) => {
      const intValue = parseInt(el, 16);
      if (intValue < 8) {
        return 0;
      } else {
        return 1;
      }
    });

    const map = [];

    map[0] = map[4] = arr.slice(0, 5);
    map[1] = map[3] = arr.slice(5, 10);
    map[2] = arr.slice(10, 15);

    canvas.current.width = (block * count + pad);
    canvas.current.height = (block * count + pad);

    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

    map.forEach((row, i) => {
      row.forEach((el, j) => {
        if (el) {
          ctx.fillStyle = fg ? fg : '#' + hashcolor;
          ctx.fillRect(
            block * i + pad,
            block * j + pad,
            block - pad,
            block - pad
          );
        } else {
          ctx.fillStyle = bg;
          ctx.fillRect(
            block * i + pad,
            block * j + pad,
            block - pad,
            block - pad
          );
        }
      });
    });
  };

  return (
    <canvas
      ref={canvas}
      className={className}
      style={{ width: size, height: size }}
    />
  );
};

export default IdentIcon;
