import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import info from '@assets/icons/info-yellow.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import IconWithLoading from '@components/common/IconWithLoading';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const ResearchApproveModal = observer(() => {
  const { settingsStore } = useStores();
  const { isSaving: storeSaving } = settingsStore;
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [isSaving, setSaving] = useState(false);

  const handleClose = () => {
    if (isSaving) {
      return;
    }

    settingsStore.setResearchApproved(true);
  };

  useEffect(() => {
    if (storeSaving) {
      setSaving(true);
    }
  }, [storeSaving]);

  useEffect(() => {
    if (!storeSaving && isSaving) {
      setShowModal(false);
    }
    return () => {
      setShowModal(false);
    };
  }, [storeSaving, isSaving]);

  return (
    <Modal show={showModal} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{t('research.approval-modal')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center">
        <img src={info} alt="info" className="mb-3 mt-1 wd-45" />

        <div className="tx-warning tx-semibold tx-center mb-2">
          {t('research.approval-modal-description')}
        </div>

        <button
          className={`btn btn-warning wd-100p mt-3 ${isSaving ? 'op-6' : ''}`}
          onClick={w(handleClose, AmplitudeEvent.DISCLAIMER_AGREED)}
        >
          <IconWithLoading isLoading={isSaving} />
          {t('research.approval-i-agree')}
        </button>
      </Modal.Body>
    </Modal>
  );
});

export default ResearchApproveModal;
