import { ImpactParam } from "types/telegram-web-app";

export const vibrate = (param: ImpactParam) => {
  const appWindow = window as unknown as AppWindow;

  if (appWindow.Telegram && appWindow.Telegram.WebApp) {
    appWindow.Telegram.WebApp.HapticFeedback.impactOccurred(param);
  } else {
    console.log("Vibration API is not supported by this device.");
  }
};
