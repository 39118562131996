import React from "react";
import ReferralBadge from "@pages/Referral/components/ReferralBadge";

interface ReferralListItemProps {
  level: any;
  isActive?: boolean;
  onClick: () => void;
}

const ReferralListItem = ({level, isActive, onClick}: ReferralListItemProps) => {
  const {
    rank,
    label,
    refRewardPercents,
  } = level;
  return (
    <div className="wd-65 tx-center cur-pointer py-3" onClick={onClick}>
      <button className={`btn btn-dark border-0 p-0 tx-center wd-65 ht-65 rounded-3 pos-relative bg-gray-${isActive ? '500' : '700'}`}>
        <ReferralBadge type={rank} size={44} />
        {isActive && (
          <div className="active-badge" />
        )}
      </button>
      <div className="tx-13 mt-1">
        <div className="tx-semibold">{label}</div>
        <div className={`lh-1 ${!isActive ? 'tx-muted' : ''}`}>{refRewardPercents[0] + refRewardPercents[1]}%</div>
      </div>
    </div>
  );
};

export default ReferralListItem;
