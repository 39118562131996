import { Blockchain } from '../enums';

export enum TopTokensFilter {
  'TRENDING_POOLS' = 'TRENDING_POOLS',
  // 'BY_TRANSACTIONS_COUNT' = 'BY_TRANSACTIONS_COUNT',
  // 'BY_TRANSACTION_VOLUME' = 'BY_TRANSACTION_VOLUME',
  // 'NEW_POOL' = 'NEW_POOL',
}

export interface TokensRequest {
  blockchain: Blockchain;
  filter: TopTokensFilter;
}
