import React from 'react';
import lock from '@assets/icons/lock.svg';
import share from '@assets/icons/share.svg';
import support from '@assets/icons/support.svg';
import logo from '@assets/images/logo.svg';
import language from '@assets/images/language.svg';
import wallet from '@assets/icons/wallet.svg';
import arrowLeft from '@assets/icons/arrow-left-red.svg';
import insurance from '@assets/icons/insurance.svg';
import terms from '@assets/icons/terms-and-conditions.svg';
import cog from '@assets/icons/cog-white.svg';
import SideNavItem, { SideNavItemProps } from '@pages/Wallet/SideNavItem';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../constants';
import bell from '@assets/icons/bell.svg';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import WalletSelection from '@components/WalletSelection';
import { useTranslation } from 'react-i18next';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import cn from 'classnames';
import usePopup from '@hooks/usePopup';
import { useAmplitude, AmplitudeEvent } from '@hooks/useAmplitude';

interface NavItem extends SideNavItemProps {
  isWalletSelection?: boolean;
}

interface NavGroup {
  title?: string;
  className?: string;
  items: NavItem[];
}

const Profile = observer(() => {
  const { t } = useTranslation();
  const [w] = useAmplitude([AmplitudeEvent.PROFILE_SCREEN_VIEWED]);
  const { accountStore } = useStores();
  const { wallets } = accountStore;
  const navigate = useNavigate();
  const WebApp = useWebApp();
  const showPopup = usePopup();

  const navGroups: NavGroup[] = [
    {
      items: [
        {
          icon: bell,
          title: t('common.next-releases'),
          onClick: w(
            () => navigate(PageRoutes.AUTO_TRADE),
            AmplitudeEvent.NEXT_RELEASES_BUTTON_CLICKED,
          ),
        },
      ],
    },
    {
      title: t('common.settings'),
      items: [
        {
          title: t('wallet.wallet-settings'),
          icon: wallet,
          isWalletSelection: true,
          onClick: w(
            () => navigate(PageRoutes.WALLET_SETTINGS),
            AmplitudeEvent.WALLET_SETTINGS_CLICKED,
          ),
        },
        {
          title: t('wallet.export-seed-phrase'),
          icon: share,
          onClick: w(
            () => navigate(PageRoutes.EXPORT_SEED_PHRASE),
            AmplitudeEvent.EXPORT_SEED_PHRASE_CLICKED,
          ),
        },
        {
          title: t('common.security'),
          icon: lock,
          onClick: w(
            () => navigate(PageRoutes.SECURITY),
            AmplitudeEvent.SECURITY_SETTINGS_CLICKED,
          ),
        },
        {
          title: t('common.language'),
          icon: language,
          onClick: w(
            () => navigate(PageRoutes.LANGUAGE),
            AmplitudeEvent.LANGUAGE_SETTINGS_CLICKED,
          ),
        },
        {
          title: t('profile.customization'),
          icon: cog,
          onClick: w(
            () => navigate(PageRoutes.AMOUNT_SETTINGS),
            AmplitudeEvent.CUSTOMIZATION_SETTINGS_CLICKED,
          ),
        },
      ],
    },
    {
      title: t('common.help'),
      items: [
        {
          icon: support,
          title: t('common.support'),
          onClick: w(
            () => window.open('https://t.me/alpha_dex_support', '_blank'),
            AmplitudeEvent.SUPPORT_CLICKED,
          ),
        },
        {
          icon: logo,
          title: t('common.about-us'),
          onClick: w(
            () => window.open('https://alpha-dex.io/', '_blank'),
            AmplitudeEvent.ABOUT_US_CLICKED,
          ),
        },
        {
          icon: insurance,
          title: t('profile.privacy-policy'),
          onClick: w(
            () => window.open('https://alpha-dex.io/termsconditions', '_blank'),
            AmplitudeEvent.PRIVACY_POLICY_CLICKED,
          ),
        },
        {
          icon: terms,
          title: t('profile.terms-conditions'),
          onClick: w(
            () => window.open('https://alpha-dex.io/privacypolicy', '_blank'),
            AmplitudeEvent.TERMS_CONDITIONS_CLICKED,
          ),
        },
      ],
    },
    {
      items: [
        {
          icon: arrowLeft,
          title: t('common.logout'),
          onClick: w(() => {
            showPopup({
              title: t('common.logout'),
              message: t('profile.confirm-logout'),
              buttons: [
                {
                  text: t('common.logout'),
                  type: 'danger',
                  id: 'ok',
                },
                {
                  text: t('common.cancel'),
                  id: 'cancel',
                },
              ],
            }).then((result: unknown) => {
              if (result === 'ok') {
                accountStore.logout();
                if (WebApp) {
                  WebApp.close();
                }
              }
            });
          }, AmplitudeEvent.LOGOUT_CLICKED),
          variant: 'transparentDanger',
        },
      ],
      className: 'bg-transparent',
    },
  ];

  return (
    <div className="pt-4 pb-nav">
      {navGroups.map(({ title, className, items }, index) => (
        <React.Fragment key={`profile-nav-group-${index}-${items.length}`}>
          {!!title && (
            <div className="tx-17 tx-semibold mt-3 mb-2">{title}</div>
          )}
          <div className={cn(['card p-0 mb-4 overflow-hidden', className])}>
            {items.map(
              ({ isWalletSelection, onClick, ...item }, groupIndex) => {
                if (!isWalletSelection) {
                  return (
                    <SideNavItem
                      key={`profile-nav-item-${groupIndex}-${item.title}`}
                      onClick={onClick}
                      {...item}
                      isLast={groupIndex === items.length - 1}
                    />
                  );
                }
                return (
                  <WalletSelection
                    className="d-flex btn btn-transparent bd-0 p-0 tx-normal tx-left wd-100p"
                    key={`profile-nav-item-${groupIndex}-${item.title}`}
                    onChange={onClick}
                    setOnChange
                  >
                    <SideNavItem
                      {...item}
                      badgedLabel={false}
                      label={wallets.length}
                      isLast={groupIndex === items.length - 1}
                    />
                  </WalletSelection>
                );
              },
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
});

export default Profile;
