export default (str: string) => {
  const result: Record<string, string> = {};
  const parts = str.split('==');
  parts.forEach((p) => {
    const part = p.replace('--', '÷');
    const [key, value] = part.split('÷').map((s) => s.trim());
    result[key] = value;
  });
  return result;
};
