import React from 'react';
import InputGroup from '@components/common/InputGroup';
import close from '@assets/icons/close-outlined.svg';
import plus from '@assets/icons/plus-outlined.svg';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { TpSlValue } from '@stores/token-trade';

type Props = {
  disableAdding: boolean;
  lightEmptyInputs: boolean;
  hasSumError: boolean;
  idx: number;
  values: TpSlValue;
  onChange: (s: 'percents' | 'value') => (p: string | number | null) => void;
  onAdd: () => void;
  onRemove: (idx: number) => void;
};

const TPSLInputGroup = ({
  disableAdding,
  lightEmptyInputs,
  hasSumError,
  idx,
  values,
  onChange,
  onAdd,
  onRemove,
}: Props) => {
  return (
    <div className={cn(['d-flex', 'flex-column', 'gap-2'])}>
      <div className={cn(['d-flex', 'flex-row', 'gap-2'])}>
        <div className="d-flex wd-50p">
          <InputGroup
            isInValid={
              lightEmptyInputs &&
              !(values.profitPercents || values.lossPercents)
            }
            isNumeric
            inputClassName={cn([
              'form-control',
              'appearance-none',
              'py-1',
              'ht-35',
            ])}
            unitClassName="ht-35"
            noCross
            value={values.profitPercents || values.lossPercents || null}
            placeholder="100%"
            unit="%"
            onChange={onChange('percents')}
          />
        </div>

        <div className="d-flex wd-50p gap-2">
          <InputGroup
            isInValid={
              hasSumError || (lightEmptyInputs && !values.tokenPercents)
            }
            isNumeric
            inputClassName={cn([
              'form-control',
              'appearance-none',
              'py-1',
              'ht-35',
            ])}
            unitClassName="ht-35"
            noCross
            value={values.tokenPercents || null}
            placeholder="25%"
            unit="%"
            onChange={onChange('value')}
          />
          {idx !== 0 && (
            <button
              onClick={() => onRemove(idx)}
              className="btn d-flex p-0 mn-wd-35 border-muted-90 justify-content-center align-items-center"
            >
              <img className="d-flex" width={16} height={16} src={close}></img>
            </button>
          )}
          {idx === 0 && (
            <button
              disabled={disableAdding}
              onClick={onAdd}
              className="btn d-flex p-0 mx-wd-35 form-control justify-content-center align-items-center"
            >
              <img className="d-flex" width={16} height={16} src={plus}></img>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(TPSLInputGroup);
