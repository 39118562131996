import React from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import CopyButton from '@components/common/CopyButton';
import { useNavigate } from 'react-router-dom';
import LabelValueCard from '@components/common/LabelValueCard';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@helpers/numbers';
import { OrdersSummaryResponse } from '../../../types/order/orders-summary.response';
import { greaterThanOrEqual } from '@helpers/bignumber';
import dots from '@assets/icons/dots.svg';
import TokenLogo from '@components/common/TokenLogo';
import tokenNameClass from '@helpers/tokenNameClass';
import { useTranslation } from 'react-i18next';
import { getEllipsisTxt } from '@helpers/formatters';
import AlphaLink from '@components/common/AlphaLink';
import { ChainId } from '../../../constants';

interface LimitOrdersSummaryProps {
  data: OrdersSummaryResponse;
  toggleSelected: () => void;
  hideDetails?: boolean;
  chartLink: string;
  isHidden?: boolean;
  analyticsLink: string;
  network: ChainId;
}

const LimitOrdersSummary = (
  {
    data,
    toggleSelected,
    chartLink,
    analyticsLink,
    hideDetails = false,
    isHidden = false,
    network,
  }: LimitOrdersSummaryProps) => {
  const { t } = useTranslation();

  const { blockchain, pairAddress, pairId } = data;

  const navigate = useNavigate();

  const handleTrade = () => {
    navigate(`/limit-orders/${blockchain}/${pairAddress}/trade`);
  };

  const handleNavigate = () => {
    navigate(`/limit-orders/${blockchain}/${pairAddress}/${pairId}`);
  };

  return (
    <div className={`card mb-3 ${isHidden ? 'd-none' : ''}`}>
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p">
        <TokenLogo address={data.tokenAddress} name={data.tokenSymbol} size={45} chain={blockchain} useName />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className={tokenNameClass(data.tokenName || '')}>
              {data.tokenName}
            </div>
            <div className="wd-2 ht-15 bg-semi-transparent mx-2" />
            <AlphaLink
              path={chartLink}
              className={`ht-16 tx-16 lh-1 d-flex align-items-center justify-content-center ${network !== ChainId.TRON ? 'me-2' : 'me-auto'} z-index-10 text-decoration-none`}
            >
              📈
            </AlphaLink>
            {network !== ChainId.TRON && (
              <AlphaLink
                path={analyticsLink}
                className="ht-16 tx-16 lh-1 d-flex align-items-center justify-content-center me-auto z-index-10 text-decoration-none"
              >
                🤖
              </AlphaLink>
            )}
            <FormattedNumber
              value={data.tokenBalanceInUsd}
              decimals={new BigNumber(data.tokenBalanceInUsd).isGreaterThan(100) ? 0 : 6}
              suffix="$"
              subZeros
              className="word-break"
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <CopyButton
              text={data.tokenAddress}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none me-auto z-index-10"
              iconClass="tx-muted ms-1"
            >
              CA: {getEllipsisTxt(data.tokenAddress, 4)}
            </CopyButton>
            <FormattedNumber
              value={data.pnlPercent}
              withSign
              className={greaterThanOrEqual(data.pnlPercent, 0) ? 'tx-success' : 'tx-danger'}
              postfix="%"
            />
          </div>
        </div>
      </div>

      <div
        className={`${hideDetails ? 'd-none' : 'd-flex'} justify-content-between align-items-center wd-100p gap-2 mt-3`}
      >
        <LabelValueCard label={t('trading.active-orders')} value={data.openOrders} />
        <LabelValueCard label={t('trading.free-tokens')}>
          {formatNumber(new BigNumber(data.tokenBalance).minus(data.tokensInOrders).toNumber())}
          <span className="mx-1">{t('common.of')}</span>
          {formatNumber(data.tokenBalance)}
        </LabelValueCard>
      </div>

      <div className="d-flex justify-content-end align-items-center wd-100p tx-semibold tx-13 z-index-10 mt-3">
        <button
          className="btn btn-semi-10 py-2 tx-13 wd-100p"
          onClick={handleTrade}
        >
          {t('trading.trade')}
        </button>
        <button
          className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 ms-2"
          onClick={toggleSelected}
        >
          <img src={dots} alt="more" width={20} />
        </button>
      </div>

      {/*<div className="d-flex justify-content-end align-items-center wd-100p tx-semibold tx-13 z-index-10">*/}
      {/*  <div*/}
      {/*    className="wd-35 ht-35 bg-semi-danger-10 rounded-1 d-flex justify-content-center align-items-center cur-pointer ms-0 me-auto"*/}
      {/*    onClick={() => {*/}
      {/*      showPopup({*/}
      {/*        title: 'Cancel Order',*/}
      {/*        message: 'Do you really want to cancel the order?',*/}
      {/*        buttons: [*/}
      {/*          {*/}
      {/*            text: 'Close',*/}
      {/*            id: 'close',*/}
      {/*          },*/}
      {/*          {*/}
      {/*            text: 'Yes',*/}
      {/*            type: 'destructive',*/}
      {/*            id: 'cancel',*/}
      {/*          },*/}
      {/*        ],*/}
      {/*      }).then((result) => {*/}
      {/*        if (result === 'cancel') {*/}
      {/*          cancelOrder(data.id);*/}
      {/*        }*/}
      {/*      });*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <img src={times} alt="cancel" width={16} height={16} />*/}
      {/*  </div>*/}
      {/*  {!!sharePosition && (*/}
      {/*    <div*/}
      {/*      className="ht-35 bg-semi-transparent-10 rounded-1 d-flex justify-content-center align-items-center cur-pointer me-2 px-2"*/}
      {/*      onClick={sharePosition}*/}
      {/*    >*/}
      {/*      <img src={share} alt="share" width={16} height={16} />*/}
      {/*      <span className="ms-2">Share</span>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className="pos-absolute absolute-item z-index-5 cur-pointer" onClick={handleNavigate} />
    </div>
  );
};

export default LimitOrdersSummary;
