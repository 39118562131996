import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface InfoTooltipProps {
  text: string;
  className?: string;
  size?: number;
}

const InfoTooltip = ({ text, className = 'ms-1 tx-white', size = 16 }: InfoTooltipProps) => {
  const tooltip = (
    <Tooltip id={text.substring(0, 30).split(' ').join('-')}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={tooltip}>
      <div className={`d-flex align-items-center ${className}`}>
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 8.00001C14 11.3137 11.3137 14 7.99995 14C4.68624 14 1.99995 11.3137 1.99995 8.00001C1.99995 4.6863 4.68624 2.00001 7.99995 2.00001C11.3137 2.00001 14 4.6863 14 8.00001ZM9.43963 8.12193C9.43962 7.62091 9.18374 7.17965 8.79553 6.92171C9.18331 6.66372 9.43888 6.22275 9.43892 5.72207L9.43897 5.16157C9.43905 4.36628 8.7944 3.72151 7.99911 3.72144C7.20382 3.72136 6.55905 4.36601 6.55897 5.1613L6.55892 5.7218C6.55888 6.22288 6.81477 6.66421 7.20302 6.92218C6.8152 7.1802 6.55962 7.62125 6.55963 8.12198L6.55969 11.1776C6.55971 11.9729 7.20443 12.6176 7.99972 12.6176C8.79501 12.6176 9.43971 11.9728 9.43969 11.1776L9.43963 8.12193Z"
            fill="white" stroke="white" strokeWidth="1.6" />
        </svg>
      </div>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
