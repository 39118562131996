import { ISnipeSettings, PositionModel } from '../../../types';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SnipeRow from '@pages/Profile/SnipeRow';
import SnipeColumn from '@pages/Profile/SnipeColumn';
import Toggle from 'react-toggle';
import chevron from '@assets/icons/chevron.svg';
import { numberRegex } from '../../../constants';
import IconWithLoading from '@components/common/IconWithLoading';
import { useStores } from '@hooks/useStores';
import { useParams } from 'react-router-dom';
import Preloader from '@components/common/Preloader';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import useNotification from '@hooks/useNotification';
import SettingsInput from '@components/common/SettingsInput';
import { ApiError } from '@helpers/api';
import { useTranslation } from 'react-i18next';
import { hasOwnProperty } from '@helpers/object';
import useResponseHandler from '@hooks/useResponseHandler';

interface PositionSettingsProps {
  position?: PositionModel;
  onSave?: (position: PositionModel) => void;
  onClose?: () => void;
  isSaving?: (value: boolean) => void;
}

const PositionSettings = observer((props: PositionSettingsProps) => {
  const { t } = useTranslation();
  const { accountStore } = useStores();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<PositionModel>();
  const [settings, setSettings] = useState<ISnipeSettings | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const params = useParams();
  const WebApp = useWebApp();
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const mainData = useMemo(() => {
    if (!position) return null;

    const { createdAt, updatedAt, id, userId, label, ...rest } =
      position.settings;

    return rest;
  }, [position]);

  const handleClose = () => {
    if (isSaving) return;
    if (!props.position && WebApp) {
      WebApp.close();
    } else {
      setSettings(JSON.parse(JSON.stringify(mainData)));
      if (props.onClose) {
        props.onClose();
      }
    }
  };

  const isInvalid = useCallback(
    (key: string, isReq = false) => {
      if (!settings) {
        return false;
      }
      // @ts-ignore
      const value = settings[key];
      if (isReq && (value === undefined || value === null)) {
        return true;
      }
      if (!isReq && (value === undefined || value === null)) {
        return false;
      }
      if (key.toLowerCase().includes('delta') && value && value < 0) {
        return true;
      }
      return (
        isNaN(value) ||
        !numberRegex.test(value.toString()) ||
        !!(error && error.error?.includes(key))
      );
    },
    [settings, error],
  );

  const handleOnChange = useCallback(
    (key: string, value: string | number | null) => {
      if (error && error.error?.includes(key)) {
        setError(null);
      }
    },
    [error],
  );

  useEffect(() => {
    if (!settings && mainData) {
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [settings, mainData]);

  useEffect(() => {
    if (
      settings &&
      settings.trailingStopLossEnabled &&
      !settings.stopLossEnabled
    ) {
      setSettings({ ...settings, stopLossEnabled: true });
    }
  }, [settings]);

  useEffect(() => {
    if (props.isSaving) {
      props.isSaving(isSaving);
    }
  }, [props, isSaving]);

  const handleSubmit = () => {
    if (!settings || !position) return;

    const checks = [
      {
        check: isInvalid('stopLossPercent', settings.stopLossEnabled),
        label: t('common.stop-loss'),
      },
      {
        check: isInvalid('takeProfitPercent', settings.takeProfitEnabled),
        label: t('trading.take-profit'),
      },
      {
        check: isInvalid('stopLossSellPercent', settings.stopLossEnabled),
        label: t('trading.stop-loss-amount'),
      },
      {
        check: isInvalid('takeProfitSellPercent', settings.takeProfitEnabled),
        label: t('trading.take-profit-amount'),
      },
      {
        check: isInvalid('sellSlippage', true),
        label: t('common.sell-slippage'),
      },
      {
        check: isInvalid('buySlippage', true),
        label: t('trading.buy-slippage'),
      },
      {
        check: isInvalid('sellGasDelta'),
        label: t('trading.sell-priority-fee'),
      },
      { check: isInvalid('buyGasDelta'), label: t('trading.buy-priority-fee') },
      {
        check: isInvalid('approveGasDelta'),
        label: t('common.approve-priority-fee'),
      },
    ];

    if (checks.some(({ check }) => check)) {
      notify(
        checks.find((c) => c.check)?.label + t('common.invalid-or-empty'),
        { type: 'danger' },
      );
      return;
    }

    const data = JSON.parse(JSON.stringify(settings));

    if (!settings.stopLossEnabled && !settings.stopLossPercent) {
      data.stopLossPercent = data.settings.stopLossPercent || 0;
    }
    if (!settings.takeProfitEnabled && !settings.takeProfitPercent) {
      data.takeProfitPercent = data.settings.takeProfitPercent || 0;
    }
    if (settings.stopLossPercent && settings.stopLossPercent > 0) {
      data.stopLossPercent = settings.stopLossPercent * -1;
    }

    setIsSaving(true);
    accountStore
      .updatePosition(position.id, data)
      .then((response) => {
        setIsSaving(false);
        if (hasOwnProperty(response, 'settings')) {
          notify(t('position.position-settings-updated'));
          if (hasOwnProperty(props, 'onSave') && props.onSave) {
            props.onSave({ ...position, ...response });
          }
          handleClose();
        } else {
          handleResponse(response);
          setError(response.data);
        }
      })
      .catch((e) => {
        console.error(e);
        setIsSaving(false);
        notify(t('position.error-updating-settings'), { type: 'danger' });
      });
  };

  useEffect(() => {
    if (position) return;
    if (props.position) {
      setPosition(props.position);
    } else if (hasOwnProperty(params, 'id') && params.id) {
      accountStore.getPosition(params.id || '').then((response) => {
        if (response) {
          setPosition(response);
        }
      });
    }
  }, [position, props, params]);

  if (!settings || !position) {
    return (
      <div className="tx-center pt-5">
        <Preloader
          inline
          iconSize={64}
          className="d-flex flex-column align-items-center"
          textClass="d-block mt-2"
          iconClass="d-block"
          text="Loading position settings..."
        />
      </div>
    );
  }

  return (
    <>
      {!props.position && (
        <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
          <div className="tx-28 tx-semibold">
            {t('position.edit-token-settings', { name: position.token.name })}
          </div>
        </div>
      )}
      <div>
        <div>{t('trading.take-profit-stop-loss')}</div>
        <div className="card mt-1 mb-2">
          <SnipeRow>
            <SnipeColumn>
              <div>{t('trading.take-profit')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.takeProfitEnabled}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    takeProfitEnabled: e.target.checked,
                  })
                }
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>{t('common.stop-loss')}</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.stopLossEnabled}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    stopLossEnabled: e.target.checked,
                    trailingStopLossEnabled: false,
                  })
                }
              />
            </SnipeColumn>
          </SnipeRow>

          {settings.takeProfitEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t('order.percentage-profit-sell-executed')}
                isRequired
              >
                {t('trading.take-profit')}
              </SettingsInput>

              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t(
                  'order.percentage-total-token-sold-profit-level-reached',
                )}
              >
                {t('trading.take-profit-sell')} %
              </SettingsInput>
            </SnipeRow>
          )}

          {settings.stopLossEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t('trading.percentage-loss-sell-executed')}
                isRequired
              >
                {t('common.stop-loss')}
              </SettingsInput>

              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t(
                  'trading.percentage-total-token-sold-loss-level-reached',
                )}
              >
                {t('common.stop-loss-sell')} %
              </SettingsInput>
            </SnipeRow>
          )}
        </div>

        <div className={`mb-2 ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
          <div>{t('trading.general-settings')}</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="maxGasPrice"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip={t('trading.max-gas-price-description')}
              >
                {t('trading.max-gas-price')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="approveGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip={t('trading.extra-tip-priority-fee-description')}
              >
                {t('common.approve-priority-fee')}
              </SettingsInput>
            </SnipeRow>

            <SnipeRow className="mt-3">
              <SnipeColumn>
                <div>{t('trading.mev-guard')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.mevGuardEnabled}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      mevGuardEnabled: e.target.checked,
                    })
                  }
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>{t('trading.fail-guard')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.failGuard}
                  onChange={(e) =>
                    setSettings({ ...settings, failGuard: e.target.checked })
                  }
                />
              </SnipeColumn>
            </SnipeRow>

            {/*<SnipeRow className="mt-3">*/}
            {/*  <SnipeColumn>*/}
            {/*    <div className="tx-gray-300">Anti Rug</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      disabled*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*  <SnipeColumn>*/}
            {/*    <div>MEV Guard</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      checked={settings.mevGuardEnabled}*/}
            {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*</SnipeRow>*/}

            <SnipeRow className="mt-3">
              <SnipeColumn className="tx-12 tx-semibold">
                <div>{t('trading.auto-approve')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.autoApprove}
                  onChange={(e) =>
                    setSettings({ ...settings, autoApprove: e.target.checked })
                  }
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>{t('trading.trailing-stop-loss')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.trailingStopLossEnabled}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      trailingStopLossEnabled: e.target.checked,
                    })
                  }
                />
              </SnipeColumn>
            </SnipeRow>
          </div>

          <div className="mt-4">{t('trading.buy-settings')}</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buySlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t('common.buy-execution-percentage')}
                isRequired
              >
                {t('trading.slippage')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buyGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip={t('trading.extra-tip-priority-fee-description')}
              >
                {t('trading.priority-fee')}
              </SettingsInput>
            </SnipeRow>
          </div>

          <div className="mt-4">{t('trading.sell-settings')}</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellSlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t('common.sale-execution-percentage')}
                isRequired
              >
                {t('trading.slippage')}
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                onChange={handleOnChange}
                tooltip={t('trading.extra-tip-priority-fee-description')}
              >
                {t('trading.priority-fee')}
              </SettingsInput>
            </SnipeRow>
          </div>
        </div>

        <div
          className="pb-4 mb-2 mt-4 tx-13 tx-center border-bottom border-semi-transparent cur-pointer with-hover"
          onClick={() => setIsDetailsOpen((prev) => !prev)}
        >
          <span>{t('trading.more-settings')}</span>
          <img
            src={chevron}
            alt="toggle details"
            width={16}
            height={16}
            className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`}
          />
        </div>
      </div>

      <button
        className="btn btn-primary-10 wd-100p mt-3"
        onClick={handleSubmit}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} />
        {t('common.save')}
      </button>

      <button
        className="btn btn-transparent wd-100p mt-2 mb-3"
        onClick={handleClose}
        disabled={isSaving}
      >
        {t('common.cancel')}
      </button>
    </>
  );
});

export default PositionSettings;
