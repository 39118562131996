import confirmAlert from '@components/ConfirmAlert';
import { useCallback } from 'react';
import { ModalAlertProps } from '@components/ModalAlert';

const usePopup = () => {
  return useCallback(
    (props: ModalAlertProps) =>
      confirmAlert(props),
    [],
  );
};

export default usePopup;
