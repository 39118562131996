import React from 'react';
import { vibrate } from '@helpers/webApp';
import { ImpactParam } from '../../types/telegram-web-app';

interface VibruttonProps {
  children: React.ReactNode;
  onClick: () => void;
  vibrate?: ImpactParam;
  className?: string;
  disabled?: boolean;
}

const Vibrutton = ({ children, onClick, className, disabled, vibrate: impact = 'light' }: VibruttonProps) => {
  return (
    <button
      className={className}
      disabled={disabled}
      onClick={() => {
        vibrate(impact);
        onClick();
      }}
    >
      {children}
    </button>
  );
};

export default Vibrutton;
