const tokenNameClass = (name: string, additional?: string) => {
  let className = `lh-2 word-break ${additional || ''}`;
  if (name.length < 10) {
    className += ' mx-wd-75p';
  } else if (name.length < 15) {
    className += ' mx-wd-70p';
  } else if (name.length < 20) {
    className += ' mx-wd-60p';
  } else {
    className += ' mx-wd-50p';
  }
  return className;
};

export default tokenNameClass;
