import React, { useCallback, useEffect, useState } from 'react';
import { IOrderSettings } from '../../../types';
import { Modal } from 'react-bootstrap';
import cog from '@assets/icons/cog.svg';
import { ChainId, numberRegex } from '../../../constants';
import usePopup from '@hooks/usePopup';
import useNotification from '@hooks/useNotification';
import { ApiError } from '@helpers/api';
import SnipeRow from '@pages/Profile/SnipeRow';
import SettingsInput from '@components/common/SettingsInput';
import SnipeColumn from '@pages/Profile/SnipeColumn';
import Toggle from 'react-toggle';
import clone from '@helpers/clone';
import { smallerThan } from '@helpers/bignumber';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { defaultOrderSettings } from '@stores/settings-store';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { useStores } from '@hooks/useStores';
import { Blockchain } from 'types/enums';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Vibrutton from '@components/common/Vibrutton';

interface OrderSettingsModalProps {
  data: IOrderSettings;
  updateSettings: (data: IOrderSettings) => void;
}

const OrderSettingsModal = ({
  data,
  updateSettings,
}: OrderSettingsModalProps) => {
  const { tokenTrade } = useStores();
  const { chain } = tokenTrade;
  const showPopup = usePopup();
  const notify = useNotification();
  const { t } = useTranslation();
  const [w] = useAmplitude();

  const [show, setShow] = useState<boolean>(false);
  const [settings, setSettings] = useState<IOrderSettings>(
    clone(data ?? defaultOrderSettings),
  );
  const [error, setError] = useState<ApiError | null>(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  useEffect(() => {
    setSettings(clone(data ?? defaultOrderSettings));
  }, [data]);

  const handleClose = () => {
    if (!isSaved) {
      setSettings(clone(data));
    }
    setIsSaved(false);
    setShow(false);
  };

  const isInvalid = useCallback(
    (key: string, isReq = false) => {
      if (!settings) {
        return false;
      }
      // @ts-ignore
      const value = settings[key];
      if (isReq && (value === undefined || value === null || value === '')) {
        return true;
      }
      if (!isReq && (value === undefined || value === null || value === '')) {
        return false;
      }
      if (value && smallerThan(value, 0)) {
        return true;
      }
      if (key === 'slippage' && value && (value < 0 || value > 100)) {
        return true;
      }
      return (
        isNaN(value) ||
        !numberRegex.test(value.toString()) ||
        !!(error && error.error?.includes(key))
      );
    },
    [settings, error],
  );

  const handleOnChange = useCallback(
    (key: string, value: string | number | null) => {
      if (error && error.error?.includes(key)) {
        setError(null);
      }
    },
    [error],
  );

  const handleSubmit = () => {
    const checks = [
      { check: isInvalid('slippage'), label: t('trading.slippage') },
      { check: isInvalid('extraGasPrice'), label: t('order.extra-gas-price') },
    ];

    if (checks.some(({ check }) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid', {
        type: 'danger',
      });
      return;
    }

    setIsSaved(true);
    setTimeout(() => {
      updateSettings(clone(settings));
      handleClose();
    }, 50);
  };

  const handleReset = () => {
    showPopup({
      title: t('order.reset-settings'),
      message: t('order.confirm-reset'),
      buttons: [
        {
          text: t('common.reset'),
          type: 'danger',
          id: 'reset',
        },
        {
          text: t('common.no'),
          id: 'close',
        },
      ],
    }).then((result) => {
      if (result === 'reset') {
        setSettings(clone(defaultOrderSettings));
      }
    });
  };

  const isInputHidden = (key: string) => {
    return {
      extraGasPricePercent: [Blockchain.TON, Blockchain.TRON],
      antiMevEnabled: [Blockchain.BSC, Blockchain.BASE, Blockchain.TON, Blockchain.TRON],
      autoApproveEnabled: [Blockchain.TON, Blockchain.TRON],
    }[key]?.includes(chain as Blockchain);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t('order.order-settings')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ht-400 d-flex flex-column justify-content-between">
          <div className="d-flex flex-column gap-2">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="slippage"
                isInvalidHandler={isInvalid}
                placeholder="5%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t('order.percentage-profit-sell-executed')}
              >
                {t('trading.slippage')}
              </SettingsInput>

              <SettingsInput
                data={settings}
                className={cn([
                  isInputHidden('extraGasPricePercent') && 'd-none',
                ])}
                setHandler={setSettings}
                dataKey="extraGasPricePercent"
                isInvalidHandler={isInvalid}
                placeholder="10%"
                unit="%"
                onChange={handleOnChange}
                tooltip={t('order.extra-gas-price-description')}
              >
                {t('order.extra-gas-price')} %
              </SettingsInput>
            </SnipeRow>

            <SnipeRow>
              <SnipeColumn
                className={cn([
                  'tx-13',
                  'tx-semibold',
                  isInputHidden('antiMevEnabled') && 'd-none',
                ])}
              >
                <div>{t('trading.mev-guard')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.antiMevEnabled}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      antiMevEnabled: e.target.checked,
                    })
                  }
                />
              </SnipeColumn>
              <SnipeColumn
                className={cn([
                  'tx-12',
                  'tx-semibold',
                  isInputHidden('autoApproveEnabled') && 'd-none',
                ])}
              >
                <div>{t('trading.auto-approve')}</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.autoApproveEnabled}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      autoApproveEnabled: e.target.checked,
                    })
                  }
                />
              </SnipeColumn>
            </SnipeRow>
          </div>

          <div className="mt-auto mb-0">
            <button
              className="btn btn-primary-10 wd-100p mt-3"
              onClick={w(
                handleSubmit,
                AmplitudeEvent.SAVE_SETTINGS_BUTTON_CLICKED,
                { settings },
              )}
            >
              {t('common.save-settings')}
            </button>

            <button
              className="btn btn-transparent tx-primary wd-100p mt-2 mb-3"
              onClick={w(
                handleReset,
                AmplitudeEvent.RESET_TO_DEFAULT_BUTTON_CLICKED,
              )}
            >
              {t('order.reset-default')}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Vibrutton
        className="btn btn-link tx-secondary bg-transparent border-1 border-semi-transparent rounded-3 d-flex justify-content-center align-items-center cur-pointer tx-muted tx-13 px-2 py-1 text-decoration-none"
        onClick={w(() => setShow(true), AmplitudeEvent.ORDER_SETTINGS_CLICKED)}
      >
        <img src={cog} alt="settings" width={16} height={16} className="me-1" />
        {t('trading.slippage')}{' '}
        {settings.slippage !== null ? settings.slippage : 5}%
      </Vibrutton>
    </>
  );
};

export default observer(OrderSettingsModal);
