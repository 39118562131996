import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormattedNumber from '@components/common/FormattedNumber';
import TokenLogo from '@components/common/TokenLogo';
import {
  chainLogo,
  chainName,
  chainTxExplorer,
  dexLogo,
  dexName, dexVersion,
} from '@helpers/chains';
import gas from '@assets/icons/gas-white.svg';
import copy from '@assets/icons/copy.svg';
import arrow from '@assets/icons/arrow-right.svg';
import { format } from 'date-fns';
import BigNumber from 'bignumber.js';
import { ReactComponent as Clock } from './clock.svg';
import CopyButton from '@components/common/CopyButton';
import { getEllipsisTxt } from '@helpers/formatters';
import { OrderResponse } from '../../../types/order/order.response';
import useOrderData from '@hooks/useOrderData';
import IconWithLoading from '@components/common/IconWithLoading';
import { OrderTrigger } from '../../../types/order/order.enum';
import usePopup from "@hooks/usePopup";
import { useStores } from '@hooks/useStores';
import useNotification from '@hooks/useNotification';
import { BaseChainToken, Blockchain, PairType } from '../../../types/enums';
import { LimitData } from '../../../types/common.types';
import LabelValueListItem from '@components/common/LabelValueListItem';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlphaLink from '@components/common/AlphaLink';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { ResearchPairResponse } from '../../../types/research/research-pair.response';
import { ChainId } from '../../../constants';
import { QuickMode } from '../../../types';

interface QuickTradeConfirmationProps {
  item: ResearchPairResponse | null;
  settings: QuickMode | undefined;
  onProceed: () => void;
  onCancel: () => void;
}

const QuickTradeConfirmation = ({
  item,
  settings,
  onProceed,
  onCancel,
}: QuickTradeConfirmationProps) => {
  const { t } = useTranslation();
  const { accountStore, tokenTrade } = useStores();
  const { wallets } = accountStore;
  const [showModal, setShowModal] = useState<boolean>(false);
  const showPopup = usePopup();
  const notify = useNotification();
  const navigate = useNavigate();
  const [price, setPrice] = useState<number>(0);

  const handleClose = () => {
    setShowModal(false);
    onCancel();
  };

  const handleProceed = () => {
    setShowModal(false);
    onProceed();
  };

  useEffect(() => {
    if (item) {
      setShowModal(true);
      tokenTrade.getCurrency().then((currency) => {
        if (currency) {
          // @ts-ignore
          const base = BaseChainToken[item.blockchain];
          setPrice(currency[base]);
        }
      });
    }
  }, [item]);

  if (!item) {
    return null;
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        onExited={handleClose}
      >
        <Modal.Header className="modal-header-new with-border" closeButton />
        <Modal.Body className="pt-2">
          <div className="card bg-semi-transparent-10 d-flex flex-row align-items-center justify-content-between my-3 tx-semibold">
            <TokenLogo
              address={item.quoteToken.address}
              name={item.quoteToken.name}
              chain={item.blockchain}
              size={45}
            />
            <div className="ms-3 me-auto">
              {item.quoteToken.name}
            </div>
            <div className="me-0">
              {getEllipsisTxt(item.quoteToken.address, 4)}
            </div>
          </div>

          <div className="tx-center pb-3 mb-3 border-bottom border-semi-transparent-10">
            <FormattedNumber
              value={new BigNumber(settings?.valueIn || 0).multipliedBy(price).toNumber()}
              decimals={2}
              subZeros
              suffix="$"
              className="d-block tx-34 tx-bold"
            />
            <FormattedNumber
              value={settings?.valueIn || 0}
              decimals={4}
              subZeros
              // @ts-ignore
              postfix={BaseChainToken[item.blockchain]}
              className="d-block tx-18"
            />
          </div>

          <LabelValueListItem
            label={t('common.network')}
            value={item.blockchain}
            className="d-flex justify-content-between"
          />

          <LabelValueListItem
            label={t('common.wallet')}
            value={wallets.find((w) => w.id === settings?.walletId)?.name}
            className="d-flex justify-content-between"
          />

          <LabelValueListItem
            label={t('trading.slippage')}
            value={settings?.orderSettings?.slippage || 5}
            unit="%"
            className="d-flex justify-content-between"
          />

          <div className="d-flex flex-column">
            <button
              className="btn btn-primary wd-100p mt-3 mb-2"
              onClick={handleProceed}
            >
              {t('common.buy')}
            </button>

            <button
              className="btn btn-semi-10 wd-100p"
              onClick={handleClose}
            >
              {t('common.close')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuickTradeConfirmation;
